import React, { useContext, useEffect } from 'react';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import { UserDataContext } from '../firebase/UserDataContext';

import * as routes from '../routes/routes';
import { useLocation } from 'react-router';
import ScoringPage from '../pages/ScoringPage';
import { useNavigate } from 'react-router-dom';


const ScoringRouter = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { user } = useContext(UserDataContext)

    useEffect(() => {
        if (Object.keys(user).length > 0 && !user.loggedIn) {
            navigate(routes.SIGN_IN)
        }
    }, [user, navigate])
    switch (true) {
        case Object.keys(globalSettings).length === 0 || Object.keys(user).length === 0:
            return <div>Loading...</div>
        case location.pathname.includes(routes.SCORING):
            return <ScoringPage />
        default:
            return <div>Unable to route you to the correct page. Please contact a system administrator</div>

    }
}

export default ScoringRouter