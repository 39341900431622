import { initializeApp } from '@firebase/app';
import { equalTo, get as dbGet, orderByChild, query as dbQuery, ref, set, push, remove, update } from "firebase/database";
import { Timestamp } from '@firebase/firestore';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, updateEmail, updatePassword } from '@firebase/auth';
import { onAuthStateChanged } from "@firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { ROLES } from '../components/misc/Utils';


var config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

export default class FirebaseNew {
    constructor() {
        this.app = initializeApp(config);
        this.db = getDatabase(this.apdp)
        this.auth = getAuth(this.app)
        this.storage = getStorage(this.app)
        //this.db = getDatabase(app)
        //this.auth = getAuth(app)
        //this.user = null
        //onAuthStateChanged(this.auth, user => {
        //    if (user) {
        //        console.log('we got a user from the firebase helper')
        //        this.user = user
        //    } else {
        //        this.user = null
        //    }

        //})
    }
    doSignInWithEmailAndPassword = (email, password) => {
        return signInWithEmailAndPassword(this.auth, email, password);
    }
    doSignOut = () => signOut(this.auth)

    passwordForgot = async (email) => {
        let result = {
            success: false,
            reason: []
        };
        const CODES = {
            "auth/user-not-found": 'Invalid Email', // keeping this agnostic for security
            "auth/missing-email": 'Missing Email',
            "auth/invalid-email": 'Invalid Email'
        }
        try {
            await sendPasswordResetEmail(this.auth, email)
            result.success = true
        } catch (e) {
            result.reason = result.reason.concat(CODES[e.code] || 'An unkonwn error occurred please try again later')
        } finally {
            return result
        }
    }

    updateUserEmail = async (newEmail) => {
        let result = {
            success: false,
            reason: []
        };
        const CODES = {
            "auth/invalid-email": 'Email format is invalid'
        }
        try {
            await updateEmail(this.auth.currentUser, newEmail)
            result.success = true
            result.reason = result.reason.concat('Successfully Changed Email')
        } catch (e) {
            console.log(e)
            result.reason = result.reason.concat(CODES[e.code] || 'An unkonwn error occurred please try again later')
        } finally {
            return result
        }
    }

    updateUserPassword = async (newPassword) => {
        const CODES = {
            "auth/requires-recent-login": 'Please Login Again Before Attempting To Change Your Password'
        }
        let result = {
            success: false,
            reason: []
        };
        try {
            await updatePassword(this.auth.currentUser, newPassword)
            result.success = true
            result.reason = result.reason.concat('Successfully Changed Password')
        } catch (e) {
            result.reason = result.reason.concat(CODES[e.code] || 'An unkonwn error occurred please try again later')
        } finally {
            return result
        }
    }

    now = () => {
        return Timestamp.now().toMillis();

    }

    /*
        usersNew
            uid
            email
            username
            role
            createdAt
            updatedAt
    */
    User = (data) => {
        return {
            uid: data.uid || '',
            email: data.email || '',
            username: data.username || '',
            role: data.role || '',
            eventsNew: data.eventsNew || {},
            teamsNew: data.teamsNew || {},
            competitions: data.competitions || {},
            defaultCompetition: data.defaultCompetition || '',
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now(),
            invited: data.invited || false
        }

    }
    insertUser = async (uid, data) => {
        const toSave = new this.User(data)
        const location = ref(this.db, `users/${uid}`)
        return set(location, toSave)
        //return this.db.ref(`users/${uid}`).set(toSave);
    }
    listenUser = (uid) => ref(this.db, `users/${uid}`);

    getUser = async (uid) => {
        const users = await dbGet(ref(this.db, `users/${uid}`))
        return users.val() || {}
    }
    listenUsersMap = () => {
        return ref(this.db, 'users')
    }
    getUsers = async () => {
        const users = await dbGet(ref(this.db, 'users'))
        return users.val() || {}

    }
    listenTeamUsersMap = () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.TEAM)]
        return dbQuery(ref(this.db, 'users'), ...queryConstraints)
    }
    listenAdminUsersMap = () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.ADMIN)]
        return dbQuery(ref(this.db, 'users'), ...queryConstraints)

    }
    listenScoreCommitteeUsersMap = () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.SCORECOMMITTEE)]
        return dbQuery(ref(this.db, 'users'), ...queryConstraints)
    }
    getTeamUserMap = async () => {
        const queryConstraints = [equalTo('TEAM')]
        const teamUsers = await dbGet(dbQuery(ref(this.db, 'users').orderByChild('role'), ...queryConstraints).equalTo('TEAM'))
        return teamUsers.val() || {}
    }
    patchUser = (userKey, newUser) => {
        const toSave = new this.User(newUser)
        console.log('this is what we need to save', toSave)
        const location = ref(this.db, `users/${userKey}`)
        return set(location, toSave)
    }
    /*
    TODO - maybe we do not need judges
        judgesNew
            uid - derived from users
            company
            email
            createdAt
            updatedAt
    */
    Judge = (data) => {
        return {
            uid: data.uid || '',
            company: data.company || '',
            email: data.email || '',
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now()
        }


    }
    insertJudge = () => {

    }
    listenJudgesMap = () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.JUDGE)]
        return dbQuery(ref(this.db, 'users'), ...queryConstraints)
    }
    getJudgesMap = async () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.JUDGE)]
        const judgeUsers = await dbGet(dbQuery(ref(this.db, 'users'), ...queryConstraints))
        return judgeUsers.val() || {}
    }
    getCompanies = () => {
        return new Promise((resolve) => {
            // TODO - to test
            dbGet(ref(this.db, 'judgesNew'))
                .then((result) => {
                    const companies = Object.keys(result || {}).reduce((acc, judge_key) => {
                        acc = acc.push(result[judge_key]['company'])
                        return acc
                    }, [])
                    resolve(companies)
                })
        })
    }
    patchJudge = async (judgeKey, newJudge) => {
        const toSave = new this.Judge(newJudge)
        const location = ref(this.db, `judgesNew/${judgeKey}`)
        return set(location, toSave)
    }
    /*
        teamsNew
            uid
            category
            division
            teamNumber
            teamMembers: [uid - derived from users]
            createdAt
            updatedAt
     */
    Team = (data) => {
        return {
            category: data.category || '',
            division: data.division || '',
            teamNumber: data.teamNumber || '',
            teamName: data.teamName || '',
            teamMembers: data.teamMembers || [],
            associatedEvents: data.associatedEvents || [],
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now()
        }
    }
    insertTeam = async (team, competition) => {
        if (!competition) {
            return {}
        }
        const toSave = new this.Team(team)
        console.log('we are about to save this team', toSave)
        console.log('this is our competition', competition)
        const location = ref(this.db, `teamsNew/${competition}`)
        return push(location, toSave)
    }
    listenTeamsMap = () => {
        return ref(this.db, 'teamsNew')
    }
    getTeamsMap = async () => {
        const teams = await dbGet(ref(this.db, 'teamsNew'))
        return teams.val() || {}
    }
    getTeam = async (teamKey, competition) => {
        if (!teamKey) {
            return false
        }
        const team = await dbGet(ref(this.db, `teamsNew/${competition}/${teamKey}`));
        return team.val() || {}
    }
    patchTeam = (teamKey, newTeam) => {
        const toSave = new this.Team(newTeam)
        if (newTeam.associatedEvents) {
            newTeam.associatedEvents.forEach((v) => {
                if (v.associatedTeams) {
                    v.associatedTeams = []
                }
            })
            // TODO Remove associated events from each team
        }
        const location = ref(this.db, `teamsNew/${teamKey}`)
        return set(location, toSave)
    }
    deleteTeam = (teamKey, competition) => {
        if (!teamKey || !competition) {
            return false
        }
        const location = ref(this.db, `teamsNew/${competition}/${teamKey}`)
        remove(location)
    }
    /*
        eventsNew
            name
            number
            judgesNew
            isQuiz
            createdAt
            updatedAt
    */
    Event = (data) => {
        return {
            name: data.name || '',
            number: parseInt(data.number) || '',
            judgesNew: data.judgesNew || [],
            isQuiz: data.isQuiz ? true : false,
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now(),
            excludeEvent: data.excludeEvent || false,
            company: data.company || '',
            fullTitle: data.fullTitle || '',
            associatedTeams: data.associatedTeams || [],
        }

    }
    insertEvent = (event, competition) => {
        let toSave = new this.Event(event)
        const location = ref(this.db, `eventsNew/${competition}`)
        return push(location, toSave)
    }
    patchEvent = async (eventKey, newEvent) => {
        let toSave = new this.Event(newEvent)
        console.log('we are attempting to patch the event', toSave)
        if (newEvent.associatedTeams) {
            newEvent.associatedTeams.forEach((v) => {
                if (v.associatedEvents) {
                    v.associatedEvents = []
                }
            })
            // TODO Remove associated events from each team
        }
        const location = ref(this.db, `eventsNew/${eventKey}`)
        return set(location, toSave)
    }
    listenEventsMap = () => {
        return ref(this.db, `eventsNew`)
    }
    getEvents = async () => {
        const events = await dbGet(ref(this.db, `eventsNew`))
        return events.val() || {}
    }
    getEventsByCompetition = async (competition) => {
        const events = await dbGet(ref(this.db, `eventsNew/${competition}`))
        return events.val() || {}
    }
    getEvent = async (eventKey, competition) => {
        if (!eventKey) {
            return false
        }
        const event = await dbGet(ref(this.db, `eventsNew/${competition}/${eventKey}`));
        return event.val() || {}
    }
    deleteEvent = async (eventKey, competition) => {
        if (!eventKey || !competition) {
            return false
        }
        const location = ref(this.db, `eventsNew/${competition}/${eventKey}`)
        remove(location)
    }
    getQuizzes = async () => {
        const quizzes = await this.db.ref('eventsNew').orderByChild('isQuiz').equalTo(true).once('value')
        return quizzes.val() || {}
    }
    /*
        scoresNew
            uid
            comments
            finalScore
            delegateJudge*
            delegateJudgeUid
            event*
            eventUid
            judge*
            judgeUid
            team*
            teamUid
            time
            pastscore
            createdAt
            updatedAt
    */
    Score = (data) => {
        return {
            comments: data.comments || '',
            finalScore: data.finalScore || '',
            delegate: data.delegate || {},
            delegateUid: data.delegateUid || '',
            event: data.event || {},
            eventUid: data.eventUid || '',
            judge: data.judge || {},
            judgeUid: data.judgeUid || '',
            team: data.team || {},
            teamUid: data.teamUid || '',
            penalties: data.penalties || {},
            quizQuestionsMissed: data.quizQuestionsMissed || 0,
            time: data.time || 0,
            minutes: data.minutes || 0,
            seconds: data.seconds || 0,
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now(),
            scoreContested: data.scoreContested || false,
            reasonForChange: data.reasonForChange || '',
            additionalComments: data.additionalComments || '',
            excludeScore: data.excludeScore || false
        }
    }
    insertScore = async (score, competition) => {
        let toSave = new this.Score(score)
        // we do not need to be saving all this extra information
        toSave.event.judgesNew = null
        toSave.event.associatedTeams = null

        toSave.judge.eventsNew = null
        toSave.judge.teamsNew = null
        toSave.judge.role = null
        toSave.judge.invited = null

        toSave.team.associatedEvents = null
        toSave.team.teamMembers =  null

        const location = ref(this.db, `scoresNew/${competition}`)
        console.log('about to insert', toSave, location, `scoresNew/${competition}`)
        return push(location, toSave)

    }
    deleteScore = async (scoreKey) => {
        const location = ref(this.db, `scoresNew/${scoreKey}`)
        remove(location)
    }
    deletePastScore = async (scoreKey) => {
        const location = ref(this.db, `pastScoresNew/${scoreKey}`)
        remove(location)
    }
    insertPastScore = async (score, scoreUid, competition) => {
        const location = ref(this.db, `pastScoresNew/${competition}/${scoreUid}`)
        return push(location, score)
    }
    getPastScoresMap = async (scoreUid, competition) => {
        const scores = await dbGet(ref(this.db, `pastScoresNew/${competition}/${scoreUid}`))
        return scores.val() || {}
    }
    listenScoresMap = () => {
        return ref(this.db, `scoresNew`);
    }
    getScore = async (scoreUid, competition) => {
        const score = await dbGet(ref(this.db, `scoresNew/${competition}/${scoreUid}`))
        return score.val() || {}
    }
    getScores = async (competition) => {
        const score = await dbGet(ref(this.db, `scoresNew/${competition}`))
        return score.val() || {}

    }
    patchScore = async (scoreKey, newScoreValue) => {
        const toSave = new this.Score(newScoreValue)
        const location = ref(this.db, `scoresNew/${scoreKey}`)

        return set(location, toSave)
        //const location = ref(this.db, `globalSettings/divisions/${divisionKey}`)
        //set(location, newDivisionValue)

        //const toSave = new this.User(data)
        //const location = ref(this.db, `users/${uid}`)
        //return set(location, toSave)
        ////return this.db.ref(`users/${uid}`).set(toSave);
    }
    getScoresForEvent = async (eventUid, competition) => {
        const queryConstraints = [orderByChild('eventUid'), equalTo(eventUid)]
        const scores = await dbGet(dbQuery(ref(this.db, `scoresNew/${competition}`), ...queryConstraints))
        return scores.val() || {}
    }
    getScoresForJudge = async (judgeUid, competition) => {
        const queryConstraints = [orderByChild('judgeUid'), equalTo(judgeUid)]
        const scores = await dbGet(dbQuery(ref(this.db, `scoresNew/${competition}`), ...queryConstraints))
        return scores.val() || {}

    }
    getScoresForTeam = async (teamUid, competition) => {
        const queryConstraints = [orderByChild('teamUid'), equalTo(teamUid)]
        const scores = await dbGet(dbQuery(ref(this.db, `scoresNew/${competition}`), ...queryConstraints))
        return scores.val() || {}

    }
    /*
        historyNew
            newData
            oldData
            who
            what
            when
            where
    */
    createHistory = (what, data, where) => {
        const toInsert = Object.assign({},
            data,
            {
                what,
                where,
                when: Timestamp.now().toMillis(),
                who: {
                    displayName: this.auth.currentUser.displayName,
                    email: this.auth.currentUser.email,
                    uid: this.auth.currentUser.uid
                }
            }

        );
        console.log('to insert', toInsert)
        this.db.ref('history').push(toInsert);

    }
    getHistory = () => {

    }
    getHistoryForUser = () => {

    }
    getHistoryForWhen = () => {

    }
    getHistoryForWhat = () => {

    }
    /*
        scoresheets
    */
    listenScoresheetsMap = () => {
        return ref(this.db, 'scoresheets')
    }
    insertGlobalScoresheet = async (values, competition) => {
        const location = ref(this.db, `scoresheets/${competition}/global`)
        set(location, values)
    }
    editGlobalScoresheet = async (values, competition) => {
        const location = ref(this.db, `scoresheets/${competition}/global`)
        set(location, values)
    }

    insertEventScoresheet = async (values, eventUid, competition) => {
        const location = ref(this.db, `scoresheets/${competition}/events/${eventUid}`)
        set(location, values)
    }
    editEventScoresheet = async (values, eventUid, competition) => {
        const location = ref(this.db, `scoresheets/${competition}/events/${eventUid}`)
        set(location, values)
    }

    /*
        liabilityReleaseForm
            userId
                agreedToConsent
    */

    listenLiabilityMap = () => {
        return ref(this.db, 'liabilityForm')
    }

    upsertLiabiltyFormDescription = async (competition, value) => {
        const location = ref(this.db, `liabilityForm/${competition}/details`)
        return set(location, value)
    }

    insertLiabilityForm = async (competition, agreedToConsentSelf, agreedToConsentSelfMinor) => {
        const location = ref(this.db, `liabilityForm/${competition}/signatories/${this.auth.currentUser.uid}`)
        return set(location, {
            agreedToConsentSelf: agreedToConsentSelf == "" ? null : agreedToConsentSelf,
            agreedToConsentSelfMinor: agreedToConsentSelfMinor == "" ? null : agreedToConsentSelfMinor,
            createdAt: Timestamp.now().toMillis()
        })
    }

    getLiabilityFormSignatories = async (competition) => {
        const form = await dbGet(ref(this.db, `liabilityForm/${competition}/signatories/${this.auth.currentUser.uid}`))
        return form.val() || false
    }

    resetLiabilityFormEntries = async (competition) => {
        const location = ref(this.db, `liabilityForm/${competition}/signatories`)
        return set(location, null)

    }

    /*
        globalSettings
            showFinalScoresToTeams
            divisions
            categories
    */
    listenGlobalSettings = () => {
        return ref(this.db, 'globalSettings')

    }
    getGlobalSettings = async () => {
        const globalSettings = await dbGet(ref(this.db, 'globalSettings'))
        return globalSettings.val() || {}

    }

    getShowFinalScoresToTeams = async (competition) => {
        const showFinalScoresToTeams = await dbGet(ref(this.db, `globalSettings/showFinalScoresToTeams/${competition}`))
        return showFinalScoresToTeams.val() || {}
    }
    setShowFinalScoresToTeams = async (competition, value) => {
        console.log('ok')
        const location = ref(this.db, `globalSettings/showFinalScoresToTeams/${competition}`)
        return set(location, value)
    }

    getDivisionMap = async () => {
        const divisions = await dbGet(ref(this.db, `globalSettings/divisions`))
        return divisions.val() || {}
    }
    getDivisionMapByCompetition = async (competition) => {
        const divisions = await dbGet(ref(this.db, `globalSettings/divisions/${competition}`))
        return divisions.val() || {}


    }
    insertDivision = async (division, competition) => {
        const location = ref(this.db, `globalSettings/divisions/${competition}`)
        push(location, division)
    }
    patchDivision = async (divisionKey, newDivisionValue) => {
        const location = ref(this.db, `globalSettings/divisions/${divisionKey}`)
        set(location, newDivisionValue)
    }
    deleteDivision = async (divisionKey, competition) => {
        if (!divisionKey || !competition) {
            return false
        }
        const location = ref(this.db, `globalSettings/divisions/${competition}/${divisionKey}`)
        remove(location)
    }

    getCategoryMap = async () => {
        console.log('ok')
        const categories = await dbGet(ref(this.db, `globalSettings/categories`))
        return categories.val() || {}
    }
    insertCategory = async (category, competition) => {
        const location = ref(this.db, `globalSettings/categories/${competition}`)
        push(location, category)
    }
    patchCategory = async (categoryKey, newCategoryValue) => {
        const location = ref(this.db, `globalSettings/categories/${categoryKey}`)
        set(location, newCategoryValue)
    }
    deleteCategory = async (categoryKey, competition) => {
        if (!categoryKey || !competition) {
            return false
        }
        const location = ref(this.db, `globalSettings/categories/${competition}/${categoryKey}`)
        remove(location)
    }
    getDefaultCompetition = async () => {
        console.log('ok')
        const defaultCompetition = await dbGet(ref(this.db, `globalSettings/defaultCompetition`))
        return defaultCompetition.val() || ''
    }
    setDefaultCompetition = async (key) => {
        const location = ref(this.db, `globalSettings/defaultCompetition`)
        set(location, key)

    }
    getCompetitionMap = async () => {
        console.log('ok')
        const competitions = await dbGet(ref(this.db, `globalSettings/competitions`))
        return competitions.val() || {}
    }
    insertCompetition = async (competition) => {
        const location = ref(this.db, `globalSettings/competitions`)
        return push(location, competition)
    }
    patchCompetition = async (competitionKey, newCompetitionValue) => {
        const location = ref(this.db, `globalSettings/competitions/${competitionKey}`)
        return set(location, newCompetitionValue)
    }
    deleteCompetition = async (competitionKey) => {
        if (!competitionKey) {
            return false
        }
        const location = ref(this.db, `globalSettings/compeititions/${competitionKey}`)
        remove(location)
    }


    // STORAGE CALLS
    // TODO - break up

    storageUploadLiabilityForm = async (file, fileName, competition) => {
        const reference = storageRef(this.storage, `liabilityForm/${competition}/${fileName}`)
        const result = await uploadBytes(reference, file)
        return result
    }

    storageGetLiabilityForm = async (fileName, competition) => {
        try {
            const reference = storageRef(this.storage, `liabilityForm/${competition}/${fileName}`)
            const result = await getDownloadURL(reference)
            return result
        } catch (e) {
            return null
        }
    }
}
