
import { Autocomplete, FormHelperText, InputLabel, TextField } from '@mui/material';
const MultiSearchSelectUserWrapper = (props) => {
    // NOTE - this is because we were at first only storing an array of UIDs to associate
    //  with the EventForm
    const {
        selectedUsers = [],
        usersMapValues
    } = props
    const newValues = Object.keys(usersMapValues).reduce((acc, v) => {
        if (selectedUsers.includes(v)) {
            acc = acc.concat({
                ...usersMapValues[v],
                ...{
                    uid: v
                }
            })
        }
        return acc
    }, [])

    return (
        <MultiSearchSelectUser
            {...props}
            value={newValues}
        />
    )
}

const MultiSearchSelectUser = (props) => {
    const {
        value,
        onChange,
        readonly,
        usersMapValues = {}
    } = props

    const filterMethod = (options, { inputValue }) => {
        return options.filter(item => {
            return item.email.toLowerCase().includes(inputValue)
                || item.username.toString().includes(inputValue)
        })
    }
    const usersList = Object.keys(usersMapValues).map(v => {
        return { ...usersMapValues[v], uid: v }
    })

    return (
        <>
            <Autocomplete
                fullWidth
                readOnly={readonly}
                multiple
                filterOptions={filterMethod}
                getOptionLabel={(option) => `${option.username} - ${option.email}`}
                options={usersList}
                renderInput={(params) => <TextField {...params} label="Teams" />}
                isOptionEqualToValue={(option, value) => {
                    return option.uid === value.uid
                }}
                value={value}
                onChange={(event, value, reason, details) => {
                    onChange(value)
                }}
            />
        </>
    )
}
export {
    MultiSearchSelectUserWrapper,
    MultiSearchSelectUser,
}