import React, { useEffect, useContext, useMemo } from 'react';
import * as routes from '../routes/routes';
import UserForm from '../components/forms/UserForm';
import { CommonDataContext } from '../firebase/CommonDataContext';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const usersColumns = [
    { field: 'col1', headerName: 'Email', width: 250 },
    { field: 'col2', headerName: 'Username', width: 300 },
    { field: 'col3', headerName: 'Role', width: 150 },
    { field: 'col4', headerName: 'Invited?', width: 100,
        valueFormatter: ({ value }) => {
            const { user } = value
            return user.invited ? 'invited' : ''
        },
        sortComparator: ({user: user1}, {user: user2}) => {
            return (user1.invited === user2.invited)? 0 : user1.invited? -1 : 1;

        },
        renderCell: ({ value }) => {
            const { user } = value
            return user.invited ? 
                <CheckIcon sx={{ color: 'green', marginLeft: '15px' }} />
                :
                <CloseIcon sx={{ color: 'red', marginLeft: '15px' }} />
        }
},
    { field: 'col5', headerName: 'uid', width: 300, getApplyQuickFilterFn: null },
    {
        field: 'col6', headerName: 'Actions', width: 150, getApplyQuickFilterFn: null,
        renderCell: (params) => {
            return <Button component={Link} to={routes.routeReplace(routes.USER_PAGE_EDIT, { edit: params.value.uid })}> Edit </Button>
        }
    },
]

function UserPage() {
    const location = useLocation()
    const params = useParams()
    const { usersMap } = useContext(CommonDataContext)


    useEffect(() => {
        const fetch = async () => {
        }
        fetch()
            .catch(console.error)

    }, [])
    const rows = useMemo(() => {
        return Object.keys(usersMap).map((v) => {
            const user = usersMap[v]
            return {
                id: v,
                col1: user.email,
                col2: user.username,
                col3: user.role,
                col4: {user},
                col5:  v ,
                col6: { uid: v },
            }
        })

    }, [usersMap])
    return (
        <>
            {
                location.pathname === routes.USER_PAGE &&
                <Grid2>
                    <Typography variant='h3'>Users</Typography>
                    <Button component={Link} to={routes.USER_PAGE_NEW}>Create A User</Button>
                    <DataGrid
                        sx={{ marginBottom: '25px' }}
                        autoHeight
                        rows={rows || []}
                        columns={usersColumns}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Grid2>
            }
            {
                Object.keys(params).length !== 0 &&
                <UserForm editUser={`${params.edit}`} />
            }
            {
                location.pathname.includes(routes.USER_PAGE_NEW) &&
                <UserForm />
            }
        </>
    )

}



export default UserPage;

