import React, { useCallback, useContext, useState } from 'react';

import { ForgotPasswordLink } from './PasswordForget';
import FormErrorMessages from '../forms/Messages/FormErrorMessages'
import * as ROUTES from '../../routes/routes';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { useNavigate } from 'react-router-dom';

function SignInPage() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessages, setErrorMessages] = useState([])
    const { firebase } = useContext(FirebaseNewContext)
    const navigate = useNavigate()

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        setErrorMessages([])

        firebase
            .doSignInWithEmailAndPassword(email.trim(), password.trim())
            .then(() => {
                navigate.push(ROUTES.DASHBOARD)
            })
            .catch(error => {
                console.log('this is our error message', error.message);
                const errors = ['auth/user-not-found', 'auth/wrong-password', 'auth/too-many-requests'].reduce((acc, v) => {
                    if (acc.length > 0) {
                        return acc
                    }
                    if (error.message.indexOf(v) != -1) {
                        acc = acc.concat(v.split('/')[1].split('-').join(' '))
                    }
                    return acc
                }, [])
                setErrorMessages(errors)
            });

    }, [email, password, errorMessages]);
    const isInvalid = password === '' || email === '';
    return (
        <form className="signin-form" onSubmit={onSubmit}>
        <input
            name="email"
            value={email}
            onChange={(e) => {
                setEmail(e.target.value)

            }}
            type="text"
            placeholder="Email Address"
            className="signin-form-input"
        />
        <input
            name="password"
            value={password}
            onChange={(e) => {
                setPassword(e.target.value)
            }}
            type="password"
            placeholder="Password"
            className="signin-form-input"
        />
        <ForgotPasswordLink />
        <button
            disabled={isInvalid}
            type="submit"
            className="signin-form-button"
        >Sign In</button>

        <FormErrorMessages errorMessages={errorMessages} success={errorMessages.length == 0} />
        </form>
    );
}
export default SignInPage;
