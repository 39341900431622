// TODO - break up the form to a different component

import { useCallback, useContext, useMemo, useReducer } from "react";
import { FirebaseNewContext } from "../firebase/FirebaseNewContext";
import { UserDataContext } from "../firebase/UserDataContext";

import app from 'firebase/compat/app';
import { initializeApp, getApp } from '@firebase/app';
import { getAuth, signOut, createUserWithEmailAndPassword, sendEmailVerification } from '@firebase/auth';
import { CommonDataContext } from "../firebase/CommonDataContext";

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, TextField, Typography } from '@mui/material';
import FormErrorMessages from "../components/forms/Messages/FormErrorMessages";
import { MultiSearchSelectTeamWrapper } from "../components/forms/Dropdowns/TeamDropdown";

const newFirebaseUserApp = () => {
    // NOTE - only create one service account
    if (app.apps.find(entry => entry.name === 'SERVICE_ACCOUNT')) {
        return getApp('SERVICE_ACCOUNT')
    }
    var config = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    };
    return initializeApp(config, "SERVICE_ACCOUNT");
}
const secondaryApp = newFirebaseUserApp()
const secondaryAuth = getAuth(secondaryApp)
function inviteTeammateFormInitialState(values) {
    return {
        email: values.email || '',
        // we will derive role + username based on email (username will be a number after the email address)
        // we will auto generate a password Amc5175446!
        //passwordTwo: '',
        // teams will be derived from all the teams the current user is involved in
        teamsNew: values.teamsNew || [],
        competitions: values.competitions || {},
        selectedCompetition: values.selectedCompetition || {},
        errorMessages: []
    }

}

function inviteTeammateFormReducer(state, action) {
    switch (action.type) {
        case 'checkbox_values_changed':
            return {
                ...state,
                [`${action.forItem}New`]: {
                    ...state[`${action.forItem}New`],
                    ...{
                        [action.forCompetition]: action.values
                    }
                }
            }
        case 'value_changed':
            return {
                ...state,
                [action.keyName]: action.keyValue
            }
        case 'error_message_changed':
            return {
                ...state,
                errorMessages: action.nextErrorMessages

            }
        default:
            return state;

    }
}

function InviteTeammateForm(props) {
    const [state, dispatch] = useReducer(inviteTeammateFormReducer, props, inviteTeammateFormInitialState)
    const { firebase } = useContext(FirebaseNewContext)
    const { defaultSelectedCompetition, user: meRaw } = useContext(UserDataContext)
    const { teamsMap: globalTeamsMap, usersMap } = useContext(CommonDataContext)
    const teamsMap = useMemo(() => {
        return globalTeamsMap[defaultSelectedCompetition] || {}
    }, [globalTeamsMap, defaultSelectedCompetition])
    console.log("OUR DEFAULT COMPETITION", defaultSelectedCompetition)
    const localTeamsMap = useMemo(() => {
        const me = usersMap[meRaw.uid]
        // TODO - this wont be needed once we wrap it in a router
        if (!me) {
            return {}
        }
        console.log(usersMap)
        console.log('this is me', me)
        if (!me.teamsNew) {
            return {}
        }
        console.log(defaultSelectedCompetition)
        console.log('here is our me', me.teamsNew[defaultSelectedCompetition])
        if (me.teamsNew[defaultSelectedCompetition]) {
            return me.teamsNew[defaultSelectedCompetition].reduce((acc, v) => {
                console.log('here is our v', v)
                acc[v] = teamsMap[v]
                return acc
            }, {})

        }
        return {}
    }, [teamsMap, usersMap, meRaw, defaultSelectedCompetition])
    console.log('this is our local teams map', localTeamsMap)
    const allUserEmails = useMemo(() => {
        return Object.keys(usersMap).map(x => usersMap[x].email)
    }, [usersMap])
    const me = usersMap[meRaw.uid]

    const insertNewUser = async (isValidCheck) => {
        if (isValidCheck.result) {
            // TODO - update this with the new way of doing auth
            createUserWithEmailAndPassword(secondaryAuth, state.email.trim(), 'Amc2024xx!')
                .then(async (firebaseUser) => {
                    const user = firebaseUser.user;
                    // NOTE - unsure if signOut is actually requried since it is a different app
                    signOut(secondaryAuth);

                    console.log('this is our default selected competition', defaultSelectedCompetition)
                    let toSave = state
                    toSave['uid'] = user.uid
                    toSave['role'] = 'TEAM'
                    toSave['username'] = state.email.trim().split('@')[0]
                    toSave['defaultCompetition'] = defaultSelectedCompetition
                    toSave['invited'] = true
                    console.log('this is what we got', toSave)
                    await firebase.insertUser(user.uid, toSave)
                    await addTeamEntry(user.uid)
                    window.location.reload(false)

                })
        }
    }
    const addTeamEntry = async (databaseId) => {
        const newUser = await firebase.getUser(databaseId)
        const newUserTeams = newUser.teamsNew && newUser.teamsNew[defaultSelectedCompetition] ?newUser.teamsNew[defaultSelectedCompetition] : []
        const result = await Promise.all(newUserTeams.map(async (x) => {
            // TODO - use the thing up above which will always have the team
            let team = teamsMap[x]
            if (!team.teamMembers) {
                team.teamMembers = {
                    [databaseId]: newUser
                }
            }
            team.teamMembers = { ...team.teamMembers, ...{ [databaseId]: newUser } }
            return firebase.patchTeam(`${defaultSelectedCompetition}/${x}`, team)
        }))
        return result

    }
    const isValid = (state) => {
        const { email } = state;
        let validCheck = { result: true, errorMessages: [] }

        if (email === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Email cannot be blank');
        }
        if (allUserEmails.includes(email)) {
            validCheck.result = false;
            validCheck.errorMessages.push('User has already been invited, contact an admin to add them to the team');
        }

        return validCheck;
    }
    const submitButtonPressed = useCallback((e) => {
        e.preventDefault()
        const isValidCheck = isValid(state)
        console.log(allUserEmails)
        dispatch({
            type: "error_message_changed",
            nextErrorMessages: isValidCheck.errorMessages
        })
        insertNewUser(isValidCheck)


    }, [state, defaultSelectedCompetition, me, allUserEmails])

    return (
        <Grid2>

            <TextField
                sx={{ flex: 1 }}
                name="email"
                value={state.email}
                onChange={(e) => {
                    dispatch({
                        type: 'value_changed',
                        keyValue: e.target.value.trim(),
                        keyName: 'email'
                    })
                }}
                label="Email Address"
            />
            {
                Object.keys(localTeamsMap).length > 0 &&
                <Grid2>
                    <Typography variant='h5'>Assign To a Team</Typography>
                    <Grid2 container>
                        <MultiSearchSelectTeamWrapper
                            selectedTeams={state.teamsNew[defaultSelectedCompetition] || []}
                            onChange={(value) => {
                                const values = value.map(v => v.uid)
                                dispatch({
                                    type: 'checkbox_values_changed',
                                    values: values,
                                    forItem: 'teams',
                                    forCompetition: defaultSelectedCompetition
                                })

                            }}
                            teamsMapValues={localTeamsMap}
                        />
                    </Grid2>
                </Grid2>
            }
            <FormErrorMessages errorMessages={state.errorMessages} />
            <Button onClick={submitButtonPressed}>Invite User</Button>
        </Grid2>
    )
}


function InviteUserPage() {

    return (
        <>
            <h2>Invite User Page</h2>
            <InviteTeammateForm />
        </>


    )
}


export default InviteUserPage;
