import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const DivisionDropdown = (props) => {
    const {
        value,
        onChange,
        readonly,
        divisionsMapValues
    } = props

    return (
        <Grid2>
            <FormControl className='scoring-form-div' variant="standard"
                sx={{ minWidth: '200px' }}>
                <InputLabel id="demo-simple-select-helper-label">Division</InputLabel>
                <Select
                    name="judgeUid"
                    value={value}
                    onChange={(e) => onChange(e, e.target.value || "")}
                    disabled={readonly}
                >
                    <MenuItem value={''}>Division</MenuItem>
                    {Object.keys(divisionsMapValues).map(dUid => {
                        return (
                            <MenuItem
                                key={dUid}
                                value={dUid}
                            >
                                {divisionsMapValues[dUid]}
                            </MenuItem>
                        )
                    })}

                </Select>
            </FormControl>
        </Grid2>
    )
}

export default DivisionDropdown