import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Typography } from '@mui/material';

const PenaltyForm = (props) => {
    const penaltyList = props.penaltyList
    const penaltiesAccrued = props.penaltiesAccrued || {}
    const onChange = (e, penalty) => {
        if (props.onChange) {
            props.onChange(e, penalty)
        }
    }
    return (
        <div>
            <label style={{ fontWeight: 'bold' }}>Penalties</label>
            {
                penaltyList.length > 0 ?
                    <div className="scoring-form-penalties v2">
                        {penaltyList.map((penalty, index) => {
                            let id = penalty.id;
                            let exists = id in penaltiesAccrued ? penaltiesAccrued[id] : { checked: false }
                            return (
                                <Grid2 container key={`${id}-${exists.checked}`}
                                    sx={{
                                        backgroundColor: index % 2 == 0 ? '#eee' : '',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingLeft: '15px',
                                        paddingRight: '15px',
                                    }}
                                >
                                    <Grid2>
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    disabled={props.readonly}
                                                    value={penalty.value}
                                                    onChange={(e) => onChange(e, penalty)}
                                                    checked={exists && (exists.checked || false)}

                                                />}
                                                label={(
                                                    <Grid2>
                                                        <span>
                                                            {index + 1}.<span> </span>
                                                            {penalty.description}
                                                        </span>
                                                    </Grid2>

                                                )}
                                            />
                                            {
                                                penalty.custom &&

                                                <TextField
                                                    readOnly={props.readonly}
                                                    sx={{ width: '100%' }}
                                                    variant="standard"
                                                    value={exists && (exists['customEntry'] || '')}
                                                    onChange={(e) => onChange(e, penalty)}
                                                    disabled={props.readonly || (exists ? exists.checked === false : true)}
                                                    label={penalty.description}
                                                />

                                            }
                                        </FormGroup>
                                    </Grid2>
                                    <Grid2 container sx={{ alignItems: 'center' }}>
                                        <Typography variant='body1'>{Math.sign(penalty.value) !== -1 ? '+' : ''}{penalty.value} {penalty.format.toLowerCase()}</Typography>
                                        {
                                            penalty.hasMultiplier && (
                                                <input
                                                    readOnly={props.readonly}
                                                    className="scoring-form-multiplier-number"
                                                    type="number"
                                                    value={exists && exists.checked && (exists['multiplier'] || '1')}
                                                    onChange={(e) => onChange(e, penalty)}
                                                    min={1}
                                                    disabled={props.readonly || (exists ? exists.checked === false : true)}
                                                />


                                            )
                                        }
                                    </Grid2>
                                </Grid2>
                            )

                        })}
                    </div>
                    :
                    <Typography>No Penalties for this socring form</Typography>
            }
        </div>
    )
}


export default PenaltyForm