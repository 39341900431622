import React, { Component, useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../routes/routes';
import { Button, TextField, Typography } from '@mui/material';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import FormErrorMessages from '../forms/Messages/FormErrorMessages';


// TODO - make a forgot password page
const ForgotPasswordLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessages, setErrorMessages] = useState([])
  const { firebase } = useContext(FirebaseNewContext)

  const onEmailChange = (e) => {
    e.preventDefault()
    setEmail(e.target.value)
  }
  const sendEmail = async () => {
    const result = await firebase.passwordForgot(email)
    if (result.success) {
      setSuccessMessage('Password has successfully be reset, check your email')
    }
    setErrorMessages(result.reason)
    setDisableSubmit(false)

  }
  const onSubmit = useCallback(() => {
    setSuccessMessage('')
    setDisableSubmit(true)
    sendEmail(email)
  }, [email, errorMessages])

  return (
    <>
      <Typography variant='h5'>Password Reset</Typography>
      <TextField
        onChange={onEmailChange}
        value={email}
        label={'Email Address'}
      />
      {
        successMessage &&
        <Typography variant='body'>{successMessage}</Typography>
      }
      <FormErrorMessages errorMessages={errorMessages} />
      <Button disabled={disableSubmit} onClick={onSubmit}>Submit Password Reset Form</Button>
    </>
  )
}

export { ForgotPasswordLink, ForgotPasswordForm };