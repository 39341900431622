import React, { createContext, useContext, useState } from "react";
import FirebaseNew from "./FirebaseNew";

export const FirebaseNewContext = createContext('');

const FirebaseNewContextProvider = (props) => {
    const [firebase, setFirebase] = useState(new FirebaseNew('newTrial'))

    return (
        <FirebaseNewContext.Provider value={{ firebase }}>
            {props.children}
        </FirebaseNewContext.Provider>
    )
}
export default FirebaseNewContextProvider