import React, { Component, useReducer, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import { DataGrid, GridRowsProp, GridColDef, GridToolbar } from '@mui/x-data-grid';
import * as ROUTES from '../../routes/routes';
import { Button, debounce, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { CheckBox, Clear } from '@mui/icons-material';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { useCookies } from 'react-cookie';
import { onValue } from "@firebase/database";
import { useDebounce } from 'use-debounce';

const eventActionColumn = (params) => {
    let toNavigate = ROUTES.routeReplace(ROUTES.SCORING_FOR_EVENT_FOR_TEAM, {
        eventUid: params.value.event.eventUid,
        teamUid: params.value.team.teamUid
    })
    if (params.value.entry.entered) {
        toNavigate = ROUTES.routeReplace(ROUTES.SCORING_EDIT, {
            editScore: params.value.entry.score.uid,
            competition: params.value.competition
        })
    }
    return (
        <a href={toNavigate}>{params.value.entry.entered ? 'edit score' : 'enter new score'}</a>
    )


}
const eventColumns = [
    { field: 'col1', headerName: 'Team Number ', width: 150 },
    { field: 'col2', headerName: 'Team Name ', width: 300 },
    { field: 'col3', headerName: 'Category', width: 150 },
    {
        field: 'col4', headerName: 'Has been entered', width: 150,
        renderCell: (params) => {
            if (params.value) {
                return (<CheckBox />)

            }
            return (<Clear />)
        }
    },
    { field: 'col5', headerName: 'Final Score', width: 150, getApplyQuickFilterFn: null },
    {
        field: 'col6', headerName: 'Action', width: 150,
        renderCell: eventActionColumn
    }
]

// TODO - this should be the same as the eventActionColumn
//  keeping it separated though for 
const teamActionColumn = (params) => {
    let toNavigate = ROUTES.routeReplace(ROUTES.SCORING_FOR_EVENT_FOR_TEAM, {
        eventUid: params.value.event.eventUid,
        teamUid: params.value.team.teamUid
    })
    if (params.value.entry.entered) {
        toNavigate = ROUTES.routeReplace(ROUTES.SCORING_EDIT, {
            editScore: params.value.entry.score.uid,
            competition: params.value.competition
        })
    }
    return (
        <a href={toNavigate}>{params.value.entry.entered ? 'edit score' : 'enter new score'}</a>
    )

}

const teamColumns = [
    { field: 'col1', headerName: 'Event Number ', width: 150 },
    { field: 'col2', headerName: 'Event Name', width: 150, },
    { field: 'col3', headerName: 'Has been entered', width: 150, },
    {
        field: 'col4', headerName: 'Action', width: 150,
        renderCell: teamActionColumn
    }
]
function scoreCommitteeInitialState(_) {
    return {
        eventFocus: true,
        eventSearch: '',
        teamSearch: '',
        errorMessages: []
    }

}
function scoreCommitteeReducer(state, action) {
    switch (action.type) {
        case 'event_search_changed':
            return {
                ...state,
                eventSearch: action.value
            }
        case 'team_search_changed':
            return {
                ...state,
                teamSearch: action.value
            }
        case 'event_focus_toggled':
            return {
                ...state,
                eventFocus: !state.eventFocus
            }
        default:
            break;
    }
}
const ScoreCommitteeDashboardV2 = (props) => {
    const [state, dispatch] = useReducer(scoreCommitteeReducer, props, scoreCommitteeInitialState)
    const [cookies, setCookie] = useCookies(['previousSearch']);
    const { firebase } = useContext(FirebaseNewContext)
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const { eventsMap: globalEventsMap, scoresMap: globalScoresMap, teamsMap: globalTeamsMap } = useContext(CommonDataContext)
    const [debouncedSearch] = useDebounce(state.eventSearch, 250)
    const eventsMap = useMemo(() => {
        return globalEventsMap[defaultSelectedCompetition] || {}
    }, [defaultSelectedCompetition, globalEventsMap])
    const [scoresMap, setScoresMap] = useState({})
    //const scoresMap = useMemo(() => {
    //    return globalScoresMap[defaultSelectedCompetition] || {}
    //}, [defaultSelectedCompetition, globalScoresMap])
    const teamsMap = useMemo(() => {
        return globalTeamsMap[defaultSelectedCompetition] || {}
    }, [defaultSelectedCompetition, globalTeamsMap])
    const categoriesMap = useMemo(() => {
        return globalSettings['categories'][defaultSelectedCompetition] || {}
    }, [defaultSelectedCompetition, globalSettings])

    useEffect(() => {
        //const fetch = async () => {
        //    const scoresMapResult = await firebase.getScores(defaultSelectedCompetition)
        //    setScoresMap(scoresMapResult)
        //}
        //fetch().catch(console.error)
        onValue(firebase.listenScoresMap(), snapshot => {
            const scoresMap = snapshot.val()
            setScoresMap(scoresMap[defaultSelectedCompetition])
            //setAdminUsersMap(adminUsersMapResult)
        })

    }, [])
    useEffect(() => {
        dispatch({
            type: 'event_search_changed',
            value: cookies.previousSearch
        })

    }, [])
    const eventKeysWithTeams = useMemo(() => {
    const MAX_RESULTS = 3
    let eventKeysWithTeams = Object.keys(eventsMap).reduce((acc, eventUid) => {
        if (Object.keys(acc).length == MAX_RESULTS) {
            return acc
        }
        const event = eventsMap[eventUid]
        if (debouncedSearch) {
            if (!(event['number'].toString() == (String(debouncedSearch).toLowerCase()))
                && !(event['name'].toLowerCase().includes(String(debouncedSearch).toLowerCase()))) {
                return acc
            }
        }
        acc[eventUid] = Object.keys(teamsMap).reduce((tAcc, tUid) => {
            //console.log('this is our team', teamsMap[tUid]['teamNumber'])
            tAcc[tUid] = { ...teamsMap[tUid], ...{ entered: false } }
            return tAcc
        }, {})
        return acc
    }, {})
    //let teamKeysWithEvents = {}
    //let teamKeysWithEvents = Object.keys(teamsMap).reduce((acc, teamUid) => {
    //    const team = teamsMap[teamUid]
    //    if (state.teamSearch) {
    //        if (!(team['teamNumber'].includes(state.teamSearch))) {
    //            return acc
    //        }
    //    }
    //    //acc[teamUid] = { ...eventsMap, ...{ entered: false } }
    //    acc[teamUid] = Object.keys(eventsMap).reduce((eAcc, eUid) => {
    //        eAcc[eUid] = { ...eventsMap[eUid], ...{ entered: false } }
    //        return eAcc
    //    }, {})
    //    return acc
    //}, {})
    // TODO - this may be bad but we will see, we may need to listen to the score
    //  and see 
    Object.keys(scoresMap).reduce((acc, scoreUid) => {
        const score = { uid: scoreUid, ...scoresMap[scoreUid] }
        // events edit
        if (score.eventUid in eventKeysWithTeams) {
            if (score.teamUid in eventKeysWithTeams[score.eventUid]) {
                eventKeysWithTeams[score.eventUid][score.teamUid]['entered'] = true
                eventKeysWithTeams[score.eventUid][score.teamUid]['score'] = score
            }

        }
        //if (score.teamUid in teamKeysWithEvents) {
        //    if (score.eventUid in teamKeysWithEvents[score.teamUid]) {
        //        teamKeysWithEvents[score.teamUid][score.eventUid]['entered'] = true
        //        teamKeysWithEvents[score.teamUid][score.eventUid]['score'] = score
        //    }
        //}
        return acc
    }, {})
        return eventKeysWithTeams

    }, [debouncedSearch, state.eventSearch, scoresMap])

    // TODO - break each of these sections into tables that are only
    //  fed the results (even the results of search)


    // TODO IMPORTANT usememo for row calculations
    //kconst eventRowsV2 = useMemo(() => {

    //k    return Object.keys(eventKeysWithTeams).map((e) => {
    //k        // TODO - break out into functions that we can reuse for the render value
    //k        const event = eventsMap[e]
    //k        const eventRows = Object.keys(eventKeysWithTeams[e]).map((t, k) => {
    //k            const team = teamsMap[t]
    //k            const entry = eventKeysWithTeams[e][t]
    //k            return {
    //k                id: `${k}${event.number}${team.teamNumber}`,
    //k                col1: team.teamNumber,
    //k                col2: team.teamName,
    //k                col3: categoriesMap[team.category],
    //k                col4: entry.entered,
    //k                col5: entry.entered ? entry.score.finalScore : '-',
    //k                col6: { entry, competition: defaultSelectedCompetition, event: { ...event, ...{ eventUid: e } }, team: { ...team, ...{ teamUid: t } } }
    //k            }

    //k        })

    //k    }, [eventKeysWithTeams])
    return (
        <Grid2>
            <Typography variant='h5'>Dashboard</Typography>
            <Grid2 container sx={{ flexDirection: 'column', m: 5 }}>
                {
                    state.eventFocus &&
                    <TextField
                        type={'text'}
                        label={`Search by Events number or name`}
                        value={state.eventSearch}
                        placeholder={'Search by event number or name'}
                        onChange={(e) => {
                            e.preventDefault()
                            dispatch({
                                type: 'event_search_changed',
                                value: e.target.value
                            })
                            setCookie('previousSearch', e.target.value)
                        }}
                    />
                }
                {
                    !state.eventFocus &&
                    <TextField
                        type={'text'}
                        label={`Search by Team number or name`}
                        value={state.teamSearch}
                        placeholder={'Search by team number or name'}
                        onChange={(e) => {
                            e.preventDefault()
                            dispatch({
                                type: 'team_search_changed',
                                value: e.target.value
                            })
                        }}
                    />
                }
            </Grid2>
            {debouncedSearch !== '' &&
                Object.keys(eventKeysWithTeams).map((e) => {
                    // TODO - break out into functions that we can reuse for the render value
                    const event = eventsMap[e]
                    //console.log('this is the event', event)
                    const eventRows = Object.keys(eventKeysWithTeams[e]).map((t, k) => {
                        const team = teamsMap[t]
                        const entry = eventKeysWithTeams[e][t]
                        return {
                            id: `${k}${event.number}${team.teamNumber}`,
                            col1: team.teamNumber,
                            col2: team.teamName,
                            col3: categoriesMap[team.category],
                            col4: entry.entered,
                            col5: entry.entered ? entry.score.finalScore : '-',
                            col6: { entry, competition: defaultSelectedCompetition, event: { ...event, ...{ eventUid: e } }, team: { ...team, ...{ teamUid: t } } }
                        }

                    })
                    return (
                        <Grid2 container sx={{ flexDirection: 'column', m: 5 }} key={event.number} style={{ height: 500, width: '100%' }}>
                            <Grid2 sx={{ textAlign: 'left' }}>
                                <Typography>Event Number: {event.number}</Typography>
                                <Typography>Event Name: {event.name}</Typography>
                                <Typography>Company: {event.company}</Typography>
                                <Typography>Full Title: {event.fullTitle}</Typography>
                            </Grid2>
                            <DataGrid
                                key={event.name}
                                rows={eventRows}
                                columns={eventColumns}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Grid2>
                    )


                })
            }
        </Grid2>
    )

}
            //{!state.eventFocus &&
            //    Object.keys(teamKeysWithEvents).map((t) => {
            //        const team = teamsMap[t]
            //        const teamRows = Object.keys(teamKeysWithEvents[t]).map((e, k) => {
            //            const event = eventsMap[e]
            //            const entry = teamKeysWithEvents[t][e]
            //            return {
            //                id: `${k}${team.teamNumber}${event.number}`,
            //                col1: event.number,
            //                col2: event.name,
            //                col3: entry.entered,
            //                col4: { entry, competition: defaultSelectedCompetition, event: { ...event, ...{ eventUid: e } }, team: { ...team, ...{ teamUid: t } } }
            //            }
            //        })
            //        return (
            //            <Grid2 key={team.teamNumber} style={{ height: 500, width: '100%' }}>
            //                <Typography>Team Number:{team.teamNumber}</Typography>
            //                <Typography>Team Name:{team.teamName}</Typography>
            //                <DataGrid
            //                    key={team.number}
            //                    rows={teamRows}
            //                    columns={teamColumns}
            //                    slots={{ toolbar: GridToolbar }}
            //                    slotProps={{
            //                        toolbar: {
            //                            showQuickFilter: true,
            //                            quickFilterProps: { debounceMs: 500 },
            //                        },
            //                    }}
            //                />
            //            </Grid2>
            //        )
            //    })
            //}





export default ScoreCommitteeDashboardV2
