import { CheckBox } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Autocomplete, FormHelperText, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { CommonDataContext } from '../../../firebase/CommonDataContext';

const JudgeCheckbox = (props) => {
    const { judgesMap } = useContext(CommonDataContext)
    useEffect(() => {
        const fetch = async () => {
        }

        fetch()
            .catch(console.error)

    }, [])
    const {
        values,
        onChange,
        readonly,
    } = props

    return (
        <Grid2>
            {Object.keys(judgesMap).map((optionKey) => {
                return (
                    <FormGroup key={optionKey}>
                        <FormControlLabel control={<Checkbox
                            value={optionKey}
                            /* TODO - fix this in the future need to be simpler */
                            checked={values.includes(optionKey)}
                            onChange={(e) => {
                                onChange(e.target.value, e.target.checked)
                            }}
                            readOnly={readonly}
                        />} label={judgesMap[optionKey]['username']} />
                    </FormGroup>
                )
            })}
        </Grid2>
    )
}

const MultiSearchSelectJudgeWrapper = (props) => {
    // NOTE - this is because we were at first only storing an array of UIDs to associate
    //  with the EventForm
    const {
        selectedJudges = [],
        judgesMap
    } = props
    console.log('selected judges', selectedJudges)
    const newValues = Object.keys(judgesMap).reduce((acc, v) => {
        if (selectedJudges.includes(v)) {
            acc = acc.concat({
                ...judgesMap[v],
                ...{
                    uid: v
                }
            })
        }
        return acc
    }, [])

    return (
        <MultiSearchSelectJudge
            {...props}
            value={newValues}
        />
    )
}

const MultiSearchSelectJudge = (props) => {
    const {
        value,
        onChange,
        readonly,
        judgesMap = {}
    } = props

    const filterMethod = (options, { inputValue }) => {
        return options.filter(item => {
            return item.username.toLowerCase().includes(inputValue)
                || item.email.toString().includes(inputValue)
        })
    }
    const judgesList = Object.keys(judgesMap).map(v => {
        return { ...judgesMap[v], uid: v }
    })

    return (
        <>
            <Autocomplete
                fullWidth
                readOnly={readonly}
                multiple
                filterOptions={filterMethod}
                getOptionLabel={(option) => `${option.username} - ${option.email}`}
                options={judgesList}
                renderInput={(params) => <TextField {...params} label="Teams" />}
                isOptionEqualToValue={(option, value) => {
                    return option.uid === value.uid
                }}
                value={value}
                onChange={(event, value, reason, details) => {
                    onChange(value)
                }}
            />
        </>
    )
}

export {
    JudgeCheckbox,
    MultiSearchSelectJudge,
    MultiSearchSelectJudgeWrapper
}
