import React from 'react'

const TimeClockManual = (props) => {
    const {
        minutes,
        seconds,
        onChange,
        readonly
    } = props;

    return (
        <>
            <div>
                <input
                    disabled={readonly}
                    name="minutes"
                    value={minutes}
                    min="0"
                    onChange={(e) => onChange(e, 'minutes')}
                    type="number"
                    className="scoring-form-time-input"
                    placeholder="MM"
                />
                <span> : </span>
                <input
                    disabled={readonly}
                    name="seconds"
                    value={seconds}
                    min="0"
                    onChange={(e) => onChange(e, 'seconds')}
                    type="number"
                    className="scoring-form-time-input"
                    placeholder="SS"
                />
            </div>
        </>
    )

}

export default TimeClockManual;