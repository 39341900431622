import React, { useRef, useReducer, useEffect, useState, useCallback, useMemo } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { text } from 'body-parser';
import generalGraphic from '../images/general-graphic.jpg'
import sponsorGraphic from '../images/sponsor-graphic.jpg'
import newGeneralGraphic from '../images/amc_pp-amc23_041223_v1_Page_2.jpg'
import newGeneralGraphic2024 from '../images/event-image.jpg'
import breakImage1 from '../images/break-image-1.jpg'
import breakImage2 from '../images/break-image-2.jpg'
import breakImage3 from '../images/break-image-3.jpg'
import breakImage4 from '../images/break-image-4.jpg'
import newBreakGraphic2024 from '../images/break-image.jpg'
import { CircularProgress, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { LinearProgress } from '@mui/material';
import { createTheme, makeStyles, ThemeProvider } from '@mui/material/styles';
import { argv0 } from 'process';



const theme = createTheme({
    palette: {
        primary: {
            main: '#00ff00', // Green
        },
        warning: {
            main: '#ffeb3b', // Yellow
        },
        secondary: {
            main: '#ff5722', // Orange
        },
        error: {
            main: '#f44336', // Red
        },
    },
});

function VisualTimer({ minutes, suffix, onFinished, restartKey, whereAreWe }) {
    const [timeLeft, setTimeLeft] = useState(minutes * 60);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (timeLeft > 0) {
            intervalRef.current = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            clearInterval(intervalRef.current);
            onFinished();
        }
        return () => clearInterval(intervalRef.current);
    }, [timeLeft]);

    useEffect(() => {
        setTimeLeft(minutes * 60);
    }, [minutes, restartKey]);

    //const secondsLeft = timeLeft % 60;
    //const progress = 100 - (timeLeft / (minutes * 60)) * 100;
    const progress = (timeLeft / (minutes * 60)) * 100;

    const getColor = (progress) => {
        if (progress > 66) {
            return 'primary';
        } else if (progress > 33) {
            return 'warning';
        } else if (progress > 10) {
            return 'secondary';
        } else {
            return 'error';
        }
    };

    //<LinearProgress
    //    variant="determinate"
    //    value={progress}
    //    color={getColor(progress)}
    //    style={{ height: 20, borderRadius: 10 }}
    ///>

    // 600 for NON break
    // h1/h2 for NON break

    // 200 for break
    // h3/h5 for break
    // textAlign=right for both inner grid items
    // paddingRight 7.5
    const onBreak = ['break', 'lbreak'].includes(whereAreWe) ? true : false
    const circularBreakStyles = {
        position: 'absolute',
        top: 50,
        left: 110
    }
    return (
        <ThemeProvider theme={theme}>

            <Grid2 container alignItems="center" justifyContent="center">
                <Grid2 item="true" xs={12} textAlign={onBreak? 'left' : 'center'} style={onBreak ? circularBreakStyles : {}}>
                    <CircularProgress
                        variant="determinate"
                        value={parseFloat(((timeLeft / (minutes * 60)) * 100).toFixed(2))}
                        size={onBreak ? 175: 600}
                        thickness={1.5}
                        color={timeLeft <= 10 ? "secondary" : "primary"}
                    />
                </Grid2>
                <Grid2 item="true" xs={12} sx={{ color: onBreak ? 'white': 'black',textAlign: onBreak ? 'left': 'center', top: onBreak ? 0 : '40%', position: 'absolute', display: 'flex', flexDirection: 'column' , paddingLeft: onBreak ?17.5: 0,  paddingTop: onBreak ? 10 : 0}}>
                    <Typography variant={onBreak?'h2': 'h1'}>{`${Math.floor(timeLeft / 60)}:${(timeLeft % 60)
                        .toString()
                        .padStart(2, '0')}`}
                    </Typography>
                    <Typography variant={onBreak?'h5':'h3'} sx={{marginLeft: onBreak ? 2 : 0}}>
                        {suffix}
                    </Typography>
                </Grid2>
            </Grid2>
        </ThemeProvider>
    );
}

const selectBreakGraphic = (graphics, graphicsUsed) => {
    //if (graphicsUsed.used.length == 0 && graphics.length > 0) {
    //    return [graphics[0], 0]
    //}
    return graphics.reduce((acc, v, k) => {
        if (!graphicsUsed.used.includes(k)) {
            acc = [v, k]
        }
        return acc
    }, [])
}

const schedule = [
        1, 'break', 2, 'break', 3, 'break', 4, 'break', 5, 'lbreak', 6, 'break', 7, 'break', 8, 'break', 9, 'lunch', 10, 'break', 11, 'break', 12, 'break', 13, 'break', 14, 'break', 15, 'lbreak', 
        16, 'break', 17, 'break', 18, 'break', 19, 'break', 20, 'break', 21, 'break', 22, 'break', 23, 'break', 24, 'break', 25, 'break', 26, 'break', 27, 'break', 28, 'break', 29, 'break',
        30, 'break', 31, 'lunch', 32, 'break', 33, 'break', 34, 'break', 35, 'break', 36, 'break', 37, 'break', 38, 'break', 39, 'lbreak', 40, 'break', 41, 'break', 42, 'break', 43, 'break', 44, 'break', 45, 'alt rd 1', 'break', 'alt rd 2'
]
function SlideShowTimerPage(props) {
    const [eventTimePlay, setEventTimePlay] = useState(false)
    const [eventCounter, setEventCounter] = useState(0)
    const [breakGraphic, setBreakGraphic] = useState({toShow: null, used: []})
    const screen1 = useFullScreenHandle();
    const reportChange = useCallback((state, handle) => {
        if (handle === screen1) {
            if (!state) {
                setEventTimePlay(false)
            }
        }
    }, [screen1]);
    const breakGraphics = [breakImage1, breakImage2, breakImage3, breakImage4]
    useEffect(() => {
        const whereAreWe = schedule[eventCounter]
        if (['break', 'lbreak'].includes(whereAreWe)) {
            const result = selectBreakGraphic(breakGraphics, breakGraphic)
            setBreakGraphic({
                toShow: result[0],
                used: breakGraphic.used.concat(result[1])
            })
        }
        
    }, [eventCounter])

    // reset the break graphics
    useEffect(() => {
        // time to start over
        if (breakGraphic.used.length > 4) {
            const defaults = {toShow: null, used: []}
            const result = selectBreakGraphic(breakGraphics, defaults)
            setBreakGraphic({
                toShow: result[0],
                used: defaults.used.concat(result[1])
            })
        }
    }, [breakGraphic])

    const howMuchTime = useMemo(() => {
        // TIME IN MINUTES
        const eventTime = 15
        //const eventTime = .25
        const lunchTime = 45
        const breakTime = 5
        //const breakTime = .25
        const lBreakTime = 25
        //const lBreakTime = .10
        //const eventTime = .10
        //const lunchTime = .10
        //const breakTime = .10
        const whereAreWe = schedule[eventCounter]
        if (whereAreWe == 'break') {
            return breakTime
        } else if (whereAreWe == 'lunch') {
            return lunchTime
        } else if (whereAreWe == 'lbreak') {
            return lBreakTime
        }

        return eventTime


    }, [eventCounter])
    const whereAreWe = schedule[eventCounter]
    const suffix = schedule[eventCounter] == 'lbreak' ? 'Break' : schedule[eventCounter]
    const onBreak = ['break', 'lbreak'].includes(whereAreWe)
    return (
        <>
            <Typography variant='h3'>Competition Slideshow Timer Page</Typography>
            <Grid2>
                <button onClick={() => {
                    screen1.enter()
                    setEventTimePlay(true)
                }}>
                    Start Slide Show
                </button>
                <Grid2 container sx={{ justifyContent: 'space-between' }}>
                    {
                        schedule.map((v, k) => {
                            return (
                                <Typography variant='body1' key={k}
                                    sx={{ p: 2, backgroundColor: eventCounter == k ? 'red' : '' }}
                                    onClick={(e) => {

                                        e.preventDefault()
                                        setEventCounter(k)
                                    }}
                                >{v}</Typography>

                            )

                        })
                    }
                </Grid2>


                <FullScreen handle={screen1} onChange={reportChange} style={{}}>
                    <Grid2 container>
                        <Grid2 sx={{ textAlign: 'center' }}>
                            {eventTimePlay && <>
                                <Grid2 container sx={{
                                    backgroundImage: "url(" + (onBreak ? breakGraphic.toShow: newGeneralGraphic2024) + ")",
                                    backgroundPosition: 'bottom',
                                    backgroundColor: 'white',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    width: '100vw',
                                    height: '100vh',
                                    alignItems: onBreak ? 'end': 'center'
                                }}>
                                    <Grid2 item="true" xs={12} >
                                        <VisualTimer whereAreWe={whereAreWe} minutes={howMuchTime} restartKey={eventCounter} onFinished={() => {
                                            let toUpdate = parseInt(eventCounter) + 1
                                            if (schedule[toUpdate]) {
                                                setEventCounter(parseInt(eventCounter) + 1)
                                            } else {
                                                screen1.exit()

                                            }
                                        }} suffix={`${isNaN(schedule[eventCounter]) ? '' : 'Stage Number'} ${String(suffix).toUpperCase()}`} />
                                    </Grid2>
                                </Grid2>
                            </>
                            }
                        </Grid2>
                    </Grid2>

                </FullScreen >
            </Grid2 >
        </>
    )

}



export default SlideShowTimerPage;
