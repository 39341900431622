import React, { createContext, useContext, useEffect, useState } from "react";
import { FirebaseNewContext } from "./FirebaseNewContext";
import { onValue } from "@firebase/database";

export const GlobalSettingsContext = createContext('');

const GlobalSettingsContextProvider = (props) => {
    const [globalSettings, setGlobalSettings] = useState({})
    const { firebase } = useContext(FirebaseNewContext)

    useEffect(() => {
        const fetch = async () => {
            onValue(firebase.listenGlobalSettings(), snapshot => {
                const globalSettingsResult = snapshot.val()
                setGlobalSettings(globalSettingsResult)
            })

        }
        fetch()
            .catch(console.error)
        return () => {
            console.log('closing out of global settings context ;)')
        }

    }, [])// eslint-disable-line

    return (
        <GlobalSettingsContext.Provider value={{ globalSettings }}>
            {props.children}
        </GlobalSettingsContext.Provider>
    )
}
export default GlobalSettingsContextProvider