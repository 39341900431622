import React from 'react';
import ScoringFormNew from '../components/forms/ScoringFormNew';
import { useLocation, useParams } from 'react-router-dom';
import ScoringFormView from '../components/forms/ScoringFormView';

function ScoringPage() {
    const params = useParams()
    const location = useLocation()
    switch (true) {
        case location.pathname.includes('view'):
            return (
                <ScoringFormView
                    scoreUid={params.scoreUid}
                    competition={params.competition}
                />
            )
        case location.pathname.includes('for'):
            return (
                <ScoringFormNew
                    editScore={params.editScore}
                    competition={params.competition}
                    eventUid={params.eventUid}
                    teamUid={params.teamUid}
                />
            )
        default:
            return <ScoringFormNew />
    }

}



export default ScoringPage;