import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../routes/routes';
import { AppBar, Button, Container, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import { Menu as MenuIcon } from '@mui/icons-material';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { ROLES } from '../misc/Utils';

const Unauthorized = () => {
  const nonAuthNav = [
    { name: 'Home', route: routes.LANDING },
    { name: 'Sign In', route: routes.ACCOUNT }
  ]
  return (
    <Box sx={{ flexGrow: 1, display: { md: 'flex' }, alignItems: { md: 'center' } }}>
      {
        nonAuthNav.map((v, k) => {
          return (
            <Button key={k} component={Link} to={v.route} sx={{ color: 'white' }}>{v.name}</Button>
          )
        })
      }
    </Box>
  )
}


const Authorized = () => {
  const { user } = useContext(UserDataContext)
  const { firebase } = useContext(FirebaseNewContext)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const adminNav = [
    { name: 'Admin Actions', route: routes.ADMIN_ACTIONS_PAGE },
    { name: 'Results Page', route: routes.RESULTS_PAGE }
  ]
  const judgeNav = [
    { name: 'Event Scoresheet', route: routes.EVENT_SCORESHEET_PAGE }
  ]
  const teamNav = [
    { name: 'Invite Teammate', route: routes.INVITE_TEAMMATE_PAGE}

  ]
  const defaultAuthNavSandwhichTop = [
    { name: 'Dashboard', route: routes.DASHBOARD },
  ]
  const defaultAuthNavSandwhichBottom = [
    { name: 'Account', route: routes.ACCOUNT },
    { name: 'Signout', action: firebase.doSignOut }
  ]

  let sandwhichMeat = []
  if (user.role === ROLES.ADMIN) {
    sandwhichMeat = adminNav
  } else if (user.role === ROLES.JUDGE) {
    sandwhichMeat = judgeNav
  } else if (user.role === ROLES.TEAM) {
    sandwhichMeat = teamNav
  }
  const nav = [...defaultAuthNavSandwhichTop, ...sandwhichMeat, ...defaultAuthNavSandwhichBottom]

  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorElNav}
          keepMounted
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          {
            nav.map((v, k) => {
              return (
                <MenuItem key={k} onClick={handleCloseNavMenu}>
                  {
                    v.route &&
                    <Button component={Link} to={v.route}>{v.name}</Button>
                  }
                  {
                    v.action &&
                    <Button onClick={v.action}>{v.name}</Button>
                  }
                </MenuItem>
              )
            })
          }
        </Menu>
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: { md: 'center' } }}>
        {
          nav.map((v, k) => {
            if (v.route) {
              return (
                <Button key={k} component={Link} to={v.route} sx={{ color: 'white' }}>{v.name}</Button>
              )
            }
            return (
              <Button key={k} onClick={v.action} sx={{ color: 'white' }}>
                {v.name}
              </Button>
            )

          })
        }
      </Box>
    </>

  )
}
const Navbar = (props) => {
  const { globalSettings } = useContext(GlobalSettingsContext)
  const { user } = useContext(UserDataContext)

  const whichComponent = () => {
    switch (true) {
      case Object.keys(globalSettings).length === 0 || Object.keys(user).length === 0:
        return <></>
      case !user.loggedIn:
        return <Unauthorized {...props} />
      default:
        return <Authorized />
    }
  }
  return (
    <AppBar position='sticky'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {whichComponent()}
        </Toolbar>
      </Container>
    </AppBar >
  )
}





export default Navbar;