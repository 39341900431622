import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel, TextField, Autocomplete, createFilterOptions } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
//import Autocomplete from '@mui/material';
//import Autocomplete from '@mui/material/Autocomplete';



const EventDropdown = (props) => {
    const {
        value,
        onChange,
        readonly,
        eventsMapValues
    } = props

    return (
        <Grid2>
            <FormControl className='scoring-form-div'
                sx={{ minWidth: '200px' }}
            >
                <InputLabel id="demo-simple-select-helper-label">Event</InputLabel>
                <Select
                    name="eventUid"
                    value={value}
                    onChange={(e) => onChange(e, { ...eventsMapValues[e.target.value], uid: e.target.value } || {})}
                    disabled={readonly}
                    variant="standard"
                >
                    <MenuItem value={''}>Event</MenuItem>
                    {Object.keys(eventsMapValues||{}).map(eUid => {
                        return (
                            <MenuItem
                                key={eUid}
                                value={eUid}
                            >
                                {`${eventsMapValues[eUid]['number']}-${eventsMapValues[eUid]['name']}; ${eventsMapValues[eUid]['company']}`}
                            </MenuItem>
                        )
                    })}

                </Select>
            </FormControl>
        </Grid2>
    )
}
const MultiSearchSelectEventWrapper = (props) => {
    // NOTE - this is because we were at first only storing an array of UIDs to associate
    //  with the TeamForm
    const {
        selectedEvents = [],
        eventsMapValues
    } = props
    const newValues = Object.keys(eventsMapValues).reduce((acc, v) => {
        if (selectedEvents.includes(v)) {
            acc = acc.concat({
                ...eventsMapValues[v],
                ...{
                    uid: v
                }
            })
        }
        return acc
    }, [])

    return (
        <MultiSearchSelectEvent
            {...props}
            value={newValues}
        />
    )
}

const MultiSearchSelectEvent = (props) => {
    const {
        value,
        onChange,
        readonly,
        eventsMapValues = {}
    } = props

    const filterMethod = (options, { inputValue }) => {
        return options.filter(item => {
            return item.name.toLowerCase().includes(inputValue)
                || item.number.toString().includes(inputValue)
                || item.fullTitle.toLowerCase().includes(inputValue)
                || item.company.toLowerCase().includes(inputValue)
        })
    }
    const eventsList = Object.keys(eventsMapValues).map(v => {
        return { ...eventsMapValues[v], uid: v }
    })


    return (
        <>
            <Autocomplete
                fullWidth
                readOnly={readonly}
                multiple
                filterOptions={filterMethod}
                getOptionLabel={(option) => `${option.number} - ${option.name} - ${option.fullTitle}`}
                options={eventsList}
                renderInput={(params) => <TextField {...params} label="Events" />}
                isOptionEqualToValue={(option, value) => {
                    return option.uid === value.uid
                }}
                value={value}
                onChange={(event, value, reason, details) => {
                    onChange(value)
                }}
            />
        </>
    )
}

export {
    EventDropdown,
    MultiSearchSelectEvent,
    MultiSearchSelectEventWrapper
}