import React, { useContext, useMemo } from 'react';
import CompetitionForm from '../components/forms/CompetitionForm';
import * as routes from '../routes/routes';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';

const competitionColumns = [
    { field: 'col1', headerName: 'Competition Name', width: 350 },
    {
        field: 'col2', headerName: 'Actions', width: 150, getApplyQuickFilterFn: null,
        renderCell: (params) => {
            return <Button component={Link} to={routes.routeReplace(routes.COMPETITION_PAGE_EDIT, { edit: params.value.uid })}>Edit</Button>
        }
    },
]

function CompetitionPage(props) {
    const location = useLocation()
    const params = useParams()
    const { globalSettings: { competitions } } = useContext(GlobalSettingsContext)
    const competitionMap = useMemo(() => {
        return competitions
    }, [competitions])

    const rows = useMemo(() => {
        return Object.keys(competitionMap).map((v) => {
            const competition = competitionMap[v]
            return {
                id: v,
                col1: competition,
                col2: { uid: v }
            }
        })

    }, [competitionMap])
    return (
        <>
            {
                location.pathname === routes.COMPETITION_PAGE &&
                <>
                    <Grid2 container justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant='h3'>Competitions</Typography>
                        <Button component={Link} to={routes.COMPETITION_PAGE_NEW}>Create A Competition</Button>
                    </Grid2>
                    <Grid2>
                        <DataGrid
                            sx={{ marginBottom: '25px' }}
                            autoHeight
                            rows={rows || []}
                            columns={competitionColumns}
                            slots={{
                                toolbar: GridToolbar,
                                noRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        <Typography variant='body'>No Competitions Have Been Created</Typography>
                                    </Stack>
                                ),
                            }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />

                    </Grid2>

                </>
            }

            {
                Object.keys(params).length !== 0 &&
                <CompetitionForm {...props} competition={params.edit} />
            }
            {
                location.pathname.includes(routes.COMPETITION_PAGE_NEW) &&
                <CompetitionForm {...props} />

            }
        </>
    )

}



export default CompetitionPage;
