import React, { Component } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './styles/App.css';
import './styles/Login.css';
import './styles/Scoring.css';
import './styles/Dashboard.css';
import './styles/CountdownCircleTimer.css'
import * as routes from './routes/routes';
import LandingPage from './components/landing/LandingPage';
import Navbar from './components/navbar/Navbar';
import SignInPage from './components/signin/SignIn';
import { PasswordForgetForm } from './components/signin/PasswordForget';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import AdminActionsRouter from './routers/AdminActionsRouter';
import ResultsRouter from './routers/ResultsRouter';
import JudgeActionsRouter from './routers/JudgeActionsRouter';
import DashboardRouter from './routers/DashboardRouter';
import ScoringRouter from './routers/ScoringRouter';
import AccountRouter from './routers/AccountRouter';
import MainRouter from './routers/MainRouter';
import LiabilityFormDialog from './components/forms/LiabilityFormDialog';
import TeamActionsRouter from './routers/TeamActionsRouter';

// TODO - make everything run through routers
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Navbar />
        <Grid2 container flexDirection={'column'}>
          <Routes>
            <Route exact path={routes.LANDING} element={<MainRouter />} />
            <Route exact path={routes.SIGN_IN} element={<MainRouter />} />
            <Route exact path={routes.PASSWORD_FORGET} element={<MainRouter />} />
            <Route exact path={routes.ACCOUNT} element={<AccountRouter />} />

            <Route exact path={routes.DASHBOARD} element={<DashboardRouter />} />
            <Route exact path={routes.ADMIN_SCORING_DASHBOARD} element={<DashboardRouter />} />


            <Route exact path={routes.CATEGORY_PAGE} element={<AdminActionsRouter />} />
            <Route exact path={routes.CATEGORY_PAGE_EDIT} element={<AdminActionsRouter />} />
            <Route exact path={routes.CATEGORY_PAGE_NEW} element={<AdminActionsRouter />} />

            <Route exact path={routes.COMPETITION_PAGE} element={<AdminActionsRouter />} />
            <Route exact path={routes.COMPETITION_PAGE_EDIT} element={<AdminActionsRouter />} />
            <Route exact path={routes.COMPETITION_PAGE_NEW} element={<AdminActionsRouter />} />

            <Route exact path={routes.BASE_SCORESHEET_PAGE_NEW} element={<AdminActionsRouter />} />
            <Route exact path={routes.BASE_SCORESHEET_PAGE_EDIT} element={<AdminActionsRouter />} />

            <Route exact path={routes.EVENT_PAGE} element={<AdminActionsRouter />} />
            <Route exact path={routes.EVENT_PAGE_EDIT} element={<AdminActionsRouter />} />
            <Route exact path={routes.EVENT_PAGE_NEW} element={<AdminActionsRouter />} />

            <Route exact path={routes.DIVISION_PAGE} element={<AdminActionsRouter />} />
            <Route exact path={routes.DIVISION_PAGE_EDIT} element={<AdminActionsRouter />} />
            <Route exact path={routes.DIVISION_PAGE_NEW} element={<AdminActionsRouter />} />

            <Route exact path={routes.USER_PAGE} element={<AdminActionsRouter />} />
            <Route exact path={routes.USER_PAGE_EDIT} element={<AdminActionsRouter />} />
            <Route exact path={routes.USER_PAGE_NEW} element={<AdminActionsRouter />} />

            <Route exact path={routes.TEAM_PAGE} element={<AdminActionsRouter />} />
            <Route exact path={routes.TEAM_PAGE_EDIT} element={<AdminActionsRouter />} />
            <Route exact path={routes.TEAM_PAGE_NEW} element={<AdminActionsRouter />} />

            <Route exact path={routes.SLIDE_SHOW_TIMER_PAGE} element={<AdminActionsRouter />} />


            <Route exact path={routes.ADMIN_ACTIONS_PAGE} element={<AdminActionsRouter />} />
            <Route exact path={routes.LIABILITY_PAGE} element={<AdminActionsRouter />} />
            <Route exact path={routes.GLOBAL_SETTINGS_PAGE} element={<AdminActionsRouter />} />
            <Route exact path={routes.PENALTIES_REPORT} element={<AdminActionsRouter />} />
            <Route exact path={routes.BASE_SCORESHEET_PAGE} element={<AdminActionsRouter />} />

            <Route exact path={routes.EVENT_SCORESHEET_PAGE} element={<JudgeActionsRouter />} />
            <Route exact path={routes.EVENT_SCORESHEET_PAGE_NEW} element={<JudgeActionsRouter />} />
            <Route exact path={routes.EVENT_SCORESHEET_PAGE_EDIT} element={<JudgeActionsRouter />} />

            <Route exact path={routes.RESULTS_PAGE} element={<ResultsRouter />} />

            <Route exact path={routes.SCORING_FORM} element={<ScoringRouter />} />
            <Route exact path={routes.SCORING_FOR_EVENT_FOR_TEAM} element={<ScoringRouter />} />
            <Route exact path={routes.SCORING_FOR_EVENT} element={<ScoringRouter />} />
            <Route exact path={routes.SCORING_FOR_TEAM} element={<ScoringRouter />} />
            <Route exact path={routes.SCORING_EDIT} element={<ScoringRouter />} />
            <Route exact path={routes.SCORING_VIEW} element={<ScoringRouter />} />

            <Route exact path={routes.INVITE_TEAMMATE_PAGE} element={<TeamActionsRouter />} />

          </Routes>
          <LiabilityFormDialog />
        </Grid2>
      </BrowserRouter >
    );
  }
}

export default App;
