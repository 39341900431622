import React, { Component, useReducer, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import { DataGrid, GridRowsProp, GridColDef, GridToolbar, GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import * as ROUTES from '../../routes/routes';
import { Button, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { CheckBox, Clear } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';

const eventActionColumn = (params) => {
    let toNavigate = ROUTES.routeReplace(ROUTES.SCORING_FOR_EVENT_FOR_TEAM, {
        eventUid: params.value.event.eventUid,
        teamUid: params.value.team.teamUid
    })
    if (params.value.entry.entered) {
        toNavigate = ROUTES.routeReplace(ROUTES.SCORING_EDIT, {
            editScore: params.value.entry.score.uid,
            competition: params.value.competition
        })
    }
    
    return (
        <Button component={Link} to={toNavigate}>{params.value.entry.entered ? 'edit score' : 'enter new score'}</Button>
    )


}
const eventColumns = [
    { field: 'col1', headerName: 'Team Number ', width: 150 },
    { field: 'col2', headerName: 'Team Number ', width: 300 },
    {
        field: 'col3', headerName: 'Has been entered', width: 150,
        renderCell: (params) => {
            if (params.value) {
                return (<CheckBox />)

            }
            return (<Clear />)
        }
    },
    { field: 'col4', headerName: 'Final Score', width: 200, getApplyQuickFilterFn: null },
    { field: 'col5', headerName: 'Division', width: 200 },
    { field: 'col6', headerName: 'Category', width: 200 },
    {
        field: 'col7', headerName: 'Action', width: 150,
        renderCell: eventActionColumn
    }
]

// TODO - this should be the same as the eventActionColumn
//  keeping it separated though for 

const JudgeDashboardNew = (props) => {
    const { firebase } = useContext(FirebaseNewContext)
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { defaultSelectedCompetition, user } = useContext(UserDataContext)
    const { eventsMap: globalEventsMap, scoresMap: globalScoresMap, teamsMap: globalTeamsMap } = useContext(CommonDataContext)
    const eventsMap = useMemo(() => {
        return globalEventsMap[defaultSelectedCompetition] || {}
    }, [defaultSelectedCompetition, globalEventsMap])
    const [scoresMap, setScoresMap] = useState({})
    const teamsMap = useMemo(() => {
        return globalTeamsMap[defaultSelectedCompetition] || {}
    }, [])
    const categoriesMap = useMemo(() => {
        return globalSettings['categories'][defaultSelectedCompetition]
    }, [])
    const divisionsMap = useMemo(() => {
        return globalSettings['divisions'][defaultSelectedCompetition]
    }, [])
    //let eventKeysWithTeams = {}
    useEffect(() => {
        const fetch = async () => {
            const scoresMapResult = await firebase.getScores(defaultSelectedCompetition)
            setScoresMap(scoresMapResult)

        }
        fetch().catch(console.error)

    }, [])
    let eventKeysWithTeams = useMemo(() => {
        return Object.keys(eventsMap).reduce((acc, eventUid) => {
            const judgeEvents = user['eventsNew'] || false
            const judgeEventUidList = judgeEvents ? judgeEvents[defaultSelectedCompetition] : []
            console.log('this is our default competition', defaultSelectedCompetition)
            if (!(judgeEventUidList.includes(eventUid))) {
                return acc
            }
            acc[eventUid] = Object.keys(teamsMap).reduce((tAcc, tUid) => {
                //console.log('this is our team', teamsMap[tUid]['teamNumber'])
                tAcc[tUid] = { ...teamsMap[tUid], ...{ entered: false } }
                return tAcc
            }, {})
            return acc
        }, {})
    })
    Object.keys(scoresMap).reduce((acc, scoreUid) => {
        const score = { uid: scoreUid, ...scoresMap[scoreUid] }
        // events edit
        if (score.eventUid in eventKeysWithTeams) {
            if (score.teamUid in eventKeysWithTeams[score.eventUid]) {
                eventKeysWithTeams[score.eventUid][score.teamUid]['entered'] = true
                eventKeysWithTeams[score.eventUid][score.teamUid]['score'] = score
            }

        }
        return acc
    }, {})

    // TODO - break each of these sections into tables that are only
    //  fed the results (even the results of search)

    return (
        <Grid2>
            <Typography variant='h5'>Judge Dashboard</Typography>

            {
                Object.keys(eventKeysWithTeams).length === 0 &&
                <Typography variant='body'>No Events Assigned</Typography>
            }
            {
                Object.keys(eventKeysWithTeams).map((e) => {
                    // TODO - break out into functions that we can reuse for the render value
                    const event = eventsMap[e]
                    const eventRows = Object.keys(eventKeysWithTeams[e]).map((t, k) => {
                        const team = teamsMap[t]
                        const entry = eventKeysWithTeams[e][t]
                        return {
                            id: `${k}${event.number}${team.teamNumber}`,
                            col1: team.teamNumber,
                            col2: team.teamName,
                            col3: entry.entered,
                            col4: entry.entered ? entry.score.finalScore : '-',
                            col5: divisionsMap[team.division],
                            col6: categoriesMap[team.category],
                            col7: { entry, competition: defaultSelectedCompetition, event: { ...event, ...{ eventUid: e } }, team: { ...team, ...{ teamUid: t } } }
                        }

                    })
                    const CustomFooter = () => {
                        return (
                            <GridFooterContainer
                                sx={{

                                    marginBottom: '25px', // To delete double border.
                                }}
                            >
                                {/* Add what you want here */}
                                <GridFooter />
                            </GridFooterContainer>
                        );
                    }
                    return (
                        <Grid2 container sx={{ flexDirection: 'column', m: 5 }} key={event.number} style={{ height: '500px', width: '100%', }}>
                            <Grid2 sx={{ textAlign: 'left' }}>
                                <Typography variant='h5' sx={{ paddingTop: '25px', paddingBottom: '25px', marginTop: '25px' }}>Event Name: {event.name}</Typography>
                                <Typography>Event Number: {event.number}</Typography>
                                <Typography>Company: {event.company}</Typography>
                                <Typography>Full Title: {event.fullTitle}</Typography>
                            </Grid2>
                            <DataGrid
                                sx={{ marginBottom: '25px' }}
                                key={event.name}
                                rows={eventRows}
                                columns={eventColumns}
                                slots={{ toolbar: GridToolbar }}
                                components={{ Footer: CustomFooter }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Grid2>
                    )


                })
            }
        </Grid2>
    )

}





export default JudgeDashboardNew