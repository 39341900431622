import { CheckBox } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, FormGroup, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import FormErrorMessages from './Messages/FormErrorMessages';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';

function CompetitionForm(props) {
    const [competition, setCompetition] = useState('')
    const [competitionMap, setCompetitionMap] = useState({})
    const [errorMessages, setErrorMessages] = useState([])
    const [defaultCompetition, setDefaultCompetition] = useState(false)
    const [globalDefaultCompetition, setGlobalDefaultCompetition] = useState('')
    const { firebase } = useContext(FirebaseNewContext)
    useEffect(() => {
        const fetch = async () => {
            const competitionMapResult = await firebase.getCompetitionMap()
            const defaultCompetitionResult = await firebase.getDefaultCompetition()
            setCompetitionMap(competitionMapResult)
            setGlobalDefaultCompetition(defaultCompetitionResult)
            if (props.competition && Object.keys(competitionMapResult).includes(props.competition)) {
                setCompetition(competitionMapResult[props.competition])
                setDefaultCompetition(props.competition == defaultCompetitionResult)
            }
        }
        fetch()
            .catch(console.error)

    }, [])

    const isValid = (competition, competitionMap) => {
        let validCheck = { result: true, errorMessages: [] };
        if (competition === '') {
            validCheck = {
                result: false,
                errorMessages: ['competition cannot be empty']
            }
            return validCheck

        }

        const normalizedCompetitionMap = Object.keys(competitionMap).map((v) => competitionMap[v]).map((v) => {
            return String(v).toUpperCase().trim()
        })

        const isIn = normalizedCompetitionMap.includes(String(competition).toUpperCase().trim())
        if (isIn) {
            validCheck = {
                result: false,
                errorMessages: ['competition already exists']
            }
        }

        return validCheck
    }

    const insertNewCompetition = async () => {
        const isValidCheck = isValid(competition, competitionMap)
        setErrorMessages(isValidCheck.errorMessages)
        if (isValidCheck.result) {
            const result = await firebase.insertCompetition(competition)
            if (defaultCompetition) {
                firebase.setDefaultCompetition(result.key)
            }
            window.location.reload(false)
        }

    }

    const patchExistingCompetition = async () => {
        const competitionPropKey = props.competition
        const excludedMap = Object.keys(competitionMap).reduce((acc, v) => {
            if (competitionPropKey === v) {
                return acc
            }
            acc[v] = competitionMap[v]
            return acc
        }, {})

        const isValidCheck = isValid(competition, excludedMap)
        if (!isValidCheck.result) {
            setErrorMessages(isValidCheck.errorMessages)
        } else {
            setErrorMessages([])
            await firebase.patchCompetition(competitionPropKey, competition)
            if (defaultCompetition) {
                firebase.setDefaultCompetition(competitionPropKey)
            }
            window.location.reload(false)
        }
    }
    const submitButtonPressed = useCallback((e) => {
        e.preventDefault();
        if (props.competition) {
            patchExistingCompetition()
        } else {
            insertNewCompetition()
        }
    }, [competition, competitionMap, defaultCompetition])

    return (
        <Grid2>
            <Typography variant='h5'>{props.competition ? 'Edit' : 'Create'} a competition</Typography>
            <Grid2>
                {
                    !props.competition &&
                    Object.keys(competitionMap).length > 0 &&
                    (
                        <>
                            <Typography variant='h6'>Currently Created Competition{Object.keys(competitionMap).length === 1 ? '' : 's'}</Typography>
                            <List>
                                {Object.keys(competitionMap).map((v) => competitionMap[v]).map((c, k) => {
                                    return (
                                        <ListItem key={`${k}-${c}`}
                                            sx={{ backgroundColor: k % 2 == 0 ? '#eee' : 'white' }}
                                        >
                                            <ListItemText>
                                                {c} {competitionMap[globalDefaultCompetition] == c && '- default competition'}
                                            </ListItemText>
                                        </ListItem>
                                    )

                                })}
                            </List>
                        </>
                    )
                }
                <TextField
                    name="competition"
                    value={competition}
                    label="Competition Name"
                    variant="standard"
                    onChange={(e) => {
                        setCompetition(e.target.value)
                    }}
                    placeholder="competition name"
                />

                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={defaultCompetition} onChange={() => setDefaultCompetition(!defaultCompetition)} />} label="Set As Default Competition" />
                </FormGroup>

            </Grid2>
            <Grid2>
                <FormErrorMessages errorMessages={errorMessages} />
                <Button onClick={submitButtonPressed}>{props.category ? 'Edit' : 'Submit'}</Button>
            </Grid2>
        </Grid2>

    )
}

export default CompetitionForm