//import { ListItem, ListItemText, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useContext, useMemo } from 'react';
import { CommonDataContext } from '../../../firebase/CommonDataContext';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const liabilityColumns = [
    { field: 'col1', headerName: 'Username', width: 250 },
    { field: 'col2', headerName: 'Email', width: 250 },
    { field: 'col3', headerName: 'Role', width: 200 },
    {
        field: 'col4', headerName: 'Submitted Liability Consent?', width: 250,
        valueFormatter: ({ value }) => {
            const { liabilityMap, v } = value
            const signatories = liabilityMap.signatories || {}
            return v in signatories ? 'yes' : 'no'

        },
        sortComparator: (v1, v2) => {
            const { liabilityMap: v1LiabilityMap, v: v1Key } = v1
            const { liabilityMap: v2LiabilityMap, v: v2Key } = v2
            const signatories1 = v1LiabilityMap.signatories || {}
            const signatories2 = v2LiabilityMap.signatories || {}
            const v1Result = v1Key in signatories1 ? signatories1[v1Key]['agreedToConsentSelf'] : false
            const v2Result = v2Key in signatories2 ? signatories2[v2Key]['agreedToConsentSelf'] : false
            return v1Result - v2Result

        },
        renderCell: ({ value }) => {
            const { liabilityMap, v } = value
            const signatories = liabilityMap.signatories || {}
            return v in signatories ?
                <CheckIcon sx={{ color: 'green', marginLeft: '75px' }} />
                :
                <CloseIcon sx={{ color: 'red', marginLeft: '75px' }} />
        }
    },
]

// TODO - rename to table 
const LiabilityList = (props) => {
    const { teamsMap: globalTeamsMap,
        liabilityMap: globalLiabilityMap,
        teamUsersMap: globalTeamUsersMap,
        adminUsersMap,
        judgesMap: globalJudgesMap } = useContext(CommonDataContext)

    const teamUsersMap = useMemo(() => {
        return Object.keys(globalTeamUsersMap).reduce((acc, v) => {
            const user = globalTeamUsersMap[v]
            if ('teamsNew' in user && props.competition in user.teamsNew) {
                acc[v] = user
            }
            return acc
        }, {})
    }, [globalTeamUsersMap, props.competition])

    const liabilityMap = useMemo(() => {
        const exists = globalLiabilityMap
        return exists && props.competition in globalLiabilityMap ? globalLiabilityMap[props.competition] : {}
    }, [globalLiabilityMap, props.competition])

    const judgesMap = useMemo(() => {
        return Object.keys(globalJudgesMap).reduce((acc, v) => {
            const judge = globalJudgesMap[v]
            if ('eventsNew' in judge && props.competition in judge.eventsNew) {
                acc[v] = judge
            }
            return acc
        }, {})

    }, [globalJudgesMap, props.competition])

    const judgesAndTeams = { ...judgesMap, ...teamUsersMap, ...adminUsersMap }
    const rows = Object.keys(judgesAndTeams).map((v) => {
        const user = judgesAndTeams[v]
        return {
            id: v,
            col1: user.username,
            col2: user.email,
            col3: user.role,
            col4: { liabilityMap, v }
        }
    })

    return (
        <Grid2>
            <DataGrid
                sx={{ marginBottom: '25px' }}
                autoHeight
                rows={rows || []}
                columns={liabilityColumns}
                slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            <Typography variant='body'>No admins, score committee members, teams, or judges in the system</Typography>
                        </Stack>
                    ),
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </Grid2>
    )
}

export default LiabilityList
