import { Box, Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useReducer, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import CompetitionDropdown from './Dropdowns/CompetitionDropdown';
import { ROLES, SCORESHEET_STATES, scoreSheetCalculation } from '../misc/Utils';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import PenaltyForm from './ScoringFormComponents/PenaltyFormNew';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const extractID = (id) => {
    return parseInt(`${id}`.match(/\d+/g))
}

const isValidScoresheet = (penalties) => {
    let validCheck = { result: true, errorMessages: [] }
    penalties.forEach(element => {
        if (element.value === '-' || element.value === '') {
            validCheck.result = false
            validCheck.errorMessages = validCheck.errorMessages.concat({
                id: element.id,
                message: '"Value In Seconds" must contain a number'
            })
        }
    });
    return validCheck
}


const scoresheetFormInitialState = (values) => {
    // TODO - fill out with base properties
    return {
        status: '',
        penalties: [],
        errors: [],
        tabNumber: 0
    }
    /*
    State Flow
    CREATED -> SUBMITTED -> APPROVED | LOCKED
    APPROVED | LOCKED -> CREATED
    APPROVED | LOCKED -> EDIT_REQUESTED -> CREATED
    status: 'SUBMITTED' | 'APPROVED' | 'CREATED' | 'EDIT_REQUESTED' | 'LOCKED'
    penalties: {
        id: int, // self determined
        type: str,
        description: str,
        value: int,
        hasMultiplier: bool, // true
        custom: bool, // false
        format: str, // 'SECONDS'
    }
    */
}
const Penalty = (id) => {
    return {
        id,
        type: '',
        description: '',
        value: 0,
        hasMultiplier: true,
        custom: false,
        format: 'SECONDS'
    }
}

const scoresheetFormReducer = (state, action) => {
    switch (action.type) {
        case 'change_tab':
            return {
                ...state,
                tabNumber: action.to
            }
        case 'add_item':
            // TODO - change the property based on whether or not you are an admin or judge
            const newAddID = `${state.penalties.length + 1}${action.idSuffix}`
            return {
                ...state,
                penalties: [...state.penalties, new Penalty(newAddID)],
                errors: []
            }
        case 'remove_item':
            const removeID = action.id
            const removedPenaltyList = state.penalties.filter((x) => {
                return x.id != removeID
            })
            const recalculateIDsPenaltyList = removedPenaltyList.map((v, i) => {
                return {
                    ...v,
                    id: i + 1
                }
            })
            return {
                ...state,
                penalties: recalculateIDsPenaltyList,
                errors: []
            }
        case 'modify_item':
            const modifyID = action.id
            const modifiedPenaltyList = state.penalties.reduce((acc, v) => {
                if (v.id == modifyID) {
                    v = { ...v, ...action.values }
                }
                acc = acc.concat(v)
                return acc
            }, [])
            return {
                ...state,
                penalties: modifiedPenaltyList
            }
        case 'modify_number_item':
            // TODO - ensure when submitting we don't have any floating '-' hanging around
            // that is in the valid check for the penalties
            const numberRe = /^-?[0-9]*$/;
            const numberModifyID = action.id
            const numberModifiedPenaltyList = state.penalties.reduce((acc, v) => {
                if (v.id === numberModifyID) {
                    if (action.value === '' || numberRe.test(action.value)) {
                        v = { ...v, ...{ value: action.value } }
                    }
                }
                acc = acc.concat(v)
                return acc
            }, [])

            return {
                ...state,
                penalties: numberModifiedPenaltyList
            }
        case 'change_order':
            // if you didn't change anything
            if (action.id === action.to) {
                return state
            }
            const extractedItem = state.penalties.reduce((acc, v) => {
                if (v.id === action.id) {
                    acc = { ...v, ...{ id: action.to } }
                }
                return acc
            }, {})
            // TODO - do it a different way where we aren't doing mutation
            state.penalties.splice(extractID(action.to) - 1, 0, extractedItem)
            const orderedPenaltyList = state.penalties.reduce((acc, v) => {
                if (v.id !== action.id) {
                    acc = acc.concat(v)
                }
                return acc
            }, [])
            const reOrderedPenaltyList = orderedPenaltyList.map((v, i) => {
                return {
                    ...v,
                    id: `${extractID(i) + 1}${action.idSuffix}`
                }
            })
            return {
                ...state,
                penalties: reOrderedPenaltyList,
                errors: []
            }
        case 'update_error_messages':
            return {
                ...state,
                ...{ errors: action.value }
            }
        case 'set_initial_values':
            return {
                ...state,
                ...action.values
            }
        default:
            break;
    }
}

function PenaltyList({ state, dispatch, forEvent }) {
    return (
        <Grid2>
            <Button
                disabled={state.status === SCORESHEET_STATES.LOCKED}
                onClick={() => {
                    dispatch({
                        type: 'add_item',
                        // TODO - I dont like the id suffix
                        idSuffix: forEvent ? 'b' : 'a'
                    })

                }}>Add Penalty/Bonus</Button>
            <Grid2 container spacing={2}>
                {
                    state.penalties.map((v, _) => {
                        const errors = state.errors.filter((e) => {
                            if (v.id === e.id) {
                                return e
                            }
                        })
                        return (
                            <Grid2 key={v.id}>
                                <Card variant='outlined' sx={{
                                    padding: '15px',
                                    border: errors.length > 0 ? '1px solid red' : 'none'
                                }}>
                                    {
                                        errors.length > 0 &&
                                        errors.map((e, i) => {
                                            return (
                                                <Typography key={i}>
                                                    {e.message}
                                                </Typography>
                                            )

                                        })
                                    }
                                    <FormControl fullWidth>
                                        <InputLabel id="order">Order</InputLabel>
                                        <Select
                                            disabled={state.status === SCORESHEET_STATES.LOCKED}
                                            value={v.id}
                                            label={'Order'}
                                            onChange={(e) => {
                                                dispatch({
                                                    type: 'change_order',
                                                    id: v.id,
                                                    to: e.target.value,
                                                    // TODO - I dont like the id suffix
                                                    idSuffix: forEvent ? 'b' : 'a'
                                                })
                                            }}
                                        >
                                            {state.penalties.map((p, _) => {
                                                let message = `Put before #${extractID(p.id)}`
                                                if (p.id === v.id) {
                                                    message = `You are currently at #${extractID(p.id)}`
                                                }
                                                const disableIfAlreadyBeforeNext = extractID(p.id) === extractID(v.id) + 1
                                                return (
                                                    <MenuItem disabled={disableIfAlreadyBeforeNext}
                                                        value={p.id}
                                                        key={p.id}>
                                                        {message}
                                                    </MenuItem>
                                                )

                                            })}
                                        </Select>
                                    </FormControl>
                                    <Grid2 container sx={{ marginTop: '15px' }}>
                                        <FormGroup sx={{ marginRight: '15px' }}>
                                            <TextField
                                                disabled={state.status === SCORESHEET_STATES.LOCKED}
                                                label={"Name Of Field"}
                                                value={v.type}
                                                onChange={(e) => {
                                                    dispatch({
                                                        type: 'modify_item',
                                                        id: v.id,
                                                        values: {
                                                            type: e.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextField
                                                disabled={state.status === SCORESHEET_STATES.LOCKED}
                                                label={"Description Of Field"}
                                                value={v.description}
                                                onChange={(e) => {
                                                    dispatch({
                                                        type: 'modify_item',
                                                        id: v.id,
                                                        values: {
                                                            description: e.target.value
                                                        }
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Grid2>
                                    <Grid2 container sx={{ marginTop: '15px' }}>
                                        <FormGroup sx={{ marginRight: '15px' }}>
                                            <Select
                                                disabled={state.status === SCORESHEET_STATES.LOCKED}
                                                label={'Penalty/Bonus Value in Seconds'}
                                                value={v.value}
                                                onChange={(e) => {
                                                    dispatch({
                                                        type: 'modify_number_item',
                                                        id: v.id,
                                                        value: e.target.value
                                                    })
                                                }}
                                            >
                                                <MenuItem value={15}>15 (penalty)</MenuItem>
                                                <MenuItem value={30}>30 (penalty)</MenuItem>
                                                <MenuItem value={60}>60 (penalty)</MenuItem>
                                                <MenuItem value={90}>90 (penalty)</MenuItem>
                                                <MenuItem value={-30}>-30 (bonus)</MenuItem>
                                                <MenuItem value={-60}>-60 (bonus)</MenuItem>
                                            </Select>
                                        </FormGroup>
                                        <FormGroup sx={{ justifyContent: 'center' }}>
                                            <FormControlLabel
                                                disabled={true}
                                                label={'Has Multiplier?'}
                                                control={
                                                    <Checkbox
                                                        value={v.hasMultiplier}
                                                        onChange={(e) => {
                                                            dispatch({
                                                                type: 'modify_item',
                                                                id: v.id,
                                                                values: {
                                                                    hasMultiplier: e.target.checked
                                                                }
                                                            })

                                                        }}
                                                        checked={v.hasMultiplier}

                                                    />}
                                            />
                                        </FormGroup>
                                    </Grid2>
                                    <Button
                                        disabled={state.status === SCORESHEET_STATES.LOCKED}
                                        onClick={() => {
                                            dispatch({
                                                type: 'remove_item',
                                                id: v.id
                                            })
                                        }}>Remove Penalty/Bonus</Button>
                                </Card>
                            </Grid2>
                        )
                    })
                }
            </Grid2>
        </Grid2>
    )
}

function ScoresheetStateButtons({
    state,
    lockScoresheet,
    unlockScoresheet,
    approveScoresheet,
    submitScoresheetForReview,
    requestEdit
}) {
    const { user } = useContext(UserDataContext)
    // TODO - we set this up like a dummy
    // dont keep checking for admin role do it ONCE ina block
    //  then run through the different state statuses
    const adminButtons = () => {
        if (user.role !== ROLES.ADMIN) {
            return false
        }
        return (
            <>
                {
                    [SCORESHEET_STATES.APPROVED,
                    SCORESHEET_STATES.SUBMITTED,
                    SCORESHEET_STATES.CREATED
                    ].includes(state.status) &&
                    lockScoresheet &&
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            lockScoresheet()
                        }}>Lock Scoresheet</Button>
                }
                {
                    (state.status === SCORESHEET_STATES.LOCKED ||
                        state.status === SCORESHEET_STATES.EDIT_REQUESTED) &&
                    unlockScoresheet &&
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            unlockScoresheet()
                        }}
                    >
                        Unlock Scoresheet
                    </Button>
                }
                {
                    [SCORESHEET_STATES.SUBMITTED].includes(state.status) &&
                    approveScoresheet &&
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            approveScoresheet()
                        }}>Approve Scoresheet</Button>
                }
            </>

        )
    }
    console.log('this is the scoresheet stats', state.status)
    return (
        <Grid2>
            {
                adminButtons()
            }
            {
                state.status === SCORESHEET_STATES.CREATED &&
                submitScoresheetForReview &&
                <Button disabled={['', SCORESHEET_STATES.LOCKED].includes(state.status)} onClick={(e) => {
                    e.preventDefault()
                    console.log('about to submit the scoresheet for erview', state, requestEdit)
                    submitScoresheetForReview()
                }}>Submit Scoresheet</Button>
            }
            {
                [SCORESHEET_STATES.SUBMITTED, SCORESHEET_STATES.APPROVED].includes(state.status) &&
                <Button disabled={![SCORESHEET_STATES.SUBMITTED, SCORESHEET_STATES.APPROVED].includes(state.status) || user.role === ROLES.ADMIN} onClick={(e) => {
                    e.preventDefault()
                    requestEdit()
                }}>Request Edit</Button>

            }
        </Grid2>
    )


}

function EventScoresheetForm(props) {
    const [state, dispatch] = useReducer(scoresheetFormReducer, props, scoresheetFormInitialState)
    const { user } = useContext(UserDataContext)
    const { scoresheetsMap: commonScoresheetsMap } = useContext(CommonDataContext)
    const { firebase } = useContext(FirebaseNewContext)
    const globalScoresheetsMap = useMemo(() => {
        if (!commonScoresheetsMap || !commonScoresheetsMap[props.competition]) {
            return {}
        }
        return commonScoresheetsMap[props.competition]['global'] || {}
    }, [commonScoresheetsMap])
    useEffect(() => {
        if (props.competition &&
            commonScoresheetsMap &&
            commonScoresheetsMap[props.competition] &&
            commonScoresheetsMap[props.competition]['events']
        ) {
            dispatch({
                type: 'set_initial_values',
                values: commonScoresheetsMap[props.competition]['events'][props.eventUid] || {}
            })
        }

    }, [])

    const createScoresheet = async (penalties, status) => {
        const validCheck = isValidScoresheet(state.penalties)
        if (!validCheck.result) {
            dispatch({
                type: 'update_error_messages',
                value: validCheck.errorMessages
            })
            return false
        }
        await firebase.insertEventScoresheet({ status, penalties }, props.eventUid, props.competition)
        window.location.reload(false)
    }

    const editScoresheet = async (penalties, status) => {
        const validCheck = isValidScoresheet(state.penalties)
        if (!validCheck.result) {
            dispatch({
                type: 'update_error_messages',
                value: validCheck.errorMessages
            })
            return false
        }
        await firebase.editEventScoresheet({ status, penalties }, props.eventUid, props.competition)
        window.location.reload(false)

    }
    const submitButtonPressed = useCallback(() => {
        if (props.editScoresheet) {
            editScoresheet(state.penalties, SCORESHEET_STATES.CREATED)
        } else {
            createScoresheet(state.penalties, SCORESHEET_STATES.CREATED)
        }
    }, [state.penalties])

    const submitScoresheetForReview = useCallback(() => {
        console.log('this is our local calling of submit scoresheet')
        editScoresheet(state.penalties, SCORESHEET_STATES.SUBMITTED)
    }, [state.penalties])
    const lockScoresheet = useCallback(() => {
        editScoresheet(state.penalties, SCORESHEET_STATES.LOCKED)
    }, [state.penalties])
    const unlockScoresheet = useCallback(() => {
        editScoresheet(state.penalties, SCORESHEET_STATES.CREATED)
    }, [user, state.penalties])
    const approveScoresheet = useCallback(() => {
        editScoresheet(state.penalties, SCORESHEET_STATES.APPROVED)
    }, [state.penalties])
    const requestEdit = useCallback(() => {
        editScoresheet(state.penalties, SCORESHEET_STATES.EDIT_REQUESTED)
    }, [state.penalties])

    // TODO - do we actually want to do this???
    //  NOTE the following is important
    const combinedPenalties = [...(globalScoresheetsMap.penalties || []), ...state.penalties]
    const shouldDisableSubmitButton = [SCORESHEET_STATES.SUBMITTED, SCORESHEET_STATES.EDIT_REQUESTED, SCORESHEET_STATES.APPROVED].includes(state.status)

    console.log('should i disablethe button', shouldDisableSubmitButton)
    return (
        <Grid2>
            <Typography variant='h3'>Event Scoresheet</Typography>
            <ScoresheetStateButtons
                {...{
                    state,
                    lockScoresheet,
                    unlockScoresheet,
                    approveScoresheet,
                    submitScoresheetForReview,
                    requestEdit
                }}
            />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={state.tabNumber} onChange={(_, newValue) => {
                    dispatch({
                        type: 'change_tab',
                        to: newValue,
                    })

                }} aria-label="basic tabs example">
                    <Tab label="Scoresheet Builder" {...a11yProps(0)} />
                    <Tab label="Scoresheet Viewer" {...a11yProps(1)} />
                </Tabs>
            </Box>

            <TabPanel value={state.tabNumber} index={0}>
                <PenaltyList {...{ state, dispatch, forEvent: true }} />
            </TabPanel>
            <TabPanel value={state.tabNumber} index={1}>
                <PenaltyForm
                    penaltyList={combinedPenalties}
                    readonly={true}
                />
            </TabPanel>
            <Grid2>
                <Button disabled={shouldDisableSubmitButton || state.penalties.length == 0} onClick={(e) => {
                    e.preventDefault()
                    submitButtonPressed()
                }}>{state.status !== '' ? 'Edit' : 'Add'} ScoreSheet</Button>
                {
                    shouldDisableSubmitButton &&
                    <Grid2>
                        <Typography variant='body'>An administrator must be contacted to enable changes</Typography>
                    </Grid2>
                }
            </Grid2>
        </Grid2>
    )

}

// TODO Will handle both editing and insertting of new data
// TODO need to have a new component for the event form
function ScoresheetForm(props) {
    const [state, dispatch] = useReducer(scoresheetFormReducer, props, scoresheetFormInitialState)
    const { defaultSelectedCompetition, user } = useContext(UserDataContext)
    const { scoresheetsMap: commonScoresheetsMap } = useContext(CommonDataContext)
    const { firebase } = useContext(FirebaseNewContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)

    // Load in the scoresheet if we have it
    useEffect(() => {
        console.log('we are calling this ONCE')
        if (selectedCompetition &&
            commonScoresheetsMap &&
            commonScoresheetsMap[selectedCompetition]) {
            dispatch({
                type: 'set_initial_values',
                values: commonScoresheetsMap[selectedCompetition]['global'] || {}
            })
        }

    }, [])

    const createScoresheet = (competition, penalties, status) => {
        const validCheck = isValidScoresheet(state.penalties)
        if (!validCheck.result) {
            dispatch({
                type: 'update_error_messages',
                value: validCheck.errorMessages
            })
            return false
        }
        firebase.insertGlobalScoresheet({ status, penalties }, competition)
    }

    const editScoresheet = (competition, penalties, status) => {
        const validCheck = isValidScoresheet(state.penalties)
        if (!validCheck.result) {
            dispatch({
                type: 'update_error_messages',
                value: validCheck.errorMessages
            })
            return false
        }
        firebase.editGlobalScoresheet({ status, penalties }, competition)

    }
    const lockScoresheet = useCallback(() => {
        editScoresheet(selectedCompetition, state.penalties, SCORESHEET_STATES.LOCKED)
    }, [state.penalties])
    const unlockScoresheet = useCallback(() => {
        editScoresheet(selectedCompetition, state.penalties, SCORESHEET_STATES.CREATED)

    }, [state.penalties])
    const submitButtonPressed = useCallback(() => {
        if (props.editScoresheet) {
            editScoresheet(selectedCompetition, state.penalties, SCORESHEET_STATES.CREATED)
        } else {
            createScoresheet(selectedCompetition, state.penalties, SCORESHEET_STATES.CREATED)
        }
    }, [state.penalties, selectedCompetition])

    const shouldDisableSubmitButton = state.penalties.length === 0 || [SCORESHEET_STATES.SUBMITTED, SCORESHEET_STATES.EDIT_REQUESTED, SCORESHEET_STATES.APPROVED].includes(state.status)
    return (
        <Grid2>
            <Typography variant='h3'>Global Base Scoresheet</Typography>
            <ScoresheetStateButtons
                {...{
                    state,
                    lockScoresheet,
                    unlockScoresheet,
                }}
            />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={state.tabNumber} onChange={(_, newValue) => {
                    dispatch({
                        type: 'change_tab',
                        to: newValue,
                    })

                }} aria-label="basic tabs example">
                    <Tab label="Scoresheet Builder" {...a11yProps(0)} />
                    <Tab label="Scoresheet Viewer" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={state.tabNumber} index={0}>
                {
                    !props.editScoresheet && !props.competition &&
                    (
                        <Grid2>
                            <CompetitionDropdown
                                value={selectedCompetition}
                                onChange={(value) => setSelectedCompetition(value)}
                            />
                        </Grid2>
                    )
                }
                <PenaltyList {...{ state, dispatch }} />
            </TabPanel>
            <TabPanel value={state.tabNumber} index={1}>
                <PenaltyForm
                    penaltyList={state.penalties}
                    readonly={true}
                />
            </TabPanel>
            <Button disabled={state.penalties.length === 0} onClick={(e) => {
                e.preventDefault()
                submitButtonPressed()
            }}>{state.status !== '' ? 'Edit' : 'Add'} ScoreSheet</Button>
        </Grid2>

    )
}

export {
    ScoresheetForm,
    EventScoresheetForm
}
