import React, { useState, useContext, useMemo } from 'react';
import * as ROUTES from '../routes/routes';
import { UserDataContext } from '../firebase/UserDataContext';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';
import { Button, Chip, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ScoresheetForm } from '../components/forms/ScoresheetForm';
import { CommonDataContext } from '../firebase/CommonDataContext';
import { DataGrid } from '@mui/x-data-grid';
import { determineScoresheetTableChipColor } from '../components/misc/Utils';
import { eventScoresheetColumns } from './JudgeScoresheetPage';
const globalScoresheetColumns = [
    { field: 'col1', headerName: 'Name', width: 200 },
    { field: 'col2', headerName: 'Description', width: 500 },
    {
        field: 'col3', headerName: 'Status', width: 200,
        renderCell: (params) => {
            let variant = determineScoresheetTableChipColor(params.value);
            let variantProps = variant === '' ? {} : { color: variant }
            return (<Chip label={params.value ? params.value : 'Not Yet Created'} {...variantProps} />)
        }
    },
    // TODO - make the actions do what you need them to
    // VIEW | LOCK | APPROVE | ALLOW_EDIT
    {
        field: 'col4', headerName: 'Actions', width: 250,
        renderCell: (params) => {
            const { status, competition, eventInfo } = params.value
            let toNavigate = ROUTES.BASE_SCORESHEET_PAGE_NEW
            if (status !== '') {
                toNavigate = ROUTES.routeReplace(ROUTES.BASE_SCORESHEET_PAGE_EDIT, {
                    competition: competition
                })
            }
            return (
                <Button
                    component={Link}
                    to={toNavigate}>
                    {status === '' ? 'Create' : 'Edit'}
                </Button>
            )
        }
    },
]

function BaseScoresheetPage(props) {
    const location = useLocation();
    const params = useParams()

    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const { scoresheetsMap: commonScoresheetsMap, eventsMap: commonEventsMap } = useContext(CommonDataContext)
    const globalScoresheetsMap = useMemo(() => {
        if (!commonScoresheetsMap || !commonScoresheetsMap[selectedCompetition]) {
            return {}
        }
        return commonScoresheetsMap[selectedCompetition]['global'] || {}
    }, [selectedCompetition, commonScoresheetsMap])
    const eventsScoresheetsMap = useMemo(() => {
        if (!commonScoresheetsMap || !commonScoresheetsMap[selectedCompetition]) {
            return {}
        }
        return commonScoresheetsMap[selectedCompetition]['events'] || {}
    }, [selectedCompetition, commonScoresheetsMap])
    const eventsMap = useMemo(() => {
        if (!commonEventsMap) {
            return {}
        }
        return commonEventsMap[selectedCompetition] || {}
    }, [selectedCompetition, commonEventsMap])

    const globalScoresheetRows = () => {
        const status = globalScoresheetsMap.status || ''
        return [{
            id: 1,
            col1: 'Base Scoresheet',
            col2: 'Base Scoresheet to be used with all other event scoresheets',
            col3: status,
            col4: { status, competition: selectedCompetition, eventInfo: null }
        }]

    }
    const eventsScoresheetRows = () => {
        return Object.keys(eventsMap).map((e, _) => {
            const event = eventsMap[e]
            const scoresheet = eventsScoresheetsMap[e] || {}
            console.log('thsi is the event', event)
            return {
                id: e,
                col1: event['number'],
                col2: event['fullTitle'],
                col3: { status: scoresheet.status || 'Not Yet Created', eventInfo: event },
                col4: { status: scoresheet.status, competition: selectedCompetition, eventUid: e, eventInfo: event }

            }
        })
    }

    return (
        <Grid2>
            {
                location.pathname === ROUTES.BASE_SCORESHEET_PAGE &&
                (
                    <Grid2>
                        <Typography variant='h3'>Base Scoresheet Page</Typography>
                        <Grid2 container justifyContent={'space-between'} alignItems={'center'}>
                            <CompetitionDropdown
                                value={selectedCompetition}
                                onChange={setSelectedCompetition}
                            />
                            {
                                Object.keys(globalScoresheetsMap).length == 0 &&
                                <Button sx={{ maxHeight: '50px' }} component={Link} variant={'outlined'} to={ROUTES.BASE_SCORESHEET_PAGE_NEW}>Create A New Base Scoresheet</Button>
                            }
                        </Grid2>
                        <Grid2>
                            {
                                globalScoresheetsMap && (() => {
                                    return (<DataGrid
                                        sx={{ marginBottom: '25px' }}
                                        rows={globalScoresheetRows()}
                                        columns={globalScoresheetColumns}
                                    />
                                    )
                                })()
                            }
                        </Grid2>
                        <Grid2>
                            <DataGrid
                                sx={{ marginBottom: '25px' }}
                                rows={eventsScoresheetRows()}
                                columns={eventScoresheetColumns}
                            />
                        </Grid2>
                    </Grid2>
                )
            }
            {
                location.pathname.includes(ROUTES.BASE_SCORESHEET_PAGE_NEW) &&
                (
                    <ScoresheetForm />
                )

            }
            {
                location.pathname.includes('edit') &&
                location.pathname.includes('base-scoresheet') &&
                (
                    <ScoresheetForm competition={params.competition} />
                )
            }
        </Grid2 >
    )

}



export default BaseScoresheetPage