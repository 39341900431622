import { Button, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import CompetitionDropdown from './Dropdowns/CompetitionDropdown';
import DivisionList from './Lists/DivisionList';
import FormErrorMessages from './Messages/FormErrorMessages';
// NOTE - component will not be a hot reload of data
//  component will exist as a static to be reloaded

// Will handle both editing and insertting of new data
function DivisionForm(props) {
    const { globalSettings: { divisions } } = useContext(GlobalSettingsContext);
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const { firebase } = useContext(FirebaseNewContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const [division, setDivision] = useState('')
    const divisionMap = useMemo(() => {
        return divisions[selectedCompetition] || {}
    }, [selectedCompetition])
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        const fetch = async () => {
            if (props.division) {
                setDivision(divisionMap[props.division])
            }
        }
        fetch()
            .catch(console.error)

    }, [])

    const isValid = (division, divisionMap) => {
        let validCheck = { result: true, errorMessages: [] };
        if (division === '') {
            validCheck = {
                result: false,
                errorMessages: ['division cannot be empty']
            }
            return validCheck

        }

        const normalizedDivisionMap = Object.keys(divisionMap).map((v) => divisionMap[v]).map((v) => {
            return String(v).toUpperCase().trim()
        })
        const isIn = normalizedDivisionMap.includes(String(division).toUpperCase().trim())
        if (isIn) {
            validCheck = {
                result: false,
                errorMessages: ['division already exists']
            }
        }

        return validCheck
    }

    const insertNewDivision = async () => {
        const isValidCheck = isValid(division, divisionMap)
        setErrorMessages(isValidCheck.errorMessages)
        if (isValidCheck.result) {
            await firebase.insertDivision(division, selectedCompetition)
            //window.location.reload(false)
        }
    }

    const patchExistingDivision = async () => {
        const excludedMap = Object.keys(divisionMap).reduce((acc, v) => {
            if (props.division === v) {
                return acc
            }
            acc[v] = divisionMap[v]
            return acc
        }, {})

        const isValidCheck = isValid(division, excludedMap)
        if (!isValidCheck.result) {
            setErrorMessages(isValidCheck.errorMessages)
        } else {
            await firebase.patchDivision(`${props.competition}/${props.division}`, division)
            window.location.reload(false)
        }
    }

    const submitButtonPressed = useCallback((e) => {
        e.preventDefault();
        if (props.division) {
            patchExistingDivision()
        } else {
            insertNewDivision()
        }
    }, [division, divisionMap])

    return (
        <Grid2>
            <Typography variant='h5'>{props.division ? 'Edit' : 'Create'} a Division</Typography>
            <Grid2>
                {
                    !props.division && !props.competition &&
                    (
                        <Grid2>
                            <DivisionList
                                competition={selectedCompetition}
                            />
                            <CompetitionDropdown
                                value={selectedCompetition}
                                onChange={(value) => setSelectedCompetition(value)}
                            />
                        </Grid2>

                    )
                }
                <TextField
                    name="division"
                    label="Division Name"
                    value={division}
                    onChange={(e) => {
                        setDivision(e.target.value)
                    }}
                />
            </Grid2>
            <Grid2>
                <FormErrorMessages
                    errorMessages={errorMessages}
                />
                <Button onClick={submitButtonPressed}>{props.division && props.competition ? 'Edit' : 'Create'}</Button>
            </Grid2>
        </Grid2>

    )
}

export default DivisionForm