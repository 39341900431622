import React, { Component, useReducer, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import { DataGrid, GridRowsProp, GridColDef, GridToolbar } from '@mui/x-data-grid';
import * as ROUTES from '../../routes/routes';
import { Button, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { CheckBox, Clear } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { avgByCategoryInEvent, avgByDivisionInEvent, avgOverallInEvent, getCategoryTopOverallInEvent, getEventCategoryPlaceInEvent, getFinalScoresByTeam, getOverallTopInEvent, getPlacementOverallInEvent, groupedEventsScores, getPlacementOverAllEventsAndCategories } from '../../components/misc/Utils';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';


// TODO - this should be the same as the eventActionColumn
//  keeping it separated though for 
const teamActionColumn = (params) => {
    if (!params.value.entered) {
        return (
            <Typography variant='body1'>Not Entered Yet</Typography>
        )
    }
    let navigation = {
        competition: params.value.competition,
        scoreUid: params.value.entry.scoreUid
    }
    // TODO - we need to path this over to the scoring router
    return (
        <Button component={Link} to={ROUTES.routeReplace(ROUTES.SCORING_VIEW, navigation)}>View Score</Button>
    )

}

const teamColumns = [
    { field: 'col1', headerName: 'Event Number ', width: 150 },
    { field: 'col2', headerName: 'Event Name', width: 300, },
    { field: 'col3', headerName: 'Final Score', width: 150, getApplyQuickFilterFn: null },
    { field: 'col4', headerName: 'Category Avg', width: 125, getApplyQuickFilterFn: null },
    { field: 'col5', headerName: 'Overall Avg', width: 125, getApplyQuickFilterFn: null },
    { field: 'col6', headerName: 'Category Top', width: 125, getApplyQuickFilterFn: null },
    { field: 'col7', headerName: 'Overall Top', width: 125, getApplyQuickFilterFn: null },
    { field: 'col8', headerName: 'Category Place', width: 125, getApplyQuickFilterFn: null },
    { field: 'col9', headerName: 'Overall Place', width: 125, getApplyQuickFilterFn: null },
    {
        field: 'col10', headerName: 'Status', width: 150,
        renderCell: teamActionColumn
    }
]
const TeamDashboardNew = (props) => {
    const { firebase } = useContext(FirebaseNewContext)
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { defaultSelectedCompetition, user } = useContext(UserDataContext)
    const { eventsMap: globalEventsMap, scoresMap: globalScoresMap, teamsMap: globalTeamsMap } = useContext(CommonDataContext)
    const eventsMap = useMemo(() => {
        return globalEventsMap[defaultSelectedCompetition] || {}
    }, [defaultSelectedCompetition, globalEventsMap])
    const [eventsScores, setEventsScores] = useState({})
    const [teamDetails, setTeamsDetails] = useState({})
    const teamsMap = useMemo(() => {
        return globalTeamsMap[defaultSelectedCompetition] || {}
    }, [])
    const categoriesMap = useMemo(() => {
        if (Object.keys(globalSettings).length != 0) {
            return globalSettings['categories'][defaultSelectedCompetition]
        }
        return {}
    }, [globalSettings])
    const divisionsMap = useMemo(() => {
        if (Object.keys(globalSettings).length != 0) {
            return globalSettings['divisions'][defaultSelectedCompetition]
        }
        return {}
    }, [globalSettings])
    const showFinalScoresToTeams = useMemo(() => {
        if (Object.keys(globalSettings).length != 0) {
            return globalSettings['showFinalScoresToTeams'][defaultSelectedCompetition]
        }
        return false

    }, [globalSettings])
    useEffect(() => {
        const fetch = async () => {
            let scoresMapResult = await firebase.getScores(defaultSelectedCompetition)
            scoresMapResult = Object.keys(scoresMapResult).reduce((acc, v) => {
                acc[v] = { ...scoresMapResult[v], scoreUid: v }
                return acc
            }, {})
            // TEAM
            getFinalScoresByTeam(scoresMapResult, teamsMap, eventsMap)
            // EVENT
            const eventScoresMap = groupedEventsScores(scoresMapResult, eventsMap)
            avgByCategoryInEvent(eventScoresMap, categoriesMap)
            avgByDivisionInEvent(eventScoresMap, divisionsMap)
            avgOverallInEvent(eventScoresMap)
            getOverallTopInEvent(eventScoresMap)
            getCategoryTopOverallInEvent(eventScoresMap, categoriesMap)
            getEventCategoryPlaceInEvent(eventScoresMap, categoriesMap)
            getPlacementOverallInEvent(eventScoresMap)

            // TEAM
            getPlacementOverAllEventsAndCategories(eventScoresMap, teamsMap, categoriesMap)

            const toSaveTeams = user['teamsNew'][defaultSelectedCompetition].reduce((acc, v) => {
                acc[v] = teamsMap[v]
                return acc
            }, {})
            Object.keys(eventScoresMap).reduce((accEvent, e) => {
                const event = eventScoresMap[e]
                accEvent[e] = event
                accEvent[e]['entered'] = {}
                accEvent[e]['targetScore'] = {}
                const scores = event.scores

                user['teamsNew'][defaultSelectedCompetition].forEach((teamUid) => {
                    const item = scores.find(x => x.teamUid == teamUid)
                    if (item) {
                        accEvent[e]['entered'][teamUid] = true
                        accEvent[e]['targetScore'][teamUid] = item
                    }
                })

                return accEvent
            }, {})
            Object.keys(eventsMap).reduce((acc, e) => {
                if (!(e in eventScoresMap)) {
                    eventScoresMap[e] = {event: eventsMap[e], entered: {}, targetScore: {}}
                }
                return acc
            }, {})
            setEventsScores(eventScoresMap)
            setTeamsDetails(toSaveTeams)
        }
        fetch().catch(console.error)

    }, [])
    console.log(user)

    return (
        <Grid2 container sx={{ flexDirection: 'column' }}>
            <Typography variant='h5'>Team Dashboard</Typography>
            <Grid2 container sx={{ flexDirection: 'column', flex: 1, m: 5 }}>
                {
                    Object.keys(teamDetails).map((t) => {
                        const team = teamDetails[t]
                        const rows = Object.keys(eventsScores).map((e, k) => {
                            const event = eventsScores[e]
                            const isEntered = t in event.entered
                            return {
                                id: `${k}-${team.teamNumber}-${event.event.number}`,
                                col1: event.event.number,
                                col2: `${event.event.name} - ${event.event.company}`,
                                col3: isEntered ? event.targetScore[t]['finalScore'] : '-',
                                col4: isEntered && showFinalScoresToTeams ? event.categoriesAvg[event.targetScore[t].team.category] : '-',
                                col5: isEntered && showFinalScoresToTeams ? event.avgOverall : '-',
                                col6: isEntered && showFinalScoresToTeams ? event.categoryTop[event.targetScore[t].team.category] : '-',
                                col7: isEntered && showFinalScoresToTeams ? event.overallTopInEvent : '-',
                                col8: isEntered && showFinalScoresToTeams ? event.targetScore[t].categoryPlace : '-',
                                col9: isEntered && showFinalScoresToTeams ? event.targetScore[t].overallPlace : '-',
                                col10: { entry: event.targetScore[t], entered: event.entered[t], competition: defaultSelectedCompetition, event: { ...event, ...{ eventUid: e } }, team: { ...team, ...{ teamUid: t } } }
                            }

                        })
                        return (
                            <Grid2 container key={team.teamNumber} style={{ flexDirection: 'column' }}>
                                <Grid2>
                                    <Grid2 sx={{ textAlign: 'left' }}>
                                        <Typography>Team Number: {team.teamNumber}</Typography>
                                        <Typography>Team Name: {team.teamName}</Typography>
                                        <Typography>Category: {categoriesMap[team.category]}</Typography>
                                        <Typography>Division: {divisionsMap[team.division]}</Typography>
                                    </Grid2>
                                    {
                                        showFinalScoresToTeams ?
                                            <>
                                                <Typography>Final Score: {team.finalScore}</Typography>
                                                <Typography>Category Place: {team.categoryPlace}</Typography>
                                                <Typography>Overall Place: {team.place}</Typography>
                                            </>
                                            :
                                            false

                                    }
                                </Grid2>
                                <Grid2 container sx={{ height: 500, width: '100%', }}>
                                    <DataGrid
                                        initialState={{
                                            sorting: {
                                                sortModel: [{field: 'col1', sort: 'asc'}]
                                            }
                                        }}
                                        key={team.number}
                                        rows={rows}
                                        columns={teamColumns}
                                        slots={{ toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                    />
                                </Grid2>
                            </Grid2>
                        )
                    })
                }
            </Grid2>
        </Grid2>
    )
}





export default TeamDashboardNew
