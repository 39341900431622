import { List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { GlobalSettingsContext } from '../../../firebase/GlobalSettingsContext';

const CategoryList = (props) => {
    //https://flaviocopes.com/how-to-rename-object-destructuring/
    const { globalSettings: { categories } } = useContext(GlobalSettingsContext)
    const categoryMap = useMemo(() => {
        return categories[props.competition] || {}
    }, [categories, props.competition])
    return (
        <>
            {
                Object.keys(categoryMap).length === 0 &&
                (
                    <Typography variant='h5'>No Categories Have Been Created</Typography>
                )
            }
            {
                Object.keys(categoryMap).length > 0 &&
                (
                    <>
                        <Typography variant='h6'>Currently Created Categories{Object.keys(categoryMap).length === 1 ? '' : 's'}</Typography>
                        <List>
                            {Object.keys(categoryMap).map((v) => categoryMap[v]).map((c, k) => {
                                return (
                                    <ListItem key={`${k}-${c}`}
                                        sx={{ backgroundColor: k % 2 == 0 ? '#eee' : '' }}>
                                        <ListItemText
                                            primary={c}
                                        />

                                    </ListItem>
                                )

                            })}

                        </List>
                    </>
                )
            }
        </>
    )
}

export default CategoryList