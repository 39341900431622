import React, { Component } from 'react';
import banner from './snapon_banner.png';
import generalGraphic from '../../images/general-graphic.jpg'

export default class App extends Component {
    render() {
        return (
            <div className="landing">
                <h1>Scoring Application</h1>
                <img className="landing-banner" src={generalGraphic} alt="amc banner" />
            </div>
        );
    }
}