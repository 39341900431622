// TODO - for the generic routes we sould palce them into a generic router
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LANDING = '/';
export const DASHBOARD = '/dashboard';
export const ADMIN_SCORING_DASHBOARD = '/dashboard/admin-scoring'
export const ADMIN_SCORING_DASHBOARD_V2 = '/dashboard/admin-scoring-v2'
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/forgot-password';

// Audit Routes
export const HISTORY = '/audit/history';



// Scoring Routes
export const SCORING = '/scoring'
export const SCORING_FORM = '/scoring/new';
export const SCORING_FOR_TEAM = '/scoring/new/for-team/:teamUid';
export const SCORING_FOR_EVENT = '/scoring/new/for-event/:eventUid';
export const SCORING_FOR_EVENT_FOR_TEAM = '/scoring/new/for-event/:eventUid/for-team/:teamUid';
export const SCORING_EDIT = '/scoring/edit/:editScore/for/:competition'
export const SCORING_VIEW = '/scoring/view/:scoreUid/for/:competition'

export const RESULTS_PAGE = '/results-page';

// Base Route
export const ADMIN_ACTIONS_PAGE = '/admin-actions';

// Global Settings
export const GLOBAL_SETTINGS_PAGE = '/admin-actions/global-settings';

// Liability Form Upload
export const LIABILITY_PAGE = '/admin-actions/liability-page';

// Penalty Report
export const PENALTIES_REPORT = '/admin-actions/penalties-report';

// Scoresheet Routes
export const BASE_SCORESHEET_PAGE = '/admin-actions/base-scoresheet';
export const BASE_SCORESHEET_PAGE_NEW = '/admin-actions/base-scoresheet/new';
export const BASE_SCORESHEET_PAGE_EDIT = '/admin-actions/base-scoresheet/edit/for/:competition';

// Category Routes
export const CATEGORY_PAGE = '/admin-actions/category';
export const CATEGORY_PAGE_NEW = '/admin-actions/category/new';
export const CATEGORY_PAGE_EDIT = '/admin-actions/category/edit/:edit/for/:competition';


// Competition Routes
export const COMPETITION_PAGE = '/admin-actions/competition';
export const COMPETITION_PAGE_NEW = '/admin-actions/competition/new';
export const COMPETITION_PAGE_EDIT = '/admin-actions/competition/edit/:edit';

// Event Routes
export const EVENT_PAGE = '/admin-actions/event';
export const EVENT_PAGE_NEW = '/admin-actions/event/new';
export const EVENT_PAGE_EDIT = '/admin-actions/event/edit/:edit/for/:competition';

// Division Routes
export const DIVISION_PAGE = '/admin-actions/division';
export const DIVISION_PAGE_NEW = '/admin-actions/division/new';
export const DIVISION_PAGE_EDIT = '/admin-actions/division/edit/:edit/for/:competition';

// User Routes
export const USER_PAGE = '/admin-actions/user';
export const USER_PAGE_NEW = '/admin-actions/user/new';
export const USER_PAGE_EDIT = '/admin-actions/user/edit/:edit';

// Team Routes
export const TEAM_PAGE = '/admin-actions/team';
export const TEAM_PAGE_NEW = '/admin-actions/team/new';
export const TEAM_PAGE_EDIT = '/admin-actions/team/edit/:edit/for/:competition';

// Judge Actions Routes
export const JUDGE_ACTIONS_ROUTER = '/judge-actions';
export const EVENT_SCORESHEET_PAGE = '/judge-actions/event-scoresheet';
export const EVENT_SCORESHEET_PAGE_NEW = '/judge-actions/event-scoresheet/new/event/:eventUid/for/:competition'
export const EVENT_SCORESHEET_PAGE_EDIT = '/judge-actions/event-scoresheet/edit/event/:eventUid/for/:competition'


// Team Actions Routes
export const TEAM_ACTIONS_ROUTER = '/team-actions';
export const INVITE_TEAMMATE_PAGE = '/team-actions/invite-teammate';

// Extra Routes
export const SLIDE_SHOW_TIMER_PAGE = '/levers/slide-show-timer-page';


export const routeReplace = (route, values) => {
    if (Object.keys(values).length === 0) {
        return route;
    }

    const parts = route.split('/');
    const filled = parts.map((v) => {
        if (v.includes(':')) {
            const needle = v.split(':')[1];
            if (needle in values) {
                return values[needle];
            }
        }

        return v;
    });

    return filled.join('/');
}