import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { CommonDataContext } from '../../firebase/CommonDataContext';

const LiabilityFormText = (props) => {
    const {
        specificDescription,
        liabilityFormLink
    } = props

    return (
        <>
            <Typography variant='body'><b>Terms and Conditions of Participation.</b></Typography>
            <br />
            <br />
            <Typography variant='body'>This Competitor Release of Liability Agreement ("Agreement") is a legal agreement between you (the "Competitor") and the Aerospace Maintenance Council ("Organizer") regarding your participation in the Aerospace Maintenance Competition taking place April 9-11, 2024 in Chicago ("Event"). By clicking “I Agree,” below, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this Agreement. Please read the following terms carefully. A print version of the form is available <a target="_BLANK" href={liabilityFormLink}>here.</a></Typography>
            <br />
            <br />
            <Typography variant='body'><b>Participation in the Event.</b>By agreeing to this Agreement, you acknowledge that participating in the Event involves certain risks and dangers, including the risk of personal injury, death, or property damage. You voluntarily assume all risks associated with your participation in the Event.</Typography>
            <br />
            <br />
            <Typography variant='body'><b>Release of Liability.</b>In consideration of being allowed to participate in the Event, you hereby release, waive, discharge, and covenant not to sue the Organizer, Informa Markets, the McCormick Place Convention Center, the event sponsors, and all those organizations’ affiliates, and their respective officers, directors, employees, agents, and representatives from any and all liability, claims, demands, actions, and causes of action whatsoever, arising out of or related to any loss, damage, or injury, including death, that may be sustained by you during or as a result of your participation in the Event.</Typography>
            <br />
            <br />
            <Typography variant='body'><b>Assumption of Risk.</b> You acknowledge and understand that participating in the Event may expose you to risks and dangers, both known and unknown. You acknowledge that such risks cannot be eliminated entirely and that you have chosen to participate in the Event voluntarily despite the risks.</Typography>
            <br />
            <br />
            <Typography variant='body'><b>Indemnification.</b> You agree to indemnify and hold the Organizer harmless from any and all claims, actions, suits, procedures, costs, expenses, damages, and liabilities, including attorney's fees, arising out of or in connection with your participation in the Event.</Typography>
            <br />
            <br />
            <Typography variant='body'><b>Governing Law.</b> This Agreement shall be governed by and construed in accordance with the laws of Oklahoma.</Typography>
            <br />
            <br />
            <Typography variant='body'><b>Insurance.</b> You understand that each participant in the Event is mandated to have an active and enforceable hospital and medical insurance policy covering himself or herself during the duration of the Event and hereby certify that you meet that requirement prior to participation. You also certify that you are not presently under any physician’s care for any condition, whether disclosed or undisclosed, that would preclude your ability to actively participate in the Event.</Typography>
            <br />
            <br />
            <Typography variant='body'>By clicking "I Agree," you acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this Agreement, acknowledge the Organizer <a href="https://www.aerospacecompetition.com/privacy-policy.html" target='_blank'>Privacy</a> and <a href='https://www.aerospacecompetition.com/media-policy.html' target='_blank'>Media</a> policies, and that you waive any right to bring a legal action or assert a claim against the Organizer for any reason whatsoever.</Typography>
            <br />
            <br />
        </>

    )
}

function LiabilityFormDialog() {
    const { defaultSelectedCompetition, user } = useContext(UserDataContext)
    const { liabilityMap: globalLiabilityMap } = useContext(CommonDataContext)
    const { globalSettings: { competitions } } = useContext(GlobalSettingsContext);
    const { firebase } = useContext(FirebaseNewContext)
    const [open, setOpen] = useState(false)
    const [mustConsent, setMustConsent] = useState(false)
    const [confirmSelf, setConfirmSelf] = useState('')
    const [confirmSelfMinor, setConfirmSelfMinor] = useState('')

    const [formLocation, setFormLocation] = useState('')


    useEffect(() => {
        const fetch = async () => {
            // liability form 
            if (!user.loggedIn || !globalLiabilityMap || Object.keys(globalLiabilityMap).length == 0) {
                return
            }
            const signatoriesResult = globalLiabilityMap[defaultSelectedCompetition]?.signatories || {}
            const formResult = await firebase.storageGetLiabilityForm('LiabilityForm.pdf', defaultSelectedCompetition)
            setFormLocation(formResult)
            if (!(user.uid in signatoriesResult) && formResult) {
                setMustConsent(true)
                setOpen(true)
            }
        }
        // Ensure we have an actual user
        if (defaultSelectedCompetition && user) {
            fetch()
                .catch(console.error)
        }

    }, [defaultSelectedCompetition, user, globalLiabilityMap])

    const handleClose = () => {
        setOpen(false)
    }

    const submit = useCallback(() => {
        const didntConfirmSelf = (confirmSelf == "" || confirmSelf == false)
        const didntConfirmSelfMinor =  (confirmSelfMinor == "" || confirmSelfMinor == false)
        if (didntConfirmSelf && didntConfirmSelfMinor) {
            alert('you must check a box to proceed')
            return
        }
        firebase.insertLiabilityForm(defaultSelectedCompetition, confirmSelf, confirmSelfMinor).then(() => {
            setOpen(false)
        })
    }, [confirmSelf, confirmSelfMinor, open, mustConsent, defaultSelectedCompetition])


    if (!mustConsent) {
        return false
    }

    const details = globalLiabilityMap[defaultSelectedCompetition]?.details || ''
    return (
        <Dialog open={open} onClose={handleClose} fullScreen={true}
        disableEscapeKeyDown={true}
        >
            <DialogTitle>Liability Forms</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <LiabilityFormText
                        specificDescription={details}
                        liabilityFormLink={formLocation}
                    />
                </DialogContentText>
                <FormGroup>
                    <FormControlLabel control={
                            <Checkbox
                                value={confirmSelf}
                                onChange={(e) => {setConfirmSelf(e.target.checked)}}
                            />
                        } 
                        label="I Agree"
                    />
                    <FormControlLabel control={
                            <Checkbox
                                value={confirmSelfMinor}
                                onChange={(e) => {setConfirmSelfMinor(e.target.checked)}}
                            />
                        } 
                        label="I am a minor and will bring a guardian-signed copy of the agreement to the Event"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={submit}>Save</Button>
            </DialogActions>
        </Dialog>

    )
}

export default LiabilityFormDialog
