import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import App from './App';
import GlobalSettingsContextProvider from './firebase/GlobalSettingsContext';
import FirebaseNewContextProvider from './firebase/FirebaseNewContext';
import CommonDataContextProvider from './firebase/CommonDataContext';
import UserDataContextProvider from './firebase/UserDataContext';
import { CookiesProvider } from 'react-cookie';


console.log('this is our app')
const root = ReactDOM.createRoot(document.getElementById("root"))
//  This only loads the app once in production mode
//<React.StrictMode>
//</React.StrictMode>
root.render(
  <FirebaseNewContextProvider>
    <GlobalSettingsContextProvider>
      <CommonDataContextProvider>
        <UserDataContextProvider>
          <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <App />
          </CookiesProvider>
        </UserDataContextProvider>
      </CommonDataContextProvider>
    </GlobalSettingsContextProvider>
  </FirebaseNewContextProvider>

)
