import React, { useContext, useState, useMemo } from 'react';
import EventForm from '../components/forms/EventForm';
import * as routes from '../routes/routes';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import { CommonDataContext } from '../firebase/CommonDataContext';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';
import { UserDataContext } from '../firebase/UserDataContext';
import { Button, Stack, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const eventsColumns = [
    { field: 'col1', headerName: 'Event Name', width: 450 },
    { field: 'col2', headerName: 'Event Number', width: 150 },
    { field: 'col3', headerName: 'Judge Count', width: 150 },
    {
        field: 'col4', headerName: 'Is Quiz', width: 150, getApplyQuickFilterFn: null,
        renderCell: (params) => {
            if (params.value.isQuiz) {
                return <CheckIcon sx={{ color: 'green' }} />
            }
            return <CloseIcon sx={{ color: 'red' }} />

        }
    },
    {
        field: 'col5', headerName: 'Actions', width: 150, getApplyQuickFilterFn: null,
        renderCell: (params) => {
            return <Button component={Link} to={routes.routeReplace(routes.EVENT_PAGE_EDIT, { edit: params.value.uid, competition: params.value.selectedCompetition })}>Edit</Button>
        }
    },
]

function EventPage() {
    const location = useLocation()
    const params = useParams()
    const { globalSettings: { competitions } } = useContext(GlobalSettingsContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const { eventsMap: globalEventsMap } = useContext(CommonDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(defaultSelectedCompetition)
    const eventsMap = useMemo(() => {
        return globalEventsMap[selectedCompetition] || {}
    }, [selectedCompetition, globalEventsMap])

    const rows = useMemo(() => {
        return Object.keys(eventsMap).map((v) => {
            const event = eventsMap[v]
            return {
                id: v,
                col1: `${event.name} - ${event.fullTitle}`,
                col2: event.number,
                col3: Object.keys(event.judgesNew || []).length,
                col4: { isQuiz: event.isQuiz },
                col5: { uid: v, selectedCompetition }
            }
        })
    }, [eventsMap])
    // TODO - for the no results make it a custom component you can reuse for a datagrid
    return (
        <Grid2>
            {
                location.pathname === routes.EVENT_PAGE &&
                <>
                    <Grid2 container justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant='h3'>Events</Typography>
                        <Button component={Link} to={routes.EVENT_PAGE_NEW}>Create An Event</Button>
                    </Grid2>
                    <CompetitionDropdown
                        value={selectedCompetition}
                        onChange={setSelectedCompetition}
                    />
                </>
            }
            {
                selectedCompetition === '' &&
                <h3>Please Select A competition</h3>
            }
            {
                location.pathname === routes.EVENT_PAGE &&
                typeof selectedCompetition == 'string' &&
                <Grid2 flexDirection={'column'}>
                    <DataGrid
                        sx={{ marginBottom: '25px' }}
                        autoHeight
                        rows={rows || []}
                        columns={eventsColumns}
                        slots={{
                            toolbar: GridToolbar,
                            noRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    <Typography variant='body'>For the {competitions[selectedCompetition]} competition</Typography>
                                    <Typography variant='body'>No Events Have Been Created</Typography>
                                </Stack>
                            ),
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Grid2>
            }
            {
                Object.keys(params).length !== 0 &&
                <EventForm event={`${params.edit}`} competition={`${params.competition}`} />
            }
            {
                location.pathname.includes(routes.EVENT_PAGE_NEW) &&
                <EventForm />
            }
        </Grid2>
    )

}



export default EventPage;
