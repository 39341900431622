import React, { useContext, useEffect } from 'react';
import ScoreCommitteeDashboardV2 from '../components/dashboard/ScoreCommitteeDashboardV2';

import { UserDataContext } from '../firebase/UserDataContext';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import JudgeDashboardNew from '../components/dashboard/JudgeDashboardNew';
import TeamDashboardNew from '../components/dashboard/TeamDashboardNew';
import { ROLES } from '../components/misc/Utils';
import { useNavigate } from 'react-router-dom';
import * as routes from '../routes/routes';
const DashboardRouter = () => {
    const navigate = useNavigate()
    const { user } = useContext(UserDataContext)
    const { globalSettings } = useContext(GlobalSettingsContext)

    useEffect(() => {
        if (Object.keys(user).length > 0 && !user.loggedIn) {
            navigate(routes.SIGN_IN)
        }
    }, [user, navigate])
    switch (true) {
        case (Object.keys(globalSettings).length == 0 || Object.keys(user).length == 0):
            return <div>Loading</div>
        case [ROLES.ADMIN, ROLES.SCORECOMMITTEE].includes(user.role):
            return <ScoreCommitteeDashboardV2 />
        case ROLES.JUDGE === user.role:
            return <JudgeDashboardNew />
        case ROLES.TEAM === user.role:
            return <TeamDashboardNew />
        default:
            return <div>Unable to route you to where you expected to go. Please contact an Administrator</div>
    }
}
export default DashboardRouter