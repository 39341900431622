import React, { createContext, useContext, useEffect, useState, useMemo } from "react";
import { FirebaseNewContext } from "./FirebaseNewContext";
import { GlobalSettingsContext } from "./GlobalSettingsContext";
import { onAuthStateChanged } from "@firebase/auth";
import { onValue } from "@firebase/database";

export const UserDataContext = createContext('');

const UserDataContextProvider = (props) => {
    const [user, setUser] = useState({})
    const { firebase } = useContext(FirebaseNewContext)
    const { globalSettings } = useContext(GlobalSettingsContext)

    const defaultSelectedCompetition = useMemo(() => {
        return user['defaultCompetition'] || globalSettings['defaultCompetition'] || ''
    }, [user, globalSettings])

    useEffect(() => {
        onAuthStateChanged(firebase.auth, user => {
            if (user) {
                const tempUser = user.uid
                onValue(firebase.listenUser(tempUser), snapshot => {
                    const userResult = snapshot.val()
                    setUser({ ...userResult, ...user, ...{ uid: tempUser, loggedIn: true } })
                })
            } else {
                setUser({ loggedIn: false })
            }

        })
        return () => {
            console.log('user data context is unloading ;)')
        }

    }, [])// eslint-disable-line

    return (
        <UserDataContext.Provider value={{ user, defaultSelectedCompetition }}>
            {props.children}
        </UserDataContext.Provider>
    )
}
export default UserDataContextProvider