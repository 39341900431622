import React, { useReducer, useContext, useEffect, useState, useMemo } from 'react';
import { CommonDataContext } from '../firebase/CommonDataContext';
import { UserDataContext } from '../firebase/UserDataContext';
import { FirebaseNewContext } from '../firebase/FirebaseNewContext';
import { Tab, Tabs, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { EventDropdown } from '../components/forms/Dropdowns/EventDropdown';

const basicPenaltyColumns = [
    { field: 'col1', headerName: 'Penalty Number', width: 150 },
    { field: 'col2', headerName: 'Penalty Description', width: 500 },
    { field: 'col3', headerName: 'Penalty Count', width: 150, getApplyQuickFilterFn: null },
]
const specificPenaltyColumns = [
    { field: 'col1', headerName: 'Penalty Number', width: 150 },
    { field: 'col2', headerName: 'Penalty Description', width: 500 },
    { field: 'col3', headerName: 'Penalty Count', width: 150, getApplyQuickFilterFn: null },
]
const thisYearsPenaltiesColumns = [
    //Team Number	Team Name	Event Number	Event Name	5. Other Penalty (15 seconds)	6. Other Penalty (30 sec)	7. Other Penalty (30 sec)	8. Other Penalty (60 sec)	9. Other Penalty (90 sec)	10. Bonus 1 (30 sec)	11. Bonus 2 (60 sec)
    { field: 'col1', headerName: 'Team Number', width: 150 },
    { field: 'col2', headerName: 'Team Name', width: 150 },
    { field: 'col3', headerName: 'Event Number', width: 150 },
    { field: 'col4', headerName: 'Event Name', width: 150 },
    { field: 'col5', headerName: 'Company Name', width: 150 },
    { field: 'col6', headerName: 'Other 1', width: 150 },
    { field: 'col7', headerName: 'Other Seconds 1', width: 150 },
    { field: 'col8', headerName: 'Other 2', width: 150 },
    { field: 'col9', headerName: 'Other 2 Seconds', width: 150 },
    { field: 'col10', headerName: 'Other 3', width: 150 },
    { field: 'col11', headerName: 'Other Seconds 3', width: 150 },
    { field: 'col12', headerName: 'Other 4', width: 150 },
    { field: 'col13', headerName: 'Other Seconds 4', width: 150 },
    { field: 'col14', headerName: 'Other 5', width: 150 },
    { field: 'col15', headerName: 'Other Seconds 5', width: 150 },
    { field: 'col16', headerName: 'Other 6', width: 150 },
    { field: 'col17', headerName: 'Other Seconds 6', width: 150 },
    { field: 'col18', headerName: 'Other 7', width: 150 },
    { field: 'col19', headerName: 'Other Seconds 7', width: 150 },

]

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid2 sx={{ p: 3 }}>
                    {children}
                </Grid2>
            )}
        </div>
    );
}

function penaltiesReportInitialState(props) {
    return {
        scoresMap: {},
        byEvent: '',
    }

}

function penaltiesReportReducer(state, action) {
    switch (action.type) {
        case 'scores_fetched':
            return { ...state, ...{ scoresMap: action.value } }

        case 'by_event_changed':
            return {
                ...state,
                byEvent: action.value || ''
            }
        default:
            break;
    }
    return state
}

function PenaltiesReportPage(props) {
    const { firebase } = useContext(FirebaseNewContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [state, dispatch] = useReducer(penaltiesReportReducer, props, penaltiesReportInitialState)
    const { eventsMap: globalEventsMap, scoresheetsMap: globalScoresheetsMap, teamsMap: globalTeamsMap } = useContext(CommonDataContext)
    const eventsMap = useMemo(() => {
        return globalEventsMap[defaultSelectedCompetition]
    })
    const teamsMap = useMemo(() => {
        return globalTeamsMap[defaultSelectedCompetition]
    })
    const thisYearsPenaltiesData = useMemo(() => {
        const hasScoresheet = Object.keys(globalScoresheetsMap[defaultSelectedCompetition] || {}).length > 0
        if (hasScoresheet) {
            const hasEvents = hasScoresheet && Object.keys(globalScoresheetsMap[defaultSelectedCompetition]['events'] || {}).length > 0
            if (hasEvents) {
                const competitionScoreSheet = globalScoresheetsMap[defaultSelectedCompetition]['events'] || {}
                const result = Object.keys(teamsMap).reduce((acc, v) => {
                    const team = teamsMap[v]
                    Object.keys(eventsMap).forEach((e) => {
                        const event = eventsMap[e]
                        const temp = {
                            teamNumber: team.teamNumber,
                            teamName: team.teamName,
                            eventNumber: event.number,
                            eventName: event.name,
                            company: event.company,
                            other1: '',
                            other1Seconds: '',
                            other2: '',
                            other2Seconds: '',
                            other3: '',
                            other3Seconds: '',
                            other4: '',
                            other4Seconds: '',
                            other5: '',
                            other5Seconds: '',
                            other6: '',
                            other6Seconds: '',
                            other7: '',
                            other7Seconds: '',
                        }

                        const localPenalty = competitionScoreSheet[e] || {}
                        if (Object.keys(localPenalty).length > 0)  {
                            localPenalty.penalties.forEach((p, index) => {
                                temp[`other${index+1}`] = p.description
                                temp[`other${index+1}Seconds`] = p.value
                            })
                        }
                        acc = acc.concat(temp)
                    })
                    return acc
                }, [])
                return result
            }
        }
        return {}
    }, [defaultSelectedCompetition, globalScoresheetsMap, eventsMap, teamsMap])
    const [value, setValue] = useState(0);
    const handleChange = (_, newValue) => {
        setValue(newValue);
    };
    const penalties = useMemo(() => {
        let basicReturn = {
            penalties: {}, penaltyIdCountOnly: {}
        }
        if (Object.keys(state.scoresMap).length == 0) {
            return basicReturn
        }
        const penaltyMap = Object.keys(state.scoresMap).reduce((acc, scoreKey) => {
            const score = state.scoresMap[scoreKey]
            if ('penalties' in score) {
                Object.keys(score['penalties']).forEach((penaltyKey) => {
                    const penalty = score['penalties'][penaltyKey]
                    if (!(penaltyKey in acc['penalties'])) {
                        acc['penalties'][penaltyKey] = {}
                        acc['penaltyIdCountOnly'][penaltyKey] = { name: penalty.description, count: 0, scores: [] }
                    }
                    if (penalty.custom) {
                        if (!(penalty['customEntry'] in acc['penalties'][penaltyKey])) {
                            acc['penalties'][penaltyKey][penalty['customEntry']] = { count: 0, scores: [] }
                        }
                        acc['penalties'][penaltyKey][penalty['customEntry']]['count'] = acc['penalties'][penaltyKey][penalty['customEntry']]['count'] + 1
                        acc['penalties'][penaltyKey][penalty['customEntry']]['scores'] = acc['penalties'][penaltyKey][penalty['customEntry']]['scores'].concat(score)
                    } else {
                        if (!(penalty['description'] in acc['penalties'][penaltyKey])) {
                            acc['penalties'][penaltyKey][penalty['description']] = { count: 0, scores: [] }
                        }
                        acc['penalties'][penaltyKey][penalty['description']]['count'] = acc['penalties'][penaltyKey][penalty['description']]['count'] + 1
                        acc['penalties'][penaltyKey][penalty['description']]['scores'] = acc['penalties'][penaltyKey][penalty['description']]['scores'].concat(score)
                    }
                    acc['penaltyIdCountOnly'][penaltyKey]['count'] = acc['penaltyIdCountOnly'][penaltyKey]['count'] + 1
                    acc['penaltyIdCountOnly'][penaltyKey]['scores'] = acc['penaltyIdCountOnly'][penaltyKey]['scores'].concat(score)
                })
            }

            return acc
        }, basicReturn)
        return penaltyMap

    }, [state.scoresMap])
    const penaltiesByEventMap = useMemo(() => {
        let basicReturn = { penalties: {}, penaltyIdCountOnly: {} }
        if (Object.keys(state.scoresMap).length == 0 || state.byEvent == '') {
            return basicReturn
        }
        const penaltiesMap = Object.keys(state.scoresMap).reduce((acc, scoreKey) => {
            const score = state.scoresMap[scoreKey]
            if (score.eventUid !== state.byEvent) {
                return acc
            }
            if (!('penalties' in score)) {
                return acc
            }
            // TODO - to recombine with the above and condense the code for cleaner output for
            //  extracting penalties
            Object.keys(score['penalties']).forEach((penaltyKey) => {
                const penalty = score['penalties'][penaltyKey]
                if (!(penaltyKey in acc['penalties'])) {
                    acc['penalties'][penaltyKey] = {}
                    acc['penaltyIdCountOnly'][penaltyKey] = { name: penalty.description, count: 0, scores: [] }
                }
                if (penalty.custom) {
                    if (!(penalty['customEntry'] in acc['penalties'][penaltyKey])) {
                        acc['penalties'][penaltyKey][penalty['customEntry']] = { count: 0, scores: [] }
                    }
                    acc['penalties'][penaltyKey][penalty['customEntry']]['count'] = acc['penalties'][penaltyKey][penalty['customEntry']]['count'] + 1
                    acc['penalties'][penaltyKey][penalty['customEntry']]['scores'] = acc['penalties'][penaltyKey][penalty['customEntry']]['scores'].concat(score)
                } else {
                    if (!(penalty['description'] in acc['penalties'][penaltyKey])) {
                        acc['penalties'][penaltyKey][penalty['description']] = { count: 0, scores: [] }
                    }
                    acc['penalties'][penaltyKey][penalty['description']]['count'] = acc['penalties'][penaltyKey][penalty['description']]['count'] + 1
                    acc['penalties'][penaltyKey][penalty['description']]['scores'] = acc['penalties'][penaltyKey][penalty['description']]['scores'].concat(score)
                }
                acc['penaltyIdCountOnly'][penaltyKey]['count'] = acc['penaltyIdCountOnly'][penaltyKey]['count'] + 1
                acc['penaltyIdCountOnly'][penaltyKey]['scores'] = acc['penaltyIdCountOnly'][penaltyKey]['scores'].concat(score)
            })

            return acc

        }, basicReturn)
        return penaltiesMap
    }, [state.scoresMap, state.byEvent])
    useEffect(() => {
        const fetch = async () => {
            const scoresMapResult = await firebase.getScores(defaultSelectedCompetition)
            dispatch({
                type: 'scores_fetched',
                value: scoresMapResult
            })
        }
        fetch().catch(console.error)

    }, [])

    const byBasicPenaltyRows = (value, index) => {
        if (value !== index) {
            return false
        }
        const rows = Object.keys(penalties.penaltyIdCountOnly).map((penaltyId) => {
            return {
                id: penaltyId,
                col1: penaltyId,
                col2: penalties.penaltyIdCountOnly[penaltyId].name,
                col3: penalties.penaltyIdCountOnly[penaltyId].count
            }
        })
        return (
            <Grid2 style={{ height: '500px', width: '100%', }}>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    sx={{ marginBottom: '25px' }}
                    key={'basicPenaltyTable'}
                    rows={rows}
                    columns={basicPenaltyColumns}
                />
            </Grid2>
        )
    }
    const bySpecificPenaltyDescriptionRows = (value, index) => {
        if (value !== index) {
            return false
        }
        const rows = Object.keys(penalties.penalties).reduce((acc, penaltyId) => {
            Object.keys(penalties.penalties[penaltyId]).forEach((v) => {
                acc = acc.concat({
                    id: `${penaltyId}-${v}`,
                    col1: penaltyId,
                    col2: v,
                    col3: penalties.penalties[penaltyId][v]['count']
                })
            })
            return acc
        }, [])
        return (
            <Grid2 style={{ height: '500px', width: '100%', }}>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    sx={{ marginBottom: '25px' }}
                    key={'specificPenaltyDescriptionRows'}
                    rows={rows}
                    columns={specificPenaltyColumns}
                />
            </Grid2>
        )
    }
    const byEvent = (value, index, eventUid) => {
        if (value !== index) {
            return false
        }
        const rows = Object.keys(penaltiesByEventMap.penalties).reduce((acc, penaltyId) => {
            Object.keys(penaltiesByEventMap.penalties[penaltyId]).forEach((v) => {
                acc = acc.concat({
                    id: `${penaltyId}-${v}`,
                    col1: penaltyId,
                    col2: v,
                    col3: penaltiesByEventMap.penalties[penaltyId][v]['count']
                })
            })
            return acc
        }, [])
        return (
            <Grid2 style={{ height: '500px', width: '100%', }}>
                <EventDropdown
                    eventsMapValues={eventsMap}
                    value={state.byEvent}
                    onChange={(e, selected) => {
                        e.preventDefault()
                        dispatch({
                            type: 'by_event_changed',
                            value: selected.uid
                        })
                    }}
                />
                {!eventUid &&
                    (<Typography>No Event Selected</Typography>)
                }
                {eventUid &&
                    (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            sx={{ marginBottom: '25px' }}
                            key={'specificPenaltyDescriptionRows'}
                            rows={rows}
                            columns={specificPenaltyColumns}
                        />

                    )
                }
            </Grid2>

        )
    }
    const thisYearsPenalties = (value, index) => {
        if (value !== index) {
            return false
        }
        const rows = thisYearsPenaltiesData.map((teamEvent, k) => {
            return {
                id: `${teamEvent['teamNumber']}-${teamEvent['eventNumber']}`,
                col1: teamEvent['teamNumber'],
                col2: teamEvent['teamName'],
                col3: teamEvent['eventNumber'],
                col4: teamEvent['eventName'],
                col5: teamEvent['company'],
                col6: teamEvent['other1'],
                col7: teamEvent['other1Seconds'],
                col8: teamEvent['other2'],
                col9: teamEvent['other2Seconds'],
                col10: teamEvent['other3'],
                col11: teamEvent['other3Seconds'],
                col12: teamEvent['other4'],
                col13: teamEvent['other4Seconds'],
                col14: teamEvent['other5'],
                col15: teamEvent['other5Seconds'],
                col16: teamEvent['other6'],
                col17: teamEvent['other6Seconds'],
                col18: teamEvent['other7'],
                col19: teamEvent['other7Seconds'],
            }
        })
        return (
            <Grid2 style={{ height: '500px', width: '100%', }}>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    sx={{ marginBottom: '25px' }}
                    key={'printablePenaltiesRows'}
                    rows={rows}
                    columns={thisYearsPenaltiesColumns}
                />
            </Grid2>

        )
    }

    return (
        <Grid2>
            <Typography variant='h2'>Penalty Report</Typography>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
            >
                <Tab label="Overall Generic Penalties" {...a11yProps(0)} />
                <Tab label="Specific Penalty" {...a11yProps(1)} />
                <Tab label="By Event" {...a11yProps(2)} />
                <Tab label="This Years Penalties" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                {byBasicPenaltyRows(value, 0)}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {bySpecificPenaltyDescriptionRows(value, 1)}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {byEvent(value, 2, state.byEvent)}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {thisYearsPenalties(value, 3, state.byEvent)}
            </TabPanel>
        </Grid2>
    )

}



export default PenaltiesReportPage;
