import React, { createContext, useContext, useEffect, useState } from "react";
import { FirebaseNewContext } from "./FirebaseNewContext";
import { onValue } from "@firebase/database";

export const CommonDataContext = createContext('');

const CommonDataContextProvider = (props) => {
    const [teamsMap, setTeamsMap] = useState({})
    const [teamUsersMap, setTeamUsersMap] = useState({})
    const [eventsMap, setEventsMap] = useState({})
    const [usersMap, setUsersMap] = useState({})
    const [judgesMap, setJudgesMap] = useState({})
    const [adminUsersMap, setAdminUsersMap] = useState({})
    const [scoresheetsMap, setScoresheetsMap] = useState({})
    const [liabilityMap, setLiabilityMap] = useState({})
    //const [scoresMap, setScoresMap] = useState({})
    const { firebase } = useContext(FirebaseNewContext)

    useEffect(() => {
        const fetch = async () => {
            onValue(firebase.listenTeamsMap(), snapshot => {
                const teamsMapResult = snapshot.val()
                setTeamsMap(teamsMapResult)
            })
            onValue(firebase.listenEventsMap(), snapshot => {
                const eventsMapResult = snapshot.val()
                setEventsMap(eventsMapResult)
            })
            onValue(firebase.listenUsersMap(), snapshot => {
                const usersMapResult = snapshot.val()
                setUsersMap(usersMapResult)
            })
            onValue(firebase.listenJudgesMap(), snapshot => {
                const judgesMapResult = snapshot.val()
                setJudgesMap(judgesMapResult)
            })
            onValue(firebase.listenTeamUsersMap(), snapshot => {
                const teamUsersMapResult = snapshot.val()
                setTeamUsersMap(teamUsersMapResult)
            })
            onValue(firebase.listenScoresheetsMap(), snapshot => {
                const scoresheetsMapResult = snapshot.val()
                setScoresheetsMap(scoresheetsMapResult)
            })
            onValue(firebase.listenLiabilityMap(), snapshot => {
                const liabilityMapResult = snapshot.val()
                setLiabilityMap(liabilityMapResult)
            })
            onValue(firebase.listenAdminUsersMap(), snapshot => {
                const adminUsersMapResult = snapshot.val()
                setAdminUsersMap(adminUsersMapResult)
            })

        }
        fetch()
            .catch(console.error)
        return () => {
            console.log('closing out of context ;)')
        }

    }, [])// eslint-disable-line

    return (
        <CommonDataContext.Provider value={{ teamsMap, usersMap, eventsMap, judgesMap, teamUsersMap, scoresheetsMap, liabilityMap, adminUsersMap }}>
            {props.children}
        </CommonDataContext.Provider>
    )
}
export default CommonDataContextProvider