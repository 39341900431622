import { Button, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useState, useEffect, useCallback } from "react";
const TimeClockAutomatic = (props) => {
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => {
                if (props.onChange) {
                    props.onChange(props.timeInSeconds + 1)
                }
            }, 1000);
        }
        return () => {
            clearInterval(intervalId);
        }
    }, [props.timeInSeconds, isRunning, props.onChange]);

    // Minutes calculation
    const minutes = Math.floor((props.timeInSeconds % 3600) / 60);

    // Seconds calculation
    const seconds = props.timeInSeconds % 60;

    // Method to start and stop timer
    const startAndStop = useCallback(() => {
        setIsRunning(!isRunning);
    });

    // Method to reset timer back to 0
    const reset = () => {
        if (props.onChange) {
            props.onChange(0, 0)
        }
    };
    return (
        <Grid2 className="stopwatch-container">
            <Typography variant="body1" className="stopwatch-time">
                {minutes.toString().padStart(2, "0")}:
                {seconds.toString().padStart(2, "0")}
            </Typography>
            <div className="stopwatch-buttons">
                <Button className="stopwatch-button" onClick={startAndStop}>
                    {isRunning ? "Stop" : "Start"}
                </Button>
                <Button className="stopwatch-button" onClick={reset}>
                    Reset
                </Button>
            </div>
        </Grid2>
    );
};

export default TimeClockAutomatic;