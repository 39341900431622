import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useReducer, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import { numberSuggester } from '../misc/Utils';
import { JudgeCheckbox, MultiSearchSelectJudgeWrapper } from './Checkboxes/JudgeCheckbox';
import CompetitionDropdown from './Dropdowns/CompetitionDropdown';
import EventList from './Lists/EventList';
import FormErrorMessages from './Messages/FormErrorMessages';
import { MultiSearchSelectTeam } from './Dropdowns/TeamDropdown';

function eventFormInitialState(props) {
    // TODO - make this the new way of doing this for all of the 
    //  default states
    let event = new props.firebase.Event({})
    return {
        event,
        errorMessages: []
    }

}
function eventFormReducer(state, action) {
    switch (action.type) {
        case 'event_initial_load':
            return {
                ...state,
                event: action.event
            }
        case 'checkbox_values_changed':
            return {
                ...state,
                event: {
                    ...state.event,
                    judgesNew: action.values

                }

            }
        case 'checkbox_value_changed':
            const value = action.value;
            const isChecked = action.checked;
            if (isChecked) {
                return {
                    ...state,
                    event: {
                        ...state.event,
                        judgesNew: [...state.event.judgesNew, action.value]

                    }
                }
            } else {
                return {
                    ...state,
                    event: {
                        ...state.event,
                        judgesNew: state.event.judgesNew.filter((option) => option !== value)
                    }
                }
            }
        case 'event_value_changed':
            return {
                ...state,
                event: {
                    ...state.event,
                    [action.keyName]: action.keyValue
                }
            }
        case 'quiz_value_changed':
            return {
                ...state,
                event: {
                    ...state.event,
                    [action.keyName]: !state.event[action.keyName]

                },
            }
        case 'event_excluded':
            return {
                ...state,
                event: {
                    ...state.event,
                    'excludeEvent': !state.event['excludeEvent']
                }
            }
        case 'error_message_changed':
            return {
                ...state,
                errorMessages: action.nextErrorMessages

            }
        default:
            break;
    }
}

function EventForm(props) {
    const { firebase } = useContext(FirebaseNewContext)
    const { judgesMap, eventsMap: globalEventsMap, usersMap, teamsMap: globalTeamsMap } = useContext(CommonDataContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [state, dispatch] = useReducer(eventFormReducer, { ...props, firebase }, eventFormInitialState)
    const [eventMembers, setEventMembers] = useState([])
    const [associatedTeams, setAssociatedTeams] = useState([])
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const eventsMap = useMemo(() => {
        return globalEventsMap[selectedCompetition] || {}
    }, [selectedCompetition])
    const teamsMap = useMemo(() => {
        return globalTeamsMap[selectedCompetition] || {}
    }, [selectedCompetition])
    useEffect(() => {
        // NOTE - when creating things you dont care about your user preference default
        const fetch = async () => {
            //https://devtrium.com/posts/async-functions-useeffect
            if (props.event) {
                const event = { ...state.event, ...eventsMap[props.event] }
                const theJudgeList = event.judgesNew ? Object.keys(event.judgesNew).map(v => v) : []
                setEventMembers(theJudgeList)
                setAssociatedTeams(event.associatedTeams || [])
                dispatch({
                    type: 'event_initial_load',
                    event: {
                        ...event,
                        judgesNew: theJudgeList

                    }
                })
            }
        }

        fetch()
            .catch(console.error)

    }, [])

    const isValid = (state) => {
        const {
            number,
            name
        } = state.event;
        let validCheck = { result: true, errorMessages: [] }

        if (number === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Event number must not be empty')
        }
        if (name === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Event name must not be empty')
        }
        return validCheck;
    }
    const insertNewEvent = async () => {
        const validResult = () => {
            let validCheck = isValid(state)
            const isValidEventNumberCheck = isValidEventNumber(state.event.number, eventsMap)
            if (!isValidEventNumberCheck.result) {
                validCheck = isValidEventNumberCheck;
            }
            return validCheck
        }
        let validResultReturn = validResult()
        dispatch({
            type: "error_message_changed",
            nextErrorMessages: validResultReturn.errorMessages
        })
        if (validResultReturn.result) {
            const eventJudgeGrouping = groupEventJudgeMembers(judgesMap, state.event.judgesNew)
            const result = await firebase.insertEvent({ ...state.event, ...{ judgesNew: eventJudgeGrouping } }, selectedCompetition)
            await addJudgeEntry(result.key, selectedCompetition)
            await addTeamEntry(props.event)
            window.location.reload(false)
        }
    }

    const patchExistingEvent = async () => {
        // NOTE - when using patch we can't use selected competition
        const isValidCheck = isValid(state)
        dispatch({
            type: "error_message_changed",
            nextErrorMessages: isValidCheck.errorMessages
        })
        if (isValidCheck.result) {
            const eventJudgeGrouping = groupEventJudgeMembers(judgesMap, state.event.judgesNew)
            await firebase.patchEvent(`${props.competition}/${props.event}`, { ...state.event, ...{ judgesNew: eventJudgeGrouping } })
            // propogate data to events
            await addTeamEntry(props.event)
            await removeTeamEntry(props.event)

            await addJudgeEntry(props.event, props.competition)
            await removeJudgeEntry(props.event, props.competition)
            window.location.reload(false)
        }
    }

    const isValidEventNumber = (eventNumber, eventsMap) => {
        let validCheck = { result: true, errorMessages: [] };
        const eventNumbers = Object.keys(eventsMap).map((v) => eventsMap[v]).map((v) => v.number)
        if (eventNumbers.includes(parseInt(eventNumber))) {
            validCheck.result = false;

            const toSuggest = numberSuggester(eventNumbers)
            validCheck.errorMessages.push(`Event number has already been assigned why not try - ${toSuggest}`)
        }
        return validCheck
    }

    const groupEventJudgeMembers = (judgesMap, eventJudgeMembers) => {
        const items = eventJudgeMembers.reduce((acc, v) => {
            if (!(v in judgesMap)) {
                return acc
            }
            acc[v] = judgesMap[v]
            return acc
        }, {})
        return items
    }
    const addJudgeEntry = async (databaseId, selectedCompetition) => {
        const newUsers = newInJudgeList(eventMembers, state.event.judgesNew)
        const result = await Promise.all(newUsers.map(async (x) => {
            let user = usersMap[x]
            if (!user.eventsNew) {
                user.eventsNew = {}
            }
            let eventsList = (user.eventsNew[selectedCompetition] || []).concat(databaseId)
            user.eventsNew = { ...user.eventsNew, ...{ [selectedCompetition]: eventsList } }
            return firebase.patchUser(x, user)
        }))
        return result

    }
    const removeJudgeEntry = async (databaseId, selectedCompetition) => {
        const removedUsers = outInJudgeList(eventMembers, state.event.judgesNew)
        const result = await Promise.all(removedUsers.map(async (x) => {
            let user = usersMap[x]
            if (!user.eventsNew) {
                user.eventsNew = {}
            }
            let eventsList = (user.eventsNew[selectedCompetition] || []).filter(x => x != databaseId)
            user.eventsNew = { ...user.eventsNew, ...{ [selectedCompetition]: eventsList } }
            return firebase.patchUser(x, user)

        }))
        return result
    }

    const newInJudgeList = (originalJudgeList, aboutToSave) => {
        return aboutToSave.filter(x => !originalJudgeList.includes(x))
    }
    const outInJudgeList = (originalJudgeList, aboutToSave) => {
        return originalJudgeList.filter(x => !aboutToSave.includes(x))
    }
    const addTeamEntry = async (eventUid) => {
        const addedTeams = newEventInTeam(associatedTeams, state.event.associatedTeams)
        const result = await Promise.all(addedTeams.map(async (x) => {
            let team = await firebase.getTeam(x.uid, selectedCompetition)
            if (!team.associatedEvents) {
                team.associatedEvents = []
            }
            team.associatedEvents = team.associatedEvents.concat({ ...(new firebase.Event(state.event)), ...{ uid: eventUid } })
            return firebase.patchTeam(`${selectedCompetition}/${x.uid}`, team)
        }))
        return result
    }
    const removeTeamEntry = async (eventUid) => {
        const removedTeams = outEventInTeam(associatedTeams, state.event.associatedTeams)
        const result = await Promise.all(removedTeams.map(async (x) => {
            let team = await firebase.getTeam(x.uid, selectedCompetition)
            if (!team.associatedEvents) {
                team.associatedEvent = []
            }

            team.associatedEvents = team.associatedEvents.filter(y => y.uid !== eventUid)
            return firebase.patchTeam(`${selectedCompetition}/${x.uid}`, team)
        }))
        return result
    }

    const newEventInTeam = (originalEvents, aboutToSave) => {
        return aboutToSave.filter(x => !originalEvents.some(y => x.uid == y.uid))
    }
    const outEventInTeam = (originalEvents, aboutToSave) => {
        return originalEvents.filter(x => !aboutToSave.some(y => x.uid == y.uid))
    }

    const submitButtonPressed = useCallback((e) => {
        e.preventDefault()
        if (props.event) {
            console.log('time to patch')
            patchExistingEvent()
        } else {
            console.log('time to insert')
            insertNewEvent()
        }
    }, [state, eventsMap, selectedCompetition])

    return (
        <Grid2>
            <Typography variant='h5'>Event Form</Typography>
            {
                !props.event && !props.competition &&
                <Grid2>
                    <CompetitionDropdown
                        value={selectedCompetition}
                        onChange={(value) => {
                            setSelectedCompetition(value)
                        }}
                    />

                    {
                        typeof selectedCompetition !== 'string' &&
                        <h3>Please Select A competition</h3>
                    }
                </Grid2>
            }
            <Grid2 container
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            >
                <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    onChange={e => {
                        dispatch({
                            type: 'event_value_changed',
                            keyValue: e.target.value,
                            keyName: 'number'
                        })

                    }}
                    name="number"
                    placeholder="number"
                    value={state.event.number}
                    label="Event Number"
                />
                <TextField
                    fullWidth
                    onChange={e => {
                        dispatch({
                            type: 'event_value_changed',
                            keyValue: e.target.value,
                            keyName: 'name'
                        })

                    }}
                    name="name"
                    placeholder="name"
                    value={state.event.name}
                    label="Event Name"
                />
            </Grid2>
            <Grid2 container
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
            >
                <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    onChange={e => {
                        dispatch({
                            type: 'event_value_changed',
                            keyValue: e.target.value,
                            keyName: 'company'
                        })

                    }}
                    name="company"
                    placeholder="company"
                    value={state.event.company}
                    label="Company"
                />
                <TextField
                    sx={{ flex: 1 }}
                    fullWidth
                    onChange={e => {
                        dispatch({
                            type: 'event_value_changed',
                            keyValue: e.target.value,
                            keyName: 'fullTitle'
                        })

                    }}
                    name="fullTitle"
                    placeholder="Full Title"
                    value={state.event.fullTitle}
                    label="Full Title"
                />
            </Grid2>
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    checked={state.event.excludeEvent}
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch({
                            type: 'event_excluded'
                        })
                    }}
                />} label="Exclude Event From Scoring?" />
            </FormGroup>
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    onClick={e => {
                        dispatch({
                            type: 'quiz_value_changed',
                            keyValue: e.target.value,
                            keyName: 'isQuiz'
                        })

                    }}
                    checked={state.event.isQuiz}
                />} label="Is Quiz" />
            </FormGroup>
            <Typography variant='h6'>JudgesList</Typography>
            <MultiSearchSelectJudgeWrapper
                values={state.event.judgesNew}
                selectedJudges={state.event.judgesNew}
                judgesMap={judgesMap}
                onChange={(value) => {
                    const values = value.map(v => v.uid)
                    dispatch({
                        type: 'checkbox_values_changed',
                        values: values,
                        forItem: 'events',
                        forCompetition: selectedCompetition
                    })
                }}
            />
            <Typography variant='h6'>Associated Teams</Typography>
            <MultiSearchSelectTeam
                value={state.event.associatedTeams}
                onChange={(value) => {
                    dispatch({
                        type: 'event_value_changed',
                        keyValue: value,
                        keyName: 'associatedTeams'
                    })
                }}
                teamsMapValues={teamsMap}
            />
            <Button type="submit" onClick={submitButtonPressed}>{props.event && props.competition ? 'Save' : 'Create'}</Button>
            <FormErrorMessages
                errorMessages={state.errorMessages}
            />
        </Grid2>

    )

}

export default EventForm