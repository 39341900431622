import { List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { GlobalSettingsContext } from '../../../firebase/GlobalSettingsContext';

const DivisionList = (props) => {
    //https://flaviocopes.com/how-to-rename-object-destructuring/
    const { globalSettings: { divisions } } = useContext(GlobalSettingsContext)
    const divisionMap = useMemo(() => {
        return divisions[props.competition] || {}
    }, [divisions, props.competition])

    return (
        <>
            {
                Object.keys(divisionMap).length === 0 &&
                (
                    <Typography variant='h5'>No Divisions Have Been Created</Typography>
                )
            }
            {
                Object.keys(divisionMap).length > 0 &&
                (
                    <>
                        <Typography variant='h6'>Currently Created Division{Object.keys(divisionMap).length === 1 ? '' : 's'}</Typography>
                        <List>
                            {Object.keys(divisionMap).map((v) => divisionMap[v]).map((d, k) => {
                                return (
                                    <ListItem key={`${k}-${d}`}
                                        sx={{ backgroundColor: k % 2 == 0 ? '#eee' : '' }}>
                                        <ListItemText>{d}</ListItemText>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </>
                )
            }
        </>
    )
}

export default DivisionList