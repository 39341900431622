import React, { useContext, useMemo, useState } from 'react';
import * as routes from '../routes/routes';
import DivisionForm from '../components/forms/DivisionForm';
import { UserDataContext } from '../firebase/UserDataContext';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';
import { Button, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const divisionColumns = [
    { field: 'col1', headerName: 'Division Name', width: 350 },
    {
        field: 'col2', headerName: 'Actions', width: 150, getApplyQuickFilterFn: null,
        renderCell: (params) => {
            return <Button component={Link} to={routes.routeReplace(routes.DIVISION_PAGE_EDIT, { edit: params.value.uid, competition: params.value.selectedCompetition })}>Edit</Button>
        }
    },
]

function DivisionPage(props) {
    const location = useLocation()
    const params = useParams()
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const { globalSettings: { divisions: globalDivisionMap, competitions } } = useContext(GlobalSettingsContext)
    const divisionMap = useMemo(() => {
        return globalDivisionMap[selectedCompetition] || {}
    }, [selectedCompetition, globalDivisionMap])


    const rows = useMemo(() => {
        return Object.keys(divisionMap).map((v) => {
            const division = divisionMap[v]
            return {
                id: v,
                col1: division,
                col2: { uid: v, selectedCompetition }
            }
        })

    }, [divisionMap])

    return (
        <>
            {
                location.pathname === routes.DIVISION_PAGE &&
                <Grid2>
                    <>
                        <Grid2 container justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='h3'>Divisions</Typography>
                            <Button component={Link} to={routes.DIVISION_PAGE_NEW}>Create A Division</Button>
                        </Grid2>
                        <CompetitionDropdown
                            value={selectedCompetition}
                            onChange={setSelectedCompetition}
                        />
                    </>
                    {
                        typeof selectedCompetition !== 'string' &&
                        <h3>Please Select A competition</h3>
                    }
                    {

                        typeof selectedCompetition == 'string' &&
                        <Grid2 flexDirection={'column'}>
                            <DataGrid
                                sx={{ marginBottom: '25px' }}
                                autoHeight
                                rows={rows || []}
                                columns={divisionColumns}
                                slots={{
                                    toolbar: GridToolbar,
                                    noRowsOverlay: () => (
                                        <Stack height="100%" alignItems="center" justifyContent="center">
                                            <Typography variant='body'>For the {competitions[selectedCompetition]} competition</Typography>
                                            <Typography variant='body'>No Divisions Have Been Created</Typography>
                                        </Stack>
                                    ),
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Grid2>
                    }
                </Grid2>
            }


            {
                Object.keys(params).length !== 0 &&
                <DivisionForm {...props} division={params.edit} competition={`${params.competition}`} />
            }
            {
                location.pathname.includes(routes.DIVISION_PAGE_NEW) &&
                <DivisionForm {...props} />

            }
        </>
    )

}



export default DivisionPage;
