import React, { useReducer, useEffect, useCallback, useContext, useMemo } from 'react';
import penalityList from './ScoringFormComponents/PenaltiesNew'
import PenaltyForm from './ScoringFormComponents/PenaltyFormNew';
import JudgeDropdown from './Dropdowns/JudgeDropdown';
import { TeamDropdown } from './Dropdowns/TeamDropdown';
import { EventDropdown } from './Dropdowns/EventDropdown';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import FormErrorMessages from './Messages/FormErrorMessages';
import TimeClockManual from './ScoringFormComponents/TimeClockManual';
import TimeClockAutomatic from './ScoringFormComponents/TimeClockAutomatic';

import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, FormGroup, TextField, Tooltip, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams } from 'react-router';
import { ROLES } from '../misc/Utils';

// NOTE - we presume delegates based on their user id and ability
//  to get to the edit / create or permissions.
function userFormInitialState(values) {
    return {
        score: {
            teamUid: values.teamUid || '',
            eventUid: values.eventUid || '',
            judgeUid: '',
            delegateUid: '',
            team: {},
            event: {},
            judge: {},
            delegate: {},
            penalties: {},
            isQuiz: false,
            quizQuestionsMissed: 0,
            finalScore: 0,
            minutes: 0,
            seconds: 0,
            comments: '',
            time: 0,
            scoreContested: false,
            excludeScore: false,
            reasonForChange: '',
            additionalComments: '',
        },
        newPastScore: {},
        pastScores: {},
        //LEFT OFF -we store edit score for patchin
        // we get past scores in a separate table call and bring them in for showing number of edits
        errorMessages: [],
        submitButtonEnabled: true

    }
}
function userFormReducer(state, action) {
    switch (action.type) {
        case 'disable_submit_button':
            return {
                ...state,
                submitButtonEnabled: false
            }
        case 'enable_submit_button':
            return {
                ...state,
                submitButtonEnabled: true
            }
        case 'value_changed':
            // TODO - if event type is a checkbox then do the switcharoo
            return {
                ...state,
                [action.keyName]: action.keyValue
            }
        case 'minutes_changed':
            const timeFromMinutes = parseInt(state.score.seconds) + (parseInt(action.value) * 60)
            const minutesChangedFinalScore = scoreSheetCalculation(timeFromMinutes, state.score.penalties, state.score.quizQuestionsMissed)
            return {
                ...state,
                score: { ...state.score, ...{ ['minutes']: action.value, time: timeFromMinutes, finalScore: minutesChangedFinalScore } }// eslint-disable-line
            }
        case 'seconds_changed':
            const timeFromSeconds = parseInt(action.value) + (parseInt(state.score.minutes) * 60)
            const secondsChangedFinalScore = scoreSheetCalculation(timeFromSeconds, state.score.penalties, state.score.quizQuestionsMissed)
            return {
                ...state,
                score: { ...state.score, ...{ ['seconds']: action.value, time: timeFromSeconds, finalScore: secondsChangedFinalScore } }// eslint-disable-line
            }
        case 'automatic_time_changed':

            // TODO - to fixu up what the total is for time in seconds and minutes
            const minutes = Math.floor((action.value.seconds % 3600) / 60);
            const seconds = action.value.seconds % 60
            const automaticTimeChangeFinalScore = scoreSheetCalculation(action.value.seconds, state.score.penalties, state.score.quizQuestionsMissed)
            return {
                ...state,
                score: { ...state.score, ...{ ['minutes']: minutes, ['seconds']: seconds, ['time']: action.value.seconds, finalScore: automaticTimeChangeFinalScore } }// eslint-disable-line
            }
        case 'penalty_changed':
            const totalPenalties = { ...state.score.penalties, ...action.value }
            const onlyActivePenalties = Object.keys(totalPenalties).reduce((acc, v) => {
                if (totalPenalties[v]['checked']) {
                    acc[v] = totalPenalties[v]
                }

                return acc
            }, {})
            let penaltyChangedFinalScore = scoreSheetCalculation(state.score.time, onlyActivePenalties, state.score.quizQuestionsMissed)

            return {
                ...state,
                score: {
                    ...state.score,
                    finalScore: penaltyChangedFinalScore,
                    penalties: onlyActivePenalties
                }
            }
        case 'judge_changed':
            return {
                ...state,
                score: { ...state.score, ...{ judge: action.value.judge, judgeUid: action.value.judgeUid } }
            }
        case 'team_changed':
            return {
                ...state,
                score: { ...state.score, ...{ team: action.value.team, teamUid: action.value.teamUid } }
            }
        case 'event_changed':
            // NOTE - you must clear out judges when you change the event form
            return {
                ...state,
                score: { ...state.score, ...{ event: action.value.event, eventUid: action.value.eventUid, judge: {}, judgeUid: '' } }
            }
        case 'delegate_changed':
            return {
                ...state,
                score: { ...state.score, ...{ delegate: action.value.delegate, delegateUid: action.value.delegateUid } }
            }
        case 'values_changed':
            return {
                ...state,
                ...action.values
            }
        case 'error_message_changed':
            return {
                ...state,
                errorMessages: action.nextErrorMessages
            }
        case 'toggle_time_clock_type':
            return {
                ...state,
                useTimeClockAutomatic: !state.useTimeClockAutomatic
            }
        case 'score_contested':
            return {
                ...state,
                score: { ...state.score, ...{ scoreContested: !state.score.scoreContested } }
            }
        case 'score_excluded':
            return {
                ...state,
                score: { ...state.score, ...{ excludeScore: !state.score.excludeScore } }
            }
        case 'quiz_questions_missed_changed':
            const _totalPenalties = { ...state.score.penalties, ...action.value }
            const _onlyActivePenalties = Object.keys(_totalPenalties).reduce((acc, v) => {
                if (_totalPenalties[v]['checked']) {
                    acc[v] = _totalPenalties[v]
                }

                return acc
            }, {})
            let quizFinalScore = scoreSheetCalculation(state.score.time, _onlyActivePenalties, action.value)
            return {
                ...state,
                score: {
                    ...state.score,
                    finalScore: quizFinalScore,
                    quizQuestionsMissed: action.value
                }
            }
        case 'reason_changed':
            return {
                ...state,
                score: { ...state.score, ...{ reasonForChange: action.value } }
            }
        case 'additional_comments_changed':
            return {
                ...state,
                score: { ...state.score, ...{ additionalComments: action.value } }
            }
        default:
            break;
    }
}
function scoreSheetCalculation(time, penalties, quizQuestionsMissed) {

    for (const key in penalties) {
        const penalty = penalties[key];
        let add = parseInt(penalty.value)
        if ('format' in penalty && penalty.format === 'MINUTES') {
            add = add * 60
        }
        const multiplier = penalty.multiplier ? parseInt(penalty.multiplier) : 1
        time += (add * multiplier)
    }

    time += quizQuestionsMissed * 30

    time = Math.max(time, 0)
    return Math.min(time, 1500)
}

function ScoringFormNew(props) {
    const params = useParams()
    const [open, setOpen] = React.useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
    const [state, dispatch] = useReducer(userFormReducer, params, userFormInitialState)
    //const [useTimeClockAutomatic, setUseTimeClockAutomatic] = useState(true)
    const { firebase } = useContext(FirebaseNewContext)
    const { defaultSelectedCompetition, user } = useContext(UserDataContext)
    const selectedCompetition = useMemo(() => {
        return props.competition || defaultSelectedCompetition
    }, [defaultSelectedCompetition, props.competition])
    const { usersMap, teamsMap: globalTeamsMap, judgesMap: globalJudgesMap, eventsMap: globalEventsMap, scoresheetsMap: globalScoresheetsMap } = useContext(CommonDataContext)
    const eventsMap = useMemo(() => {
        const theEvents = globalEventsMap[selectedCompetition] || {}
        if (user.role === ROLES.JUDGE) {
            return user.eventsNew && user.eventsNew[selectedCompetition] ?
                user.eventsNew[selectedCompetition].reduce((acc, v) => {
                    acc[v] = theEvents[v]
                    return acc
                }, {})
                : 
                {}
        }
        return theEvents
    }, [selectedCompetition, globalEventsMap, user])
    const teamsMap = useMemo(() => {
        return globalTeamsMap[selectedCompetition] || {}
    }, [selectedCompetition, globalTeamsMap])
    const combinedPenalties = useMemo(() => {
        //const combinedPenalties = [...(globalScoresheetsMap.penalties || []), ...state.penalties]
        const hasScoresheet = Object.keys(globalScoresheetsMap[selectedCompetition] || {}).length > 0
        let toReturn = []
        if (hasScoresheet) {
            const globalScoresheet = globalScoresheetsMap[selectedCompetition]['global'] || {}
            const eventUid = state.score.eventUid || props.eventUid
            const competitionScoresheet = globalScoresheetsMap[selectedCompetition]

            const eventScoresheet = competitionScoresheet['events'] && competitionScoresheet['events'][eventUid] ?competitionScoresheet['events'][eventUid] : {}

                const globalPenalties = globalScoresheet.penalties || []
                const eventPenalties = eventScoresheet.penalties || []

                toReturn = [...globalPenalties, ...eventPenalties]

                // TODO - do not combine the penalties here
                //<PenaltyForm
                //    onChange={(e, penalty) => {
                //        e.preventDefault()
                //        const result = penaltyChanged(e, penalty)
                //        dispatch({
                //            type: 'penalty_changed',
                //            value: result,
                //        })
                //    }}
                //    penaltiesAccrued={state.score.penalties}
                //    penaltyList={penalityList}
                ///>

        }
        // TODO - to improve later, we shouldnt be calling this every time 
        //  score is updated
        return toReturn
    }, [selectedCompetition, globalScoresheetsMap, state.score])
    const judgesMap = useMemo(() => {
        return globalJudgesMap
    }, [globalJudgesMap])
    // TODO - Potentially wrap in a use callback and then check on the linter
    const eventTeamComboIsValid = async (score) => {// eslint-disable-line
        let validCheck = { result: true, errorMessages: [] }
        if (!score.teamUid || !score.eventUid) {
            return validCheck
        }
        const result = await firebase.getScoresForTeam(score.teamUid, selectedCompetition)
        const filtered = Object.keys(result).reduce((acc, v) => {
            // Since we have already gotten all the scores by the judge
            //  now all we need to sift through is matching the event and
            //  the team to see if we have judge one already
            const currentScore = result[v]
            if (currentScore.eventUid === score.eventUid) {
                acc[v] = currentScore
            }

            return acc
        }, {})
        if (Object.keys(filtered).length > 0) {
            validCheck.result = false
            validCheck.errorMessages = [...validCheck.errorMessages, 'this team has already received a score for this event']
        }
        return validCheck
    }
    useEffect(() => {
        const fetch = async () => {
            if (props.editScore) {
                return
            }
            const eventTeamComboResult = await eventTeamComboIsValid(state.score)
            if (!eventTeamComboResult.result) {
                dispatch({
                    type: "error_message_changed",
                    nextErrorMessages: [...eventTeamComboResult.errorMessages]
                })
            } else {
                // TODO - this is probably the wrong way to do this, but it works,
                //  you'll eventually get a new error message on submit
                // and this is another todo that we have... to do....
                dispatch({
                    type: "error_message_changed",
                    nextErrorMessages: []
                })

            }
        }
        fetch()
            .catch(console.error)
    }, [state.score.teamUid, state.score.eventUid, props.editScore, state.score])

    // INITIAL LOAD
    useEffect(() => {
        const fetch = async () => {
            //props.editScore will have the UID of what you are editing
            let toSave = { ...state }
            if (props.editScore) {
                const pastScoresMapResult = await firebase.getPastScoresMap(props.editScore, props.competition)
                const score = await firebase.getScore(props.editScore, props.competition)
                toSave.score = { ...toSave.score, ...score }
                // NOTE - if we are editing add your past score
                toSave.newPastScore = score
                toSave.pastScores = pastScoresMapResult

                // delegate changed upon entry of an edit event
                if (user.role === ROLES.ADMIN || user.role === ROLES.SCORECOMMITTEE) {
                    dispatch({
                        type: 'delegate_changed',
                        value: {
                            delegateUid: user.uid,
                            delegate: user
                        }
                    })
                }
            } else {
                if (user.role === ROLES.JUDGE) {
                    toSave.score.judgeUid = user.uid
                    toSave.score.judge = usersMap[user.uid]
                }
                if (props.teamUid) {
                    toSave.score.teamUid = props.teamUid
                    toSave.score.team = teamsMap[props.teamUid]
                }
                if (props.eventUid) {
                    toSave.score.eventUid = props.eventUid
                    toSave.score.event = eventsMap[props.eventUid]
                    // TODO - to test in the morning with adding new scores
                    // TODO - ON EVENT CHANGE CHANGE THE JUDGE
                    //if ('judgesNew' in toSave.score.event && Object.keys(toSave.score.event.judgesNew).length == 1) {
                    //    const judges = toSave.score.event.judgesNew
                    //    toSave.score.judgeUid = Object.keys(judges)[0]
                    //    toSave.score.judge = usersMap[Object.keys(judges)[0]]
                    //    //console.log('this is the evnt we are on', toSave.score.event)
                    //}
                }
            }
            dispatch({
                type: 'values_changed',
                values: toSave
            })
        }

        fetch()
            .catch(console.error)

    }, [])// eslint-disable-line
    useEffect(() => {
        // If events only have a single judge then populate it in the form
        const event = eventsMap[state.score.eventUid] || {}
        const judges = event.judgesNew || {}
        //toSave.score.judgeUid = Object.keys(judges)[0]
        //toSave.score.judge = usersMap[Object.keys(judges)[0]]
        if (Object.keys(judges).length == 1) {
            // TODO - error with the wireTrouble shooting event # 2
            const keys = Object.keys(judges)
            dispatch({
                type: 'judge_changed',
                value: {
                    judgeUid: keys[0],
                    judge: usersMap[keys[0]]
                }
            })
        }
    }, [state.score.eventUid, usersMap])

    const handleClose = () => {
        //setOpen(false);
        window.location.reload(false)
    };
    const handleCloseDelete = () => {
        deleteButtonPressed()
    }
    const penaltyChanged = useCallback((event, penalty) => {
        let id = penalty.id;
        let type = event.target.type;
        // NOTE - we wil assume 0th key because the item should always be there
        //let targetPenaltyBase = penalityList.filter(x => x.id === id)[0]
        let targetPenaltyBase = combinedPenalties.filter(x => x.id === id)[0]
        if (id in state.score.penalties) {
            targetPenaltyBase = { ...targetPenaltyBase, ...state.score.penalties[id] }
        }

        if (type === 'text') {
            targetPenaltyBase['customEntry'] = event.target.value
        }
        if (type === 'checkbox') {
            targetPenaltyBase['checked'] = event.target.checked
        }

        if (type === 'number') {
            targetPenaltyBase['multiplier'] = event.target.value
        }

        return { [id]: targetPenaltyBase }
    }, [state])

    const isValid = async (state) => {
        let validCheck = { result: true, errorMessages: [] }
        if (!state.score.judgeUid) {
            validCheck.result = false
            validCheck.errorMessages.push('a judge must be selected')
        }
        if (!state.score.teamUid) {
            validCheck.result = false
            validCheck.errorMessages.push('a team must be selected')
        }
        if (!state.score.eventUid) {
            validCheck.result = false
            validCheck.errorMessages.push('an even must be selected')

        }
        if (parseInt(state.score.minutes, 10) < 0) {
            validCheck.result = false
            validCheck.errorMessages.push('minutes cannot be less than 0')
        }
        if (parseInt(state.score.minutes, 10) >= 60) {
            validCheck.result = false
            validCheck.errorMessages.push('minutes cannot be greater than 60')
        }
        if (parseInt(state.score.seconds, 10) < 0) {
            validCheck.result = false
            validCheck.errorMessages.push('seconds cannot be less than 0')
        }
        if (parseInt(state.score.seconds, 10) >= 60) {
            validCheck.result = false
            validCheck.errorMessages.push('seconds cannot be greater than 60')
        }
        if (parseInt(state.score.quizQuestionsMissed, 10) < 0) {
            validCheck.result = false
            validCheck.errorMessages.push('quiz questions missed cannot be less than 0')

        }
        if (!props.editScore) {
            const isValidComboResult = await eventTeamComboIsValid(state.score)
            if (!isValidComboResult.result) {
                validCheck.result = isValidComboResult.result
                validCheck.errorMessages = [...validCheck.errorMessages, ...isValidComboResult.errorMessages]
            }
        }

        return validCheck;
    }
    const patchExistingScore = async (isValidCheck) => {
        if (isValidCheck.result) {
            await firebase.patchScore(`${props.competition}/${props.editScore}`, state.score)
            await firebase.insertPastScore(state.newPastScore, props.editScore, props.competition)
            setOpen(true);
        }
    }
    const insertNewScore = async (isValidCheck) => {
        if (isValidCheck.result) {
            await firebase.insertScore(state.score, selectedCompetition)
            setOpen(true);
        }

    }
    const deleteScore = async (competition, scoreId, eventUid) => {
        await firebase.deleteScore(`${competition}/${scoreId}`)
        await firebase.deletePastScore(`${competition}/${scoreId}`)

        window.location.replace(`/scoring/new/for-event/${eventUid}`)
    }

    const deleteButtonPressed = useCallback(() => {
        deleteScore(props.competition, props.editScore, state.score.eventUid)
    }, [props.competition, props.editScore, state, firebase])

    const submitButtonPressed = useCallback((e) => {
        e.preventDefault()
        dispatch({
            type: "disable_submit_button"
        })
        const fetch = async () => {
            const isValidCheck = await isValid(state)
            dispatch({
                type: "error_message_changed",
                nextErrorMessages: isValidCheck.errorMessages
            })
            if (props.editScore) {
                console.log('are we patching')
                await patchExistingScore(isValidCheck)
            } else {
                console.log('or are we insertting')
                await insertNewScore(isValidCheck)
            }
            dispatch({
                type: "enable_submit_button"
            })
        }
        fetch()
            .catch(console.error)

    }, [state.score])// eslint-disable-line

    return (
        <Grid2 sx={{ m: 5 }}>
            <Grid2 sx={{ mb: 5 }}>
                <Typography variant='h6'>New Scoring Form</Typography>
                {
                    Object.keys(state.pastScores).length > 0 &&
                    <Typography variant='body1' sx={{ marginBottom: '10px' }}>
                        This Score Has Been Edited {Object.keys(state.pastScores).length} time{Object.keys(state.pastScores).length === 1 ? '' : 's'}
                    </Typography>
                }
                <Button disabled={!state.submitButtonEnabled} variant="contained" sx={{ marginBottom: '10px' }} onClick={submitButtonPressed}>{props.editScore ? 'Save' : 'Add'} Score</Button>
                {
                    props.editScore &&
                    <Grid2>
                        <Button variant='contained' color='error' onClick={() => {
                            setOpenConfirmDelete(true)
                        }}>Delete Score</Button>
                    </Grid2>
                }
                <FormErrorMessages
                    errorMessages={state.errorMessages}
                />
            </Grid2>
            <Grid2 container sx={{ mb: 5 }}>
                <EventDropdown
                    readonly={true}
                    eventsMapValues={eventsMap}
                    value={state.score.eventUid}
                    onChange={(e, event) => {
                        e.preventDefault()
                        dispatch({
                            type: 'event_changed',
                            value: {
                                eventUid: e.target.value,
                                event
                            }
                        })

                    }}
                />
                <JudgeDropdown
                    readonly={props.editScore || ![ROLES.ADMIN, ROLES.SCORECOMMITTEE].includes(user.role) ? true : false}
                    judgesMapValues={judgesMap}
                    value={state.score.judgeUid}
                    onChange={(e, judge) => {
                        e.preventDefault()
                        dispatch({
                            type: 'judge_changed',
                            value: {
                                judgeUid: e.target.value,
                                judge
                            }
                        })

                        if (e.target.value !== user.uid) {
                            if (user.role === ROLES.ADMIN || ROLES.SCORECOMMITTEE) {
                                dispatch({
                                    type: 'delegate_changed',
                                    value: {
                                        delegateUid: e.target.value,
                                        delegate: new firebase.User(user)
                                    }
                                })
                            }
                        }
                    }}
                />
                <TeamDropdown
                    readonly={props.editScore ? true : false}
                    teamsMapValues={teamsMap}
                    value={state.score.teamUid}
                    onChange={(e, team) => {
                        e.preventDefault()
                        dispatch({
                            type: 'team_changed',
                            value: {
                                teamUid: e.target.value,
                                team
                            }
                        })

                    }}
                />
            </Grid2>
            {state.useTimeClockAutomatic ?
                (
                    <Grid2 sx={{ mb: 2 }}>
                        <Typography variant='h6'>Stopwatch</Typography>
                        <TimeClockAutomatic
                            timeInSeconds={state.score.time}
                            onChange={(seconds) => {
                                dispatch({
                                    type: 'automatic_time_changed',
                                    value: { seconds }
                                })


                            }}
                        />
                    </Grid2>
                )
                :
                (
                    <Grid2 sx={{ mb: 2 }}>
                        <Typography variant='h6'>Manual Entry</Typography>
                        <TimeClockManual
                            minutes={state.score.minutes}
                            seconds={state.score.seconds}
                            onChange={(e, forWhat) => {
                                e.preventDefault()
                                const value = e.target.value
                                if (forWhat === 'minutes') {
                                    dispatch({
                                        type: 'minutes_changed',
                                        value
                                    })
                                }
                                if (forWhat === 'seconds') {
                                    dispatch({
                                        type: 'seconds_changed',
                                        value
                                    })
                                }

                            }}
                        />
                    </Grid2>
                )
            }

            <Button variant='contained' sx={{ mb: 5 }} onClick={() => {
                dispatch({
                    type: 'toggle_time_clock_type'
                })

            }}>Toggle Time Clock Type</Button>
            {
                props.editScore &&
                <Grid2>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={state.score.scoreContested}
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch({
                                    type: 'score_contested'
                                })
                            }}
                        />} label="Contest Score" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={state.score.excludeScore}
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch({
                                    type: 'score_excluded'
                                })
                            }}
                        />} label="Exclude Score" />
                    </FormGroup>
                </Grid2>
            }
            <Grid2 container>
                <Tooltip title="Penalties plus minutes plus seconds">
                    <Typography variant='h6'>Final Score: {state.score.finalScore}</Typography>
                </Tooltip>
            </Grid2>

            {
                eventsMap[state.score.eventUid] &&
                    eventsMap[state.score.eventUid]['isQuiz'] ?
                    <TextField
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        name="teamNumber"
                        value={state.score.quizQuestionsMissed}
                        onChange={(e) => {
                            dispatch({
                                type: 'quiz_questions_missed_changed',
                                value: e.target.value,
                            })
                        }}
                        label="Quiz Questions Missed"
                    />

                    :
                    <>
                        <PenaltyForm
                            onChange={(e, penalty) => {
                                e.preventDefault()
                                const result = penaltyChanged(e, penalty)
                                dispatch({
                                    type: 'penalty_changed',
                                    value: result,
                                })
                            }}
                            penaltiesAccrued={state.score.penalties}
                            penaltyList={combinedPenalties}
                        />
                    </>
            }
            <Grid2 container>
                <TextField
                    fullWidth
                    label="Comments and Explanations"
                    placeholder="Comments and Explanations"
                    multiline
                    value={state.score.additionalComments}
                    sx={{ marginBottom: 5 }}
                    onChange={(e) => {
                        e.preventDefault()
                        dispatch({
                            type: 'additional_comments_changed',
                            value: e.target.value
                        })
                    }}
                />
            </Grid2>
            {
                props.editScore &&
                <Grid2 container sx={{ marginBottom: 5 }}>
                    <TextField
                        fullWidth
                        label="Change Reason"
                        placeholder="Reason For Change"
                        multiline
                        value={state.score.reasonForChange}
                        onChange={(e) => {
                            e.preventDefault()
                            dispatch({
                                type: 'reason_changed',
                                value: e.target.value
                            })
                        }}
                    />
                </Grid2>

            }
            <Button disabled={!state.submitButtonEnabled} variant="contained" onClick={submitButtonPressed}>{props.editScore ? 'Save' : 'Add'} Score</Button>
            <Grid2 container>
                <Tooltip title="Penalties plus minutes plus seconds">
                    <Typography variant='h6'>Final Score: {state.score.finalScore}</Typography>
                </Tooltip>
            </Grid2>
            <FormErrorMessages
                errorMessages={state.errorMessages}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Score has been {props.editScore ? 'edited' : 'added'} successfully!
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirmDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Do you really want to delete this entry?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => {setOpenConfirmDelete(false)}} autoFocus>
                        No
                    </Button>
                    <Button onClick={handleCloseDelete} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid2>
    )
}
// TODO replace the success dialog with the form messaging component

export default ScoringFormNew;
