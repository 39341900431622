import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText, InputLabel } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const JudgeDropdown = (props) => {
    const {
        value,
        onChange,
        readonly,
        judgesMapValues
    } = props

    return (
        <Grid2>
            <FormControl className='scoring-form-div' variant="standard"
                sx={{ minWidth: '200px' }}>
                <InputLabel id="demo-simple-select-helper-label">Judge</InputLabel>
                <Select
                    name="judgeUid"
                    value={value}
                    onChange={(e) => onChange(e, judgesMapValues[e.target.value] || {})}
                    disabled={readonly}
                >
                    <MenuItem value={''}>Judge</MenuItem>
                    {Object.keys(judgesMapValues).map(jUid => {
                        return (
                            <MenuItem
                                key={jUid}
                                value={jUid}
                            >
                                {judgesMapValues[jUid]['username'] + ' - ' + judgesMapValues[jUid]['email']}
                            </MenuItem>
                        )
                    })}

                </Select>
            </FormControl>
        </Grid2>
    )
}

export default JudgeDropdown