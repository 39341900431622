import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

// TODO -rename to like form dialog
const DialogErrorMessaging = (props) => {
    const { errorTitle, errorDescription } = props
    const { showErrorMessage } = props
    const { onErrorClose } = props
    // TODO - we need some things like an icon or something to differentiate between
    //  the types of messaging
    return (
        <DialogFormMessaging
            title={errorTitle}
            description={errorDescription}
            shouldShow={showErrorMessage}
            onClose={onErrorClose}
        />
    )
}

const DialogSuccessMessaging = (props) => {
    // messaging
    const { successTitle, successDescription } = props
    // state
    const { showSuccessMessage } = props
    // callbacks
    const { onSuccessClose } = props
    return (
        <DialogFormMessaging
            title={successTitle}
            description={successDescription}
            shouldShow={showSuccessMessage}
            onClose={onSuccessClose}
        />
    )
}

const DialogConfirmMessaging = (props) => {
    const { confirmTitle, confirmDescription } = props
    const { showConfirmMessage } = props
    const { onConfirmClose, onConfirmCancel, onConfirmConfirm } = props

    return (
        <DialogFormMessagingV2
            title={confirmTitle}
            description={confirmDescription}
            shouldShow={showConfirmMessage}
            onClose={onConfirmClose}
            onConfirm={onConfirmConfirm}
            onCancel={onConfirmCancel}
        />
    )
}

const DialogFormMessagingV2 = (props) => {
    const { title, description } = props
    const { shouldShow } = props
    const { onClose, onConfirm, onCancel } = props

    return (
        <>
            <Dialog
                open={shouldShow}
                onClose={onClose}
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    {description}
                </DialogContent>
                <DialogActions>
                    {
                        onCancel &&
                        <Button onClick={onCancel || onClose}>
                            Cancel
                        </Button>
                    }
                    <Button onClick={onConfirm || onClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const DialogFormMessaging = (props) => {
    // messaging
    const { title, description } = props
    // state
    const { shouldShow } = props
    // callbacks
    const { onClose } = props
    return (
        <>
            <Dialog
                open={shouldShow}
                onClose={onClose}
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    {description}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export {
    DialogErrorMessaging,
    DialogSuccessMessaging,
    DialogConfirmMessaging
}