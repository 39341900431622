import { Typography } from '@mui/material';
import React from 'react';

// TODO - make more generic for success messages as well
//  even info messages
const FormErrorMessages = (props) => {
    const {
        errorMessages,
        success
    } = props

    return (
        <>
            {errorMessages.length !== 0 && errorMessages.map((v, k) => {
                return <Typography sx={{ color: success ? 'green' : 'red' }} key={k}>{v}</Typography>
            })}
        </>
    )
}

export default FormErrorMessages