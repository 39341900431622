import React, { useReducer, useEffect, useContext, useMemo } from 'react';
import penalityList from './ScoringFormComponents/PenaltiesNew'
import PenaltyForm from './ScoringFormComponents/PenaltyFormNew';
import JudgeDropdown from './Dropdowns/JudgeDropdown';
import { TeamDropdown } from './Dropdowns/TeamDropdown';
import { EventDropdown } from './Dropdowns/EventDropdown';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import TimeClockManual from './ScoringFormComponents/TimeClockManual';
import { TextField, Tooltip, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

// NOTE - we presume delegates based on their user id and ability
//  to get to the edit / create or permissions.
function userFormInitialState() {
    return {
        score: {
            teamUid: '',
            eventUid: '',
            judgeUid: '',
            delegateUid: '',
            team: {},
            event: {},
            judge: {},
            delegate: {},
            penalties: {},
            quizQuestionsMissed: 0,
            finalScore: 0,
            minutes: 0,
            seconds: 0,
            comments: '',
            time: 0,
        },
        newPastScore: {},
        pastScores: [],
        //LEFT OFF -we store edit score for patchin
        // we get past scores in a separate table call and bring them in for showing number of edits

    }
}
function userFormReducer(state, action) {
    switch (action.type) {
        case 'values_changed':
            return {
                ...state,
                ...action.values
            }
        default:
            break;
    }
}

function ScoringFormView(props) {
    const [state, dispatch] = useReducer(userFormReducer, props, userFormInitialState)
    //const [useTimeClockAutomatic, setUseTimeClockAutomatic] = useState(true)
    const { firebase } = useContext(FirebaseNewContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const selectedCompetition = useMemo(() => {
        return props.competition || defaultSelectedCompetition
    }, [defaultSelectedCompetition])
    const { teamsMap: globalTeamsMap, judgesMap: globalJudgesMap, eventsMap: globalEventsMap,scoresheetsMap: globalScoresheetsMap } = useContext(CommonDataContext)
    const eventsMap = useMemo(() => {
        return globalEventsMap[selectedCompetition] || {}
    }, [selectedCompetition, globalEventsMap])
    const teamsMap = useMemo(() => {
        return globalTeamsMap[selectedCompetition] || {}
    }, [selectedCompetition, globalTeamsMap])
    const judgesMap = useMemo(() => {
        return globalJudgesMap
    }, [selectedCompetition, globalJudgesMap, state.score])
    const combinedPenalties = useMemo(() => {
        //const combinedPenalties = [...(globalScoresheetsMap.penalties || []), ...state.penalties]
        const hasScoresheet = Object.keys(globalScoresheetsMap[selectedCompetition] || {}).length > 0
        let toReturn = []
        if (hasScoresheet) {
            const globalScoresheet = globalScoresheetsMap[selectedCompetition]['global'] || {}
            const eventUid = state.score.eventUid || props.eventUid
            const eventScoresheet = globalScoresheetsMap[selectedCompetition]['events'][eventUid] || {}
            if (Object.keys(globalEventsMap).length > 0 && Object.keys(eventScoresheet).length > 0) {

                toReturn = [...(globalScoresheet.penalties || []), ...eventScoresheet.penalties]

                console.log('here is our combined penalites', combinedPenalties)
                // TODO - do not combine the penalties here
                //<PenaltyForm
                //    onChange={(e, penalty) => {
                //        e.preventDefault()
                //        const result = penaltyChanged(e, penalty)
                //        dispatch({
                //            type: 'penalty_changed',
                //            value: result,
                //        })
                //    }}
                //    penaltiesAccrued={state.score.penalties}
                //    penaltyList={penalityList}
                ///>
            }

        }
        // TODO - to improve later, we shouldnt be calling this every time 
        //  score is updated
        return toReturn
    }, [selectedCompetition, globalScoresheetsMap, state.score])

    useEffect(() => {
        const fetch = async () => {
            //props.editScore will have the UID of what you are editing
            let toSave = {}
            if (props.scoreUid) {
                const pastScoresMapResult = await firebase.getPastScoresMap(props.scoreUid, props.competition)
                const score = await firebase.getScore(props.scoreUid, props.competition)
                toSave.score = score
                // NOTE - if we are editing add your past score
                toSave.newPastScore = score
                toSave.pastScores = pastScoresMapResult
            }
            dispatch({
                type: 'values_changed',
                values: toSave
            })
        }

        fetch()
            .catch(console.error)

    }, [])


    return (
        <>
            <Typography variant='h6'>Team View Scoring Form</Typography>
            <Grid2 container>
                <EventDropdown
                    readonly={true}
                    eventsMapValues={eventsMap}
                    value={state.score.eventUid}
                />
                <JudgeDropdown
                    readonly={true}
                    judgesMapValues={judgesMap}
                    value={state.score.judgeUid}
                />
                <TeamDropdown
                    readonly={true}
                    teamsMapValues={teamsMap}
                    value={state.score.teamUid}
                />
            </Grid2>
            <>
                <Typography variant='h6'>Manual Entry</Typography>
                <TimeClockManual
                    readonly={true}
                    minutes={state.score.minutes}
                    seconds={state.score.seconds}
                />
            </>
            <Grid2 container>
                <Tooltip title="Penalties plus minutes plus seconds">
                    <Typography variant='h6'>Final Score: {state.score.finalScore}</Typography>
                </Tooltip>
            </Grid2>

            <PenaltyForm
                readonly={true}
                penaltiesAccrued={state.score.penalties}
                penaltyList={combinedPenalties}
            />
            <Grid2 container>
                <TextField
                    disabled
                    fullWidth
                    multiline
                    value={state.score.additionalComments}
                    sx={{ marginBottom: 5 }}
                />
            </Grid2>

        </>
    )
}

export default ScoringFormView;
