import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, StepIcon } from '@mui/material';
import { Event, Groups, Person, Category, Festival, Class, ManageSearch, Settings, SlideshowOutlined, Feedback, ImportContacts, PriorityHigh } from '@mui/icons-material';
import * as routes from '../routes/routes';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';

const AdminActionsPage = () => {
    const pages = [
        { name: 'Categories', description: 'Create and edit categories', link: routes.CATEGORY_PAGE, icon: <Category /> },
        { name: 'Divisions', description: 'Create and edit divisions', link: routes.DIVISION_PAGE, icon: <Class /> },
        { name: 'Competitions', description: 'Create competitions and set the global default competition', link: routes.COMPETITION_PAGE, icon: <Festival /> },
        { name: 'Users', description: 'Edit users and assign them to teams or events', link: routes.USER_PAGE, icon: <Person /> },
        { name: 'Teams', description: 'Create and edit teams by assigning team members', link: routes.TEAM_PAGE, icon: <Groups /> },
        { name: 'Events', description: 'Create and edit events. Assign Judges to events', link: routes.EVENT_PAGE, icon: <Event /> },
        { name: 'Base Scoresheet', description: 'Base Scoresheet within a competition', link: routes.BASE_SCORESHEET_PAGE, icon: <ImportContacts /> },
        { name: 'Slide Show Timer', description: 'Create a slide show during the event', link: routes.SLIDE_SHOW_TIMER_PAGE, icon: <SlideshowOutlined /> },
        { name: 'Penalties Report', description: 'See most used penalties for the competition', link: routes.PENALTIES_REPORT, icon: <Feedback /> },
        { name: 'History', description: 'View history of actions taken in the application', link: routes.HISTORY, icon: <ManageSearch /> },
        { name: 'Liability Form Upload', description: 'Upload liability release forms', link: routes.LIABILITY_PAGE, icon: <PriorityHigh /> },
        { name: 'Global Settings', description: 'Show final scores and the global default competition. This goes no where currently', link: routes.GLOBAL_SETTINGS_PAGE, icon: <Settings /> }
    ]

    return (
        <Box>
            <h2>Admin Actions page</h2>
            <List>
                {
                    pages.map((v, k) => {

                        return (
                            <ListItem key={k}>
                                <ListItemIcon>
                                    {v.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={(
                                        <Link to={v.link}>{v.name}</Link>
                                    )}
                                    secondary={(<span>{v.description}</span>)}
                                />
                            </ListItem>
                        )
                    })
                }
            </List>
        </Box>
    )

}

export default AdminActionsPage