import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { Component, useReducer, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import { ROLES, numberSuggester } from '../misc/Utils';
import CompetitionDropdown from './Dropdowns/CompetitionDropdown';
import FormErrorMessages from './Messages/FormErrorMessages';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import app from 'firebase/compat/app';
import { initializeApp, getApp } from '@firebase/app';
import { getAuth, signOut, createUserWithEmailAndPassword } from '@firebase/auth';
import { MultiSearchSelectEventWrapper } from './Dropdowns/EventDropdown';
import { MultiSearchSelectTeamWrapper } from './Dropdowns/TeamDropdown';

// TODO - allow for changing a users default competition
const newFirebaseUserApp = () => {
    // NOTE - only create one service account
    if (app.apps.find(entry => entry.name === 'SERVICE_ACCOUNT')) {
        return getApp('SERVICE_ACCOUNT')
    }
    var config = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    };
    return initializeApp(config, "SERVICE_ACCOUNT");
}
const secondaryApp = newFirebaseUserApp()
const secondaryAuth = getAuth(secondaryApp)

function userFormInitialState(values) {
    return {
        username: values.username || '',
        email: values.email || '',
        passwordOne: '',
        passwordTwo: '',
        role: values.role || '',
        eventsNew: values.events || [],
        teamsNew: values.teams || [],
        competitions: values.competitions || {},
        selectedCompetition: values.selectedCompetition || {},
        errorMessages: []
    }
}
function userFormReducer(state, action) {
    switch (action.type) {
        case 'checkbox_values_changed':
            return {
                ...state,
                [`${action.forItem}New`]: {
                    ...state[`${action.forItem}New`],
                    ...{
                        [action.forCompetition]: action.values
                    }
                }
            }
        case 'value_changed':
            return {
                ...state,
                [action.keyName]: action.keyValue
            }
        case 'values_changed':
            return {
                ...state,
                ...action.values
            }
        case 'error_message_changed':
            return {
                ...state,
                errorMessages: action.nextErrorMessages

            }
        default:
            break;
    }
}

// TODO - break up user form so it is just creating a user
//      not assigning them to a team yet
// TODO - create team form so you can assign multiple users to a team
function UserForm(props) {
    const [state, dispatch] = useReducer(userFormReducer, props, userFormInitialState)
    const { firebase } = useContext(FirebaseNewContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const { teamsMap: globalTeamsMap, eventsMap: globalEventsMap, usersMap } = useContext(CommonDataContext)
    const { globalSettings: { competitions: competitionMap } } = useContext(GlobalSettingsContext)
    const [selectedCompetition, setSelectedCompetition] = useState(defaultSelectedCompetition)
    const teamsMap = useMemo(() => {
        return globalTeamsMap[selectedCompetition] || {}
    }, [globalTeamsMap, selectedCompetition])
    const eventsMap = useMemo(() => {
        return globalEventsMap[selectedCompetition] || {}
    }, [globalEventsMap, selectedCompetition])


    const [user, setUser] = useState({})
    const userTeams = useMemo(() => {
        if (props.editUser && props.editUser in usersMap) {
            if (usersMap[props.editUser]['teamsNew'] && usersMap[props.editUser]['teamsNew'][selectedCompetition]) {
                return usersMap[props.editUser]['teamsNew'][selectedCompetition]
            }
        }
        return []
    }, [selectedCompetition])
    const userEvents = useMemo(() => {
        if (props.editUser && props.editUser in usersMap) {
            if (usersMap[props.editUser]['eventsNew'] && usersMap[props.editUser]['eventsNew'][selectedCompetition]) {
                console.log(usersMap[props.editUser]['eventsNew'][selectedCompetition])
                return usersMap[props.editUser]['eventsNew'][selectedCompetition]
            }
        }
        return []
    }, [selectedCompetition])
    useEffect(() => {
        const fetch = async () => {
            // TODO - we should not be sharing state between a reducer and use state here
            //  fix one or the other (we had a good thing going on our scoring sheet reducer)
            if (props.editUser && props.editUser in usersMap) {
                const user = usersMap[props.editUser]
                dispatch({
                    type: 'values_changed',
                    values: {
                        username: user.username,
                        email: user.email,
                        role: user.role,
                        eventsNew: user.eventsNew || {},
                        teamsNew: user.teamsNew || {},
                        competitions: user.competitions || {},
                        selectedCompetition: user.selectedCompetition || {},
                    }
                })
                setUser(user)
            }
        }

        fetch()
            .catch(console.error)

    }, [])
    const isValidPassword = (passwordOne, passwordTwo) => {
        let validCheck = { result: true, errorMessages: [] };
        if (passwordOne !== passwordTwo) {
            validCheck.result = false;
            validCheck.errorMessages.push('Passwords don\'t match');
        }
        if (passwordOne === '' || passwordTwo === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Both password fields must be filled in');
        }
        if (passwordOne.length < 8) {
            validCheck.result = false;
            validCheck.errorMessages.push('Password must be 8 or more characters');
        }
        return validCheck;
    }
    const isValid = (state) => {
        const { username, email, passwordOne, passwordTwo, role } = state;
        let validCheck = { result: true, errorMessages: [] }
        if (!props.editUser) {
            let passwordCheck = isValidPassword(passwordOne, passwordTwo);
            if (!passwordCheck.result) {
                validCheck = passwordCheck;
            }
        }
        if (email === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Email cannot be blank');
        }
        if (username === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Username cannot be blank');
        }
        if (role === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Role cannot be blank');
        }

        return validCheck;
    }
    const insertNewUser = async (isValidCheck) => {
        if (isValidCheck.result) {
            // TODO - update this with the new way of doing auth
            createUserWithEmailAndPassword(secondaryAuth, state.email.trim(), state.passwordOne.trim())
                .then(async (firebaseUser) => {
                    const user = firebaseUser.user;
                    // NOTE - unsure if signOut is actually requried since it is a different app
                    signOut(secondaryAuth);

                    let toSave = state
                    toSave['uid'] = user.uid
                    // set the default competition based on where you created them
                    toSave['defaultCompetition'] = selectedCompetition
                    await firebase.insertUser(user.uid, toSave)
                    // TODO - to fix, we currently dont have any users in the Map to be referenced
                    //  to save the user to the db, we only have the UID
                    if (toSave.role == 'TEAM') {
                        await addTeamEntry(user.uid)
                        await removeTeamEntry(user.uid)
                    }
                    if (toSave.role == 'JUDGE') {
                        await addEventEntry(user.uid)
                        await removeEventEntry(user.uid)
                    }
                    window.location.reload(false)

                })
        }
    }
    const patchExistingUser = async (isValidCheck) => {
        if (isValidCheck.result) {
            console.log('about to patch a user', state)
            const toReplace = await firebase.getUser(props.editUser)
            await firebase.patchUser(props.editUser, {...toReplace, ...state})
            if (user.role == ROLES.TEAM) {
                await addTeamEntry(props.editUser)
                await removeTeamEntry(props.editUser)
            }
            if (user.role == ROLES.JUDGE) {
                console.log('about to do a judge')
                console.log('about to do an event entry')
                await addEventEntry(props.editUser, selectedCompetition)
                console.log('about to remove a judge enry')
                await removeEventEntry(props.editUser, selectedCompetition)
            }

            window.location.reload(false)
        }
    }

    const addTeamEntry = async (databaseId) => {
        const newTeams = newIn(userTeams, state.teamsNew[selectedCompetition] || [])
        const newUser = {
            ...usersMap[databaseId], ...{ uid: databaseId }
        }
        const result = await Promise.all(newTeams.map(async (x) => {
            // TODO - use the thing up above which will always have the team
            let team = teamsMap[x]
            if (!team.teamMembers) {
                team.teamMembers = {
                    [databaseId]: newUser
                }
            }
            team.teamMembers = { ...team.teamMembers, ...{ [databaseId]: newUser } }
            return firebase.patchTeam(`${selectedCompetition}/${x}`, team)
        }))
        return result

    }
    const removeTeamEntry = async (databaseId) => {
        const removedTeams = outIn(userTeams, state.teamsNew[selectedCompetition] || [])
        const result = await Promise.all(removedTeams.map(async (x) => {
            let team = teamsMap[x]
            if (!team.teamMembers) {
                team.teamMembers = {}
            }
            //team.teamMembers = team.teamMembers.filter(y => y.uid != databaseId)
            team.teamMembers = Object.keys(team.teamMembers).reduce((acc, v) => {
                if (databaseId == v) {
                    return acc
                }
                acc[v] = team.teamMembers[v]
                return acc
            }, {})
            return firebase.patchTeam(`${selectedCompetition}/${x}`, team)

        }))
        return result
    }
    const addEventEntry = async (databaseId) => {
        const newEvents = newIn(userEvents, state.eventsNew[selectedCompetition] || [])
        const newUser = {
            ...usersMap[databaseId], ...{ uid: databaseId }
        }
        const result = await Promise.all(newEvents.map(async (x) => {
            let event = eventsMap[x]
            if (!event.judgesNew) {
                event.judgesNew = {
                    [databaseId]: newUser
                }
            }
            event.judgesNew = { ...event.judgesNew, ...{ [databaseId]: newUser } }
            return firebase.patchEvent(`${selectedCompetition}/${x}`, event)
        }))
        return result

    }
    const removeEventEntry = async (databaseId) => {
        const removedEvents = outIn(userEvents, state.eventsNew[selectedCompetition] || [])
        const result = await Promise.all(removedEvents.map(async (x) => {
            let event = eventsMap[x]
            if (!event.judgesNew) {
                event.judgesNew = {}
            }
            event.judgesNew = Object.keys(event.judgesNew).reduce((acc, v) => {
                if (databaseId == v) {
                    return acc
                }
                acc[v] = event.judgesNew[v]
                return acc
            }, {})
            return firebase.patchEvent(`${selectedCompetition}/${x}`, event)

        }))
        return result
    }
    const newIn = (original, aboutToSave) => {
        return aboutToSave.filter(x => !original.includes(x))

    }
    const outIn = (original, aboutToSave) => {
        return original.filter(x => !aboutToSave.includes(x))

    }

    const submitButtonPressed = useCallback((e) => {
        e.preventDefault()
        const isValidCheck = isValid(state)
        dispatch({
            type: "error_message_changed",
            nextErrorMessages: isValidCheck.errorMessages
        })
        if (props.editUser) {
            patchExistingUser(isValidCheck)
        } else {
            insertNewUser(isValidCheck)
        }


    }, [state, user, selectedCompetition])

    const _renderPasswordFields = (state, dispatch) => {
        return (
            <>
                <Typography variant='h5'>Password</Typography>
                <Grid2 container
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}

                >
                    <TextField
                        sx={{ flex: 1 }}
                        name="passwordOne"
                        value={state.passwordOne}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                                keyName: 'passwordOne'
                            })
                        }}
                        type="password"
                        label="Password"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        name="passwordTwo"
                        value={state.passwordTwo}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                                keyName: 'passwordTwo'
                            })
                        }}
                        type="password"
                        label="Confirm Password"
                    />
                </Grid2>
            </>
        )

    }
    return (
        <Grid2>
            <Typography variant='h4'>{props.editUser ? 'Edit' : 'Create'} a User</Typography>
            <Typography variant='body'>uid: {props.editUser}</Typography>
            <CompetitionDropdown
                value={selectedCompetition}
                onChange={(value) => setSelectedCompetition(value)}
            />
            <Grid2 container flexDirection={'column'} sx={{}}>
                <Typography variant='h5'>General Information</Typography>
                <Grid2 container
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                >
                    <TextField
                        sx={{ flex: 1 }}
                        name="username"
                        value={state.username}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                                keyName: 'username'
                            })
                        }}
                        label="Name"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        name="email"
                        value={state.email}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'email'
                            })
                        }}
                        label="Email Address"
                        readOnly={props.editUser}
                    />
                </Grid2>
                {
                    !props.editUser &&
                    _renderPasswordFields(state, dispatch)
                }
                <Typography variant='h5'>Role Assignment</Typography>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select name="role" value={state.role}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                                keyName: 'role'
                            })
                        }}>
                        <MenuItem value="">Role</MenuItem>
                        <MenuItem value="TEAM">Team</MenuItem>
                        <MenuItem value="JUDGE">Judge</MenuItem>
                        <MenuItem value="ADMIN">Admin</MenuItem>
                        <MenuItem value="SCORECOMMITTEE">Scoring Committee</MenuItem>
                    </Select>
                </FormControl>
            </Grid2>
            {
                state.role === 'TEAM'
                && (
                    <Grid2>
                        <Typography variant='h5'>Assign To a Team</Typography>
                        <Grid2 container>
                            <MultiSearchSelectTeamWrapper
                                selectedTeams={state.teamsNew[selectedCompetition]}
                                onChange={(value) => {
                                    const values = value.map(v => v.uid)
                                    dispatch({
                                        type: 'checkbox_values_changed',
                                        values: values,
                                        forItem: 'teams',
                                        forCompetition: selectedCompetition
                                    })

                                }}
                                teamsMapValues={teamsMap}

                            />
                        </Grid2>
                    </Grid2>

                )

            }
            {
                state.role === 'JUDGE' &&
                (
                    <Grid2>
                        <Typography variant='h5'>Assign To An Event</Typography>
                        <Grid2 container>
                            <MultiSearchSelectEventWrapper
                                selectedEvents={state.eventsNew[selectedCompetition]}
                                onChange={(value) => {
                                    const values = value.map(v => v.uid)
                                    dispatch({
                                        type: 'checkbox_values_changed',
                                        values: values,
                                        forItem: 'events',
                                        forCompetition: selectedCompetition
                                    })

                                }}
                                eventsMapValues={eventsMap}
                            />
                        </Grid2>
                    </Grid2>
                )
            }
            <Button onClick={submitButtonPressed}>{props.editUser ? 'Save' : 'Create'}</Button>
            <FormErrorMessages errorMessages={state.errorMessages} />
        </Grid2 >
    )
}
// TOOD - checkboxes for judges and teams as a component
export default UserForm