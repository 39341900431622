
import React, { useEffect, useState, useMemo, useContext } from 'react';
import TeamForm from '../components/forms/TeamForm';
import * as routes from '../routes/routes';
import { CommonDataContext } from '../firebase/CommonDataContext';
import { UserDataContext } from '../firebase/UserDataContext';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, Stack, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { FirebaseNewContext } from '../firebase/FirebaseNewContext';

const teamsColumns = [
    { field: 'col1', headerName: 'Team Name', width: 350 },
    { field: 'col2', headerName: 'Team Number', width: 100 },
    { field: 'col3', headerName: 'Member Count', width: 150 },
    { field: 'col4', headerName: 'Invited Members', width: 150 },
    {
        field: 'col5', headerName: 'Liability Signatures', width: 150,
        renderCell: (params) => {

            const allSigned = params.value.signedMembers >= 5 || params.value.signedMembers == params.value.members
            return (
                <>
                    {params.value.signedMembers} / {params.value.members}
                    {
                        allSigned ?
                            <CheckIcon sx={{ color: 'green'}} />
                            :
                            <CloseIcon sx={{ color: 'red'}} />
                    }
                </>
            )

        }
    },
    {
        field: 'col6', headerName: 'Category', width: 150,
        valueGetter: (params) => {
            return params.value.categoryMap[params.value.category]
        }
    },
    {
        field: 'col7', headerName: 'Division', width: 300,
        valueGetter: (params) => {
            return params.value.divisionMap[params.value.division]
        }
    },
    {
        field: 'col8', headerName: 'Actions', width: 150, getApplyQuickFilterFn: null,
        renderCell: (params) => {
            return (
                <>
                    <Button component={Link} to={routes.routeReplace(routes.TEAM_PAGE_EDIT, { edit: params.value.uid, competition: params.value.selectedCompetition })}>Edit</Button>
                    <Button disabled onClick={params.value.callback}>Delete</Button>
                </>
            )
        }
    },
]
function TeamPage(props) {
    const location = useLocation()
    const params = useParams()
    const { globalSettings: { divisions: globalDivisionMap, categories: globalCategoriesMap, competitions } } = useContext(GlobalSettingsContext)

        
    const { firebase } = useContext(FirebaseNewContext)
    const { teamsMap: globalTeamsMap, liabilityMap: globalLiabilityMap } = useContext(CommonDataContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(defaultSelectedCompetition)
    const teamsMap = useMemo(() => {
        return globalTeamsMap[selectedCompetition] || {}
    }, [selectedCompetition, globalTeamsMap])
    const categoryMap = useMemo(() => {
        return globalCategoriesMap[selectedCompetition] || {}
    }, [selectedCompetition, globalCategoriesMap])
    const divisionMap = useMemo(() => {
        return globalDivisionMap[selectedCompetition] || {}
    }, [selectedCompetition, globalDivisionMap])
    const liabilityMap = useMemo(() => {
        const theLiabilities = globalLiabilityMap[selectedCompetition] || {}
        return 'signatories' in theLiabilities ? theLiabilities['signatories'] : {}
    }, [globalLiabilityMap, selectedCompetition])

    useEffect(() => {
        const fetch = async () => {
        }
        fetch()
            .catch(console.error)

    }, [])
    const deleteTeam = async (team, competition) => {
        await firebase.deleteTeam(team, competition)
        window.location.reload(false)

    }
    const rows = useMemo(() => {
        return Object.keys(teamsMap).map((v) => {
            const team = teamsMap[v]
            const members = 'teamMembers' in team ? Object.keys(team.teamMembers) : []
            const invitedMembers = 'teamMembers' in team && 
            Object.keys(team.teamMembers).reduce((acc, v) => {
                const member = team.teamMembers[v]
                if (member.invited) {
                    acc = acc + 1
                }
                return acc
            }, 0) || 0
            const signedMembers = members.filter(value => Object.keys(liabilityMap).includes(value))
            return {
                id: v,
                col1: team.teamName,
                col2: team.teamNumber,
                col3: members.length,
                col4: invitedMembers,
                col5:  {signedMembers: signedMembers.length, members: members.length},
                col6: { categoryMap, category: team.category },
                col7: { divisionMap, division: team.division },
                col8: { uid: v, selectedCompetition, callback: deleteTeam.bind(this, v, selectedCompetition) }
            }
        })
    }, [teamsMap, globalCategoriesMap, globalDivisionMap, liabilityMap])
    return (
        <Grid2>
            {
                location.pathname === routes.TEAM_PAGE &&
                <>
                    <Grid2 container justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant='h3'>Teams</Typography>
                        <Button component={Link} to={routes.TEAM_PAGE_NEW}>Create A Team</Button>
                    </Grid2>
                    <CompetitionDropdown
                        value={selectedCompetition}
                        onChange={setSelectedCompetition}
                    />
                </>
            }
            {
                typeof selectedCompetition !== 'string' &&
                <h3>Please Select A competition</h3>
            }
            {
                location.pathname === routes.TEAM_PAGE &&
                typeof selectedCompetition == 'string' &&
                <Grid2 flexDirection={'column'}>
                    <DataGrid
                        sx={{ marginBottom: '25px' }}
                        autoHeight
                        rows={rows || []}
                        columns={teamsColumns}
                        slots={{
                            toolbar: GridToolbar,
                            noRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    <Typography variant='body'>For the {competitions[selectedCompetition]} competition</Typography>
                                    <Typography variant='body'>No Teams Have Been Created</Typography>
                                </Stack>
                            ),
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Grid2>
            }

            {
                Object.keys(params).length !== 0 &&
                <TeamForm {...props} team={`${params.edit}`} competition={selectedCompetition} />
            }
            {
                location.pathname.includes(routes.TEAM_PAGE_NEW) &&
                <TeamForm {...props} />
            }
        </Grid2 >
    )

}



export default TeamPage;

